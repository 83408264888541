import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { hospitalService } from "../../../../../services";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";

class Add extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            name: "",
            id: "",
            editHospitalInfo: {
                id: props.editId,
                name: "",
                switch_board_phone_number: "",
                contact_person_name: "",
                contact_person_phone_number: "",
                sac_code: "",
                email: "",
                addressData: { address: [{ name: "" }] },
                departmentData: { department: [{ name: "" }] },
            },
            submitModalFalg: 0,
            suggestionHospitalList: [],
            suggestionsActive: false,
            error:"",
            loading:false
        };
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleOnHospitalSelect = this.handleOnHospitalSelect.bind(this);
    }

    editHospitalInfoHandler = (name, value) => {
        const { editHospitalInfo } = this.state;
        editHospitalInfo[name] = value;
        this.setState(
        editHospitalInfo,
            () => {
                if (name == "name") {
                    this.getAutoSearch();
                }
            },
        );
    };

    editAddMoreAddress = () => {
        const { editHospitalInfo } = this.state;
        var addmore = editHospitalInfo["addressData"].address;
        addmore.push({ name: "" });
        editHospitalInfo["addressData"].address = addmore;
        this.setState({
            editHospitalInfo,
        });
    };

    editAddressHandler = (value, ind) => {
        const { editHospitalInfo } = this.state;
        var addmore = editHospitalInfo["addressData"].address;
        addmore[ind].name = value;
        editHospitalInfo["addressData"].address = addmore;
        this.setState({
            editHospitalInfo,
        });
    };

    editAddMoreDepartment = () => {
        const { editHospitalInfo } = this.state;
        var addmore = editHospitalInfo["departmentData"].department;
        addmore.push({ name: "" });
        editHospitalInfo["departmentData"].department = addmore;
        this.setState({
            editHospitalInfo,
        });
    };

    editDepartmentHandler = (value, ind) => {
        const { editHospitalInfo } = this.state;
        var addmore = editHospitalInfo["departmentData"].department;
        addmore[ind].name = value;
        editHospitalInfo["departmentData"].department = addmore;
        this.setState({
            editHospitalInfo,
        });
    };

    deleteEditDepartment(ind) {
        const { editHospitalInfo } = this.state;
        var deleteh = editHospitalInfo["departmentData"].department;
        deleteh.splice(ind, 1);
        editHospitalInfo["departmentData"].department = deleteh;
        this.setState({
            editHospitalInfo,
        });
    }

    deleteEditAddress(ind) {
        const { editHospitalInfo } = this.state;
        var deleteh = editHospitalInfo["addressData"].address;
        deleteh.splice(ind, 1);
        editHospitalInfo["addressData"].address = deleteh;
        this.setState({
            editHospitalInfo,
        });
    }

    modalClose(){

        this.props.editModalClose();
        this.resetData();
       
    }

    resetData(){
        this.setState({
            editHospitalInfo: {
                id: "",
                name: "",
                switch_board_phone_number: "",
                contact_person_name: "",
                contact_person_phone_number: "",
                sac_code: "",
                email: "",
                addressData: { address: [{ name: "" }] },
                departmentData: { department: [{ name: "" }] },
               
            },
            submitModalFalg:0,
            error:"",
            loading:false
        });
    }

    modalCloseAfterSubmit(){
        this.props.editModalClose();
        this.props.refreshGetList();
        this.resetData();
    }

    handleUpdate() {
        const { editHospitalInfo } = this.state;
        var address = [];
        var flag = 0;
        editHospitalInfo.addressData.address.map(sub => {
            if (sub.name == "") {
                flag = 1;
                address.push({ name: sub.name, validate: true });
            } else {
                address.push({ name: sub.name, validate: false });
            }
        });

        var department = [];
        editHospitalInfo.departmentData.department.map(sub => {
            if (sub.name == "") {
                flag = 1;
                department.push({ name: sub.name, validate: true });
            } else {
                department.push({ name: sub.name, validate: false });
            }
        });
        
        editHospitalInfo["addressData"].address = address;
        editHospitalInfo["departmentData"].department = department;
        this.setState({
            editHospitalInfo,
            suggestionsActive: false,
            submitModalFalg: 1,
            loading:true
        });

        if (
            flag == 0 ||
            editHospitalInfo.name == "" ||
            editHospitalInfo.contact_person_name == "" ||
            editHospitalInfo.contact_person_phone_number == ""
        ) {

            hospitalService.update(this.state.editHospitalInfo)
            .then(response => {
               console.log(response);
               this.modalCloseAfterSubmit();
               
            })
            .catch((error) => {
                console.log("Error updating item:", error);
                this.setState({error:error,loading:false});
            });
          
        }
        
        
    }

    getAutoSearch() {
        const { editHospitalInfo } = this.state;

        hospitalService
            .getSuggestionList({ search: editHospitalInfo["name"], page: 1 })
            .then(response => {
                this.handleSuggestionHospitalList(response.data);
            });
    }

    handleSuggestionHospitalList(data) {
        this.setState({
            suggestionHospitalList: data,
            suggestionsActive: true,
        });
    }

    handleOnHospitalSelect(item) {
        const { editHospitalInfo } = this.state;
        editHospitalInfo["name"] = item.name;
        this.setState({
            suggestionHospitalList: [],
            suggestionsActive: false,
            editHospitalInfo,
        });
    }

    setEdit(data) {
        var address = [];
        data.addresses.map(sub => {
            address.push({ name: sub.name, validate: false });
        });

        var department = [];
        data.departments.map(sub => {
            department.push({ name: sub.name, validate: false });
        });

        this.setState({
            editHospitalInfo: {
                id: data._id,
                name: data.name,
                switch_board_phone_number: data.switch_board_phone_number,
                contact_person_name: data.contact_person_name,
                contact_person_phone_number: data.contact_person_phone_number,
                sac_code: data.sac_code,
                email: data.email,
                addressData: { address: address },
                departmentData: { department: department },
            },
        });
    }

    getSingle(id){
        hospitalService.getById(id)
        .then(response => {
                this.setEdit(response.data);
        });
    }

   
    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("ssgss", nextProps);
        if (nextProps.editModal==true) {
            this.getSingle(nextProps.editId);
        }
    }

    render() {
        const { submitModalFalg, error, loading  } = this.state;
        return (
            <div>
                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.props.editModal}
                    toggle={() => this.modalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.modalClose()}>
                        Edit Hospital
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>
                                            Hospital Name{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <div className="autocomplete">
                                            <div className="wrapper remove-rounder">
                                                <div className="sc-hKMtZM">
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .editHospitalInfo
                                                                .name
                                                        }
                                                        onChange={e =>
                                                            this.editHospitalInfoHandler(
                                                                "name",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                    {this.state
                                                        .suggestionsActive &&
                                                        this.state
                                                            .suggestionHospitalList
                                                            .length > 0 &&
                                                        this.state
                                                            .editHospitalInfo
                                                            .name != "" && (
                                                            <ul className="suggestions">
                                                                {this.state.suggestionHospitalList.map(
                                                                    (
                                                                        item,
                                                                        index,
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                index
                                                                            }
                                                                            onClick={() =>
                                                                                this.handleOnHospitalSelect(
                                                                                    item,
                                                                                )
                                                                            }>
                                                                            <i className="fa fa-search suggestionsSearch"></i>
                                                                            <span className="suggestionsText">
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    ),
                                                                )}
                                                            </ul>
                                                        )}
                                                </div>
                                                {submitModalFalg == 1 &&
                                                error ? (
                                                    <span className="text-danger">
                                                        {error.name
                                                            ? error.name
                                                            : ""}
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label>Switch Board Phone Number</label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            value={
                                                this.state.editHospitalInfo
                                                    .switch_board_phone_number
                                            }
                                            onChange={e =>
                                                this.editHospitalInfoHandler(
                                                    "switch_board_phone_number",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        {submitModalFalg == 1 && error ? (
                                            <span className="text-danger">
                                                {error.switch_board_phone_number
                                                    ? error.switch_board_phone_number
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Contact Person Name{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={
                                                this.state.editHospitalInfo
                                                    .contact_person_name
                                            }
                                            onChange={e =>
                                                this.editHospitalInfoHandler(
                                                    "contact_person_name",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        {submitModalFalg == 1 && error ? (
                                            <span className="text-danger">
                                                {error.contact_person_name
                                                    ? error.contact_person_name
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            Contact Person Phone Number{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            value={
                                                this.state.editHospitalInfo
                                                    .contact_person_phone_number
                                            }
                                            onChange={e =>
                                                this.editHospitalInfoHandler(
                                                    "contact_person_phone_number",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        {submitModalFalg == 1 && error ? (
                                            <span className="text-danger">
                                                {error.contact_person_phone_number
                                                    ? error.contact_person_phone_number
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>SAC Code</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={
                                                this.state.editHospitalInfo
                                                    .sac_code
                                            }
                                            onChange={e =>
                                                this.editHospitalInfoHandler(
                                                    "sac_code",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        {submitModalFalg == 1 && error ? (
                                            <span className="text-danger">
                                                {error.sac_code
                                                    ? error.sac_code
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={
                                                this.state.editHospitalInfo
                                                    .email
                                            }
                                            onChange={e =>
                                                this.editHospitalInfoHandler(
                                                    "email",
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        {submitModalFalg == 1 && error ? (
                                            <span className="text-danger">
                                                {error.email ? error.email : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    {this.state.editHospitalInfo.addressData
                                        .address &&
                                        this.state.editHospitalInfo.addressData.address.map(
                                            (sub, ind) => (
                                                <div
                                                    className="form-group"
                                                    key={ind}>
                                                    <label>
                                                        Hospital Address{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            style={{
                                                                width: "calc(100% - 30px)",
                                                            }}>
                                                            <textarea
                                                                className="form-control"
                                                                name=""
                                                                onChange={e =>
                                                                    this.editAddressHandler(
                                                                        e.target
                                                                            .value,
                                                                        ind,
                                                                    )
                                                                }
                                                                value={sub.name}
                                                                rows="2"></textarea>
                                                        </div>
                                                        {ind > 0 && (
                                                            <div>
                                                                <a
                                                                    className="ml-2"
                                                                    onClick={() =>
                                                                        this.deleteEditAddress(
                                                                            ind,
                                                                        )
                                                                    }
                                                                    href="javascript:;">
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {sub.validate == true ? (
                                                        <span className="text-danger">
                                                            The address field is
                                                            required.
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ),
                                        )}
                                    <div>
                                        <a
                                            href="javascript:;"
                                            onClick={() =>
                                                this.editAddMoreAddress()
                                            }
                                            className="sml-btn mt-2 mb-3">
                                            <i className="fa fa-plus"></i> Add
                                            More Address
                                        </a>
                                    </div>
                                    {this.state.editHospitalInfo.departmentData
                                        .department &&
                                        this.state.editHospitalInfo.departmentData.department.map(
                                            (sub, ind) => (
                                                <div
                                                    className="form-group"
                                                    key={ind}>
                                                    <label>
                                                        Hospital Department{" "}
                                                        <span className="text-danger">
                                                            *
                                                        </span>
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <div
                                                            style={{
                                                                width: "calc(100% - 30px)",
                                                            }}>
                                                            <Input
                                                                type="text"
                                                                className="form-control"
                                                                onChange={e =>
                                                                    this.editDepartmentHandler(
                                                                        e.target
                                                                            .value,
                                                                        ind,
                                                                    )
                                                                }
                                                                name=""
                                                                value={sub.name}
                                                            />
                                                        </div>
                                                        {ind > 0 && (
                                                            <div>
                                                                <a
                                                                    className="ml-2"
                                                                    onClick={() =>
                                                                        this.deleteEditDepartment(
                                                                            ind,
                                                                        )
                                                                    }
                                                                    href="javascript:;">
                                                                    <i className="fa fa-trash"></i>
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                    {sub.validate == true ? (
                                                        <span className="text-danger">
                                                            The department field
                                                            is required.
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            ),
                                        )}
                                    <div>
                                        <a
                                            href="javascript:;"
                                            onClick={() =>
                                                this.editAddMoreDepartment()
                                            }
                                            className="sml-btn mt-2 mb-3">
                                            <i className="fa fa-plus"></i> Add
                                            More Department
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={() => this.modalClose()}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal">
                                Cancel
                            </button>
                            {(loading == false || loading == null) && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.handleUpdate()}>
                                    Update
                                </button>
                            )}
                            {loading == true && (
                                <button
                                    className="btn btn-primary loader-icon"
                                    disabled>
                                    <Spinner className="img-svg"></Spinner>
                                </button>
                            )}
                        </div>
                    </ModalFooter>
                </Modal>
                {/* Add Modal */}
            </div>
        );
    }
}


export default Add;
