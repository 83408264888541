import axios from "axios";
import { authHeader,check419 } from "../helpers";

export const kitService = {
    addKit,
    getKitsList,
    kitListDropdown,
    kitListDropdownForReps,
    getById,
    updateKitStatus,
    update,
    delete: _delete,
    getKitsSearchList
};

function getKitsList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(process.env.REACT_APP_APIURL + "kits/getKits", requestOptions)
        .then(handleResponse)
        .then((kitsList) => {
            return kitsList;
        });
}
//search
function getKitsSearchList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "kits/kitListForSuggestion ",
        requestOptions
    )
        .then(handleResponse)
        .then((operationList) => {
            return operationList;
        });
}
//search


function kitListDropdown(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "kits/kitListForDropdown",
        requestOptions
    )
        .then(handleResponse)
        .then((kitListDropdown) => {
            return kitListDropdown;
        });
}

function kitListDropdownForReps(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "kits/kitListForRepsBooking",
        requestOptions
    )
        .then(handleResponse)
        .then((kitListDropdown) => {
            return kitListDropdown;
        });
}

function getById(id) {
    const requestOptions = {
        method: "GET",
        headers: authHeader(),
    };

    return fetch(
        process.env.REACT_APP_APIURL + `kits/getKit/${id}`,
        requestOptions
    ).then(handleResponse);
}

function addKit(kitInfo) {
    //console.log("---------", kitInfo);
    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     // headers: {"Content-Type": "application/json;charset=UTF-8"},
    //     body: JSON.stringify(kitInfo),
    // };

    // return fetch(
    //     process.env.REACT_APP_APIURL + "kits/saveKits",
    //     requestOptions
    // ).then(handleResponse);

    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(kitInfo)
    // };

    // return fetch(process.env.REACT_APP_APIURL+"kits/saveKits", requestOptions).then(handleResponse);
    return axios
        .post(process.env.REACT_APP_APIURL + "kits/saveKits", kitInfo,{headers : {...authHeader(),"Content-Type": "application/json"}})
        .then((result) => result)
        .catch((error) => {
            if(error?.response?.status === 419){
                check419();
            }else{
                console.error("Error in saveKits:", error);
                throw error;
            }
        });
}

function update(kitInfo) {
    console.log("---------", kitInfo);
    // const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify(kitInfo),
    // };

    // return fetch(
    //     process.env.REACT_APP_APIURL + "kits/updateKits",
    //     requestOptions
    // ).then(handleResponse);
    return axios
        .post(process.env.REACT_APP_APIURL + "kits/updateKits", kitInfo,{headers : {...authHeader(),"Content-Type": "application/json"}})
        .then((result) => result)
        .catch((error) => {
            if(error?.response?.status === 419){
                check419();
            }else{
                console.error("Error in updateKits:", error);
                throw error;
            }
        });
}

function updateKitStatus(kitInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(kitInfo),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "kits/updateKitStatus",
        requestOptions
    ).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(id),
    };

    return fetch(
        process.env.REACT_APP_APIURL + "kits/deleteKits",
        requestOptions
    ).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            } else if(response.status === 419){
                check419();
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}


