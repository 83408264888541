import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { bookingService, returnBookingService, collectionBookingService } from "../../../../services";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
}${separator}${date < 10 ? `0${date}` : `${date}`}`;


const NewDashboardSidebar = (props) => {
    const [requestCounts, setRequestCounts] = useState(props.requestCount);
    const [dispatchCounts, setDispatchCounts] = useState(props.dispatchCount);
    const [returnCounts, setReturnCounts] = useState(props.returnCount);
    const [collectionCounts, setCollectionCounts] = useState(props.collectionCount);
    const [today, setToday] = useState(todayDate);

    const pendingCount = () => {
        bookingService.countByStatus({
            type: ["loan"],
            status: ["pending"],
            deliveryDateFiter: "",
            toDeliveryDateFiter:""
        })
        .then(response => {
            setRequestCounts(response.data);
        }); 
    };

    const dispatchCount = () => {
        bookingService.countDispatch({
            type: ["loan"],
            status: ["dispatch", "booked"],
            deliveryDateFiter: today,
            toDeliveryDateFiter: today,
        })
        .then(response => {
            setDispatchCounts(response.data);
        });
    };

    const returnCount = () => {
        returnBookingService.countByDate({
            type: ["loan"],
            status: ["dispatch", "close"],
            returnDateFiter: today,
            toReturnDateFiter: today,
        })
        .then(response => {
            setReturnCounts(response.data);
        });
    };

    const collectionCount = () => {
        collectionBookingService.countByDate({
            type: ["loan"],
            status: ["dispatch"],
            collectionDateFilter: today,
            toCollectionDateFilter: today,
        })
        .then(response => {
            setCollectionCounts(response.data);
        });
    };

    const getCount = () => {
        pendingCount();
        dispatchCount();
        returnCount();
        collectionCount();
    };

    useEffect(() => {
        setToday(todayDate);
        getCount();
    }, []);

    useEffect(() => {
      if(props?.rejectStatus){
        pendingCount();
      }
    }, [props?.rejectStatus]);

    return (
        <div>
        <div className="list-group mt-4 pr-4">

            {/* <Link
                to="/admin/dashboard/booking-dispatch"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "dispatch" ? "active " : "")
                }>
                Dispatch
                <span className="badge badge-primary badge-pill">
                    {dispatchCounts != "" &&
                        dispatchCounts > 0 &&
                        dispatchCounts}
                </span>
            </Link> */}

            <Link
                to="/admin/dashboard/booking-dispatch"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "dispatch" ? "active " : "")
                }>
                Dispatch
                <span className="badge badge-primary badge-pill">
                    {dispatchCounts != "" &&
                        dispatchCounts > 0 &&
                        dispatchCounts}
                </span>
            </Link>

            {/* <Link
                to="/admin/dashboard/booking-returns"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "return" ? "active " : "")
                }>
                Returns
                <span className="badge badge-primary badge-pill">
                    {returnCounts != "" &&
                        returnCounts > 0 &&
                        returnCounts}
                </span>
            </Link> */}

            <Link
                to="/admin/dashboard/booking-returns"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "return" ? "active " : "")
                }>
                 Returns
                <span className="badge badge-primary badge-pill">
                    {returnCounts != "" &&
                        returnCounts > 0 &&
                        returnCounts}
                </span>
            </Link>

            {/* <Link
                to="/admin/dashboard/booking-collections"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "collection" ? "active " : "")
                }>
                Collections
                <span className="badge badge-primary badge-pill">
                    {collectionCounts != "" &&
                        collectionCounts > 0 &&
                        collectionCounts}
                </span>
            </Link> */}


            <Link
                to="/admin/dashboard/booking-collections"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "collection" ? "active " : "")
                }>
                Collections
                <span className="badge badge-primary badge-pill">
                    {collectionCounts != "" &&
                        collectionCounts > 0 &&
                        collectionCounts}
                </span>
            </Link>

            {/* <Link
                to="/admin/dashboard/booking-request"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "request" ? "active " : "")
                }>
                Booking Requests
                <span className="badge badge-primary badge-pill">
                    {requestCounts != "" &&
                        requestCounts > 0 &&
                        requestCounts}
                </span>
            </Link> */}

            <Link
                to="/admin/dashboard/booking-request"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "request" ? "active " : "")
                }>
                 Booking Requests
                <span className="badge badge-primary badge-pill">
                    {requestCounts != "" &&
                        requestCounts > 0 &&
                        requestCounts}
                </span>
            </Link>

            <Link
                to="/admin/dashboard/equipment-used-report"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "equipment" ? "active " : "")
                }>
                Equipment Used Report
            </Link>

            <Link
                to="/admin/dashboard/booking-transfer"
                className={
                    "list-group-item d-flex justify-content-between align-items-center " +
                    (props.activePage == "booking-transfer" ? "active " : "")
                }>
                Booking Transfer
            </Link>

        </div>
    </div>
    );
};
export default NewDashboardSidebar;

