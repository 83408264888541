import React, { Component } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { setActions } from "../../../../actions";

class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitList: [],
            productList: [],
            selectedKit: "",
            selectedProductId: "",
            selectedProductList: [],
            quantity: "",
            error: false,
            //product_error: "",
            kit_error: "",
            searchProducts: "",
            setData: {
                set_name: "",
                description: "",
                kitID: "",
                quarantine_check:false,
                missing_stock_check:false,
                productData: {
                    products: [],
                },
            },
            // quarantine_check: false,
            // missing_stock_check: false,
        };

        this.handleOnKitSearch = this.handleOnKitSearch.bind(this);
        this.handleOnKitSelect = this.handleOnKitSelect.bind(this);
        // this.handleOnProductSearch = this.handleOnProductSearch.bind(this);
        // this.handleOnProductSelect = this.handleOnProductSelect.bind(this);
        this.handleKitList = this.handleKitList.bind(this);
        // this.handleProductList = this.handleProductList.bind(this);
        // this.handleProductQuantity = this.handleProductQuantity.bind(this);
        this.addSet = this.addSet.bind(this);
        // this.setMissingStock = this.setMissingStock.bind(this);
        // this.setQuarantine = this.setQuarantine.bind(this);
    }

    // handleProductQuantity(quantity, index) {
    //     var selectedProductList = this.state.selectedProductList;
    //     selectedProductList[index].quantity = quantity;
    //     this.setState({
    //         selectedProductList: selectedProductList,
    //     });
    // }

    handleSetData = (name, value) => {
        const { setData } = this.state;
        setData[name] = value;
        this.setState({
            setData,
        });
    };

    handleOnKitSearch(searchString, result) {
        console.log(result);
        const { setData } = this.state;
        setData["kitID"] = "";
        setData["selectedKit"] = "";
        this.setState({
            setData,
        });

        this.props.dispatch(setActions.getKitsList({ search: searchString }));
    }

    handleOnKitSelect(item) {
        const { setData } = this.state;
        setData["kitID"] = item._id;
        setData["selectedKit"] = item;
        this.setState({
            setData,
        });
    }

    // setMissingStock(val) {

    //     const { setData } = this.state;
    //     setData["missing_stock_check"] = val;
    //     this.setState({
    //         setData,
    //     });

    //     this.setState({
    //         missing_stock_check: val
    //     });
    // }

    // setQuarantine(val) {
    //     const { setData } = this.state;
    //     setData["quarantine_check"] = val;
    //     this.setState({
    //         setData,
    //     });

    //     this.setState({
    //         quarantine_check: val
    //     });
    // }

    // handleOnProductSearch(searchString, result) {
    //     console.log(result);
    //     this.setState({
    //         searchProducts:searchString
    //     });

    //     this.props.dispatch(setActions.getProductsList({search: searchString}));
    // }

    // handleOnProductSelect(item) {

    //     item.name=item.nameOld;
    //     var id = item._id;
    //     var newProduct;
    //     var index;
    //     if (this.state.selectedProductList.length > 0) {
    //         //newProduct = this.state.selectedProductList.filter(product => product._id === id);
    //         newProduct = this.state.selectedProductList.find(product => product._id === id);
    //         index = this.state.selectedProductList.findIndex(product => product._id === id);
    //     }
    //     if (newProduct) {
    //         newProduct.quantity += 1;
    //         var list = this.state.selectedProductList;
    //         list[index] = newProduct;
    //         this.setState({
    //             selectedProductList: list
    //         });
    //     } else {
    //         item.quantity = 1;
    //         this.setState({
    //             selectedProduct:  item
    //         });

    //         this.setState(prevState => ({
    //             selectedProductList: [ item, ...prevState.selectedProductList]
    //         }));
    //     }

    //     this.setState({
    //         searchProducts:""
    //     });

    // }

    handleKitList(kitList) {
        this.setState({
            kitList: kitList,
        });
    }

    // handleProductList(productList) {

    //     productList.map((item)=>{
    //         item.nameOld=item.name;
    //         item.name=item.name+" ("+item.code+")";

    //     });
    //     this.setState({
    //         productList:  productList
    //     });

    // }

    // deleteProduct(e, id) {
    //     e.preventDefault();
    //     const newProductList = this.state.selectedProductList.filter(product => product._id !== id);
    //     this.setState({ selectedProductList: newProductList });
    // }

    addSet() {
    // if(this.state.selectedProductList.length === 0) {
    //     this.setState({
    //         error: true,
    //         product_error: "Please select atleast one item"
    //     });
    // }
    // else
    // {
    // this.setState({
    //     product_error: ""
    // });
    // }

        // if(this.state.setData.name== "" || this.state.setData.kitID== "" || this.state.setData.description== "") {
        var selectedProductList = this.state.selectedProductList;
        var tempProducts = [];

        selectedProductList.map((product) => {
            var tempObj = {};
            tempObj.id = product._id;
            tempObj.quantity = product.quantity;
            tempProducts.push(tempObj);
        });

        this.setState(
            {
                setData: {
                    ...this.state.setData,
                    productData: {
                        products: tempProducts,
                    },
                },
            },
            () => {
                this.props.dispatch(setActions.addSet(this.state.setData));
            }
        );
        // }

    // if(this.state.selectedKit === "") {
    //     this.setState({
    //         error: true,
    //         kit_error: "Please select kit"
    //     }, ()=> {
    //         setTimeout(() => {
    //             this.setState({
    //                 error: false,
    //                 kit_error: ""
    //             });
    //         }, 5000);
    //     });
    // }
    }

    componentDidMount() {
        this.handleOnKitSearch("", "");
    //this.handleOnProductSearch("", "");
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        if (nextProps.kitsList) {
            this.handleKitList(nextProps.kitsList);
        }
        if (nextProps.productsList) {
            this.handleProductList(nextProps.productsList);
        }

        // if(nextProps.editModal == false) {
        //     this.editModalClose();
        // }
        // if(nextProps.editModal == true) {
        //     this.setEditUser(nextProps.user.data);
        // }

        // if(nextProps.resetModal == false) {
        //     this.resetPasswordModalClose();
        // }
        // if(nextProps.resetModal == true) {
        //     this.resetPasswordModalOpen();
        // }

        // if(nextProps.deleteModal == false) {
        //     this.deleteModalClose();
        // }
        // if(nextProps.deleteModal == true) {
        //     this.deleteModalOpen();
        // }

        // // if(nextProps.editModalClose) {
        // //     this.editModalClose();
        // // }

        if (nextProps.error !== "") {
            this.setState({
                error: true,
            });
        }
    }

    render() {
        const { redirect, error } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        if (redirect) {
            return <Redirect to="/admin/kits" />;
        }

        return (
            <div>
                <Navbar activePage="kits" />
                <section className="b-burger">
                    <div className="container">
                        <h1 className="date-scroll pt-4">
                            {" "}
                            <Link to="/admin/kits">
                                <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                                <strong>Back</strong>
                            </Link>
                        </h1>

                        <div className="row pt-4">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="d-card">
                                    <h4>Basic Details</h4>
                                    <div className="form-group" style={{zIndex:1,position:"relative"}}>
                                        <label>Select Kit</label>
                                        <ReactSearchAutocomplete
                                            //className="form-control chosen-select"
                                            items={this.state.kitList}
                                            onSearch={this.handleOnKitSearch}
                                            onSelect={this.handleOnKitSelect}
                                            fuseOptions={{ minMatchCharLength: 1 }}
                                            inputDebounce="100"
                                            autoFocus
                                            placeholder="Search..."
                                            // styling={{searchIconMargin:"0 0 0 90%"}}
                                        />
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.kitID ? error.kitID : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    {/* <div className="form-group">
                                        <div className="row no-gutters">
                                                <div className="">
                                                    <Input className="form-check-input mx-1"
                                                        type="checkbox"
                                                        id="StockCheck"
                                                        checked={this.state.missing_stock_check}
                                                        value={this.state.missing_stock_check}
                                                        onChange={(e) => this.setMissingStock(e.target.checked)}
                                                      />
                                                    <label className="form-check-label mx-4 font-weight-bold" htmlFor="StockCheck">
                                                        Missing Stocks
                                                    </label>

                                                    <Input className="form-check-input mx-1" 
                                                        type="checkbox" 
                                                        id="StockUnChecked"
                                                        checked={this.state.quarantine_check}
                                                        value={this.state.quarantine_check}
                                                        onChange={(e) => this.setQuarantine(e.target.checked)}
                                                    />
                                                    <label className="form-check-label mx-4 font-weight-bold" htmlFor="StockUnChecked">
                                                    Quarantine
                                                    </label>
                                                </div>
                                           
                                        </div>
                                    </div> */}

                                    <div className="form-group">
                                        <label>Set Name (Set Unique Identifier)</label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            value={this.state.setData.set_name}
                                            onChange={(e) =>
                                                this.handleSetData("set_name", e.target.value)
                                            }
                                        />
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.set_name ? error.set_name : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="form-group">
                                        <label>Set Description</label>
                                        <textarea
                                            className="form-control"
                                            rows="5"
                                            value={this.state.setData.description}
                                            onChange={(e) =>
                                                this.handleSetData("description", e.target.value)
                                            }
                                        ></textarea>
                                        {this.state.error && error ? (
                                            <span className="text-danger">
                                                {error.description ? error.description : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                {/* <div className="d-card mt-4">
                                    <h4>Items</h4>
                                    <div className="">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Item Code</th>
                                                    <th>Item Name</th>
                                                    <th>Qty</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { this.state.selectedProductList.length > 0 && this.state.selectedProductList.map((product, index) => (
                                                    <tr key={index}>
                                                        <td>{product.code}</td>
                                                        <td>{product.name}</td>
                                                        <td className="align-items-start">
                                                            <input
                                                                type="number"
                                                                className="form-control sml-input"
                                                                value={product.quantity}
                                                                min="1"
                                                                onChange={(e) => this.handleProductQuantity(e.target.value, index)}
                                                            />
                                                        </td>
                                                        <td><a href="#" onClick={(e) => this.deleteProduct(e, product._id)}><i className="fa fa-trash"></i> </a></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div> 
                                    <div className="form-group">
                                        <label><strong>Add More Items</strong></label>
                                        <ReactSearchAutocomplete
                                            //className="form-control chosen-select"
                                            items={this.state.productList}
                                            onSearch={this.handleOnProductSearch}
                                            onSelect={this.handleOnProductSelect}
                                            fuseOptions={{ minMatchCharLength: 1 }}
                                            inputDebounce="100"
                                            inputSearchString={this.state.searchProducts}
                                            placeholder="Search..."
                                        />
                                        {(this.state.error && this.state.product_error) ? <span className="text-danger">{this.state.product_error ? this.state.product_error : ""}</span> : ""}
                                    </div>                         
                                </div> */}
                                <div className="mt-5 text-right">
                                    <Link
                                        to="/admin/kits"
                                        className="btn btn-secondary mb-3 mr-2"
                                    >
                    Cancel
                                    </Link>
                                    <button
                                        className="btn btn-primary mb-3"
                                        onClick={this.addSet}
                                    >
                    Save Changes
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-4"></div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { kitsList, productsList, redirect, error } = state.rootReducer.sets;

    return {
        kitsList,
        productsList,
        redirect,
        error,
    };
}

export default connect(mapStateToProps)(Index);
