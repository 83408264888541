import React, { useState } from "react";
import { operationService } from "../../../../../services/operation.services";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
const Index = ({ getOperation }) => {
    const [modalerror, setModalError] = useState("");
    const [addNewModal, setAddNewModal] = useState(false);
    const [name, setName] = useState("");

    const addOperation = () => {
        const addOperation = { name: name };

        operationService
            .addOperationsList(addOperation)
            .then((res) => {
                console.log(res);
                setAddNewModal(false);
                getOperation();
                setName("");
                setModalError("");

                console.log("-----data", addOperation);
            })
            .catch((error) => {
                console.log(error);
                setModalError(error.name[0]);
            });
    };

    const nameHandler = (e) => {
        setName(e.target.value);
    };

    const handleSubmit = () => {
        addOperation();
    };

    console.log(addNewModal);
    const addNewModalOpen = () => {
        setAddNewModal(true);
    };

    const addNewModalClose = () => {
        setAddNewModal(false);
        setModalError("");
    };

    return (
        <>
            <button
                className="btn btn-primary add_option"
                onClick={addNewModalOpen}
            >
                <i className="fa fa-plus"></i> <span>Add New</span>
            </button>

            <Modal
                size="md"
                backdrop="static"
                isOpen={addNewModal}
                toggle={addNewModalClose}
            >
                <ModalHeader className="" toggle={addNewModalClose}>
                    Add New Operation
                </ModalHeader>
                <ModalBody>
                    <form className="">
                        <div className="form-group row">
                            <div className="col">
                                <label>Operation name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={nameHandler}
                                />
                                {/* {this.state.error && error ? (
                                    <span className="text-danger">
                                        {error.name ? error.name : ""}
                                    </span>
                                ) : (
                                    ""
                                )} */}
                                <span className="text-danger">
                                    {modalerror}
                                </span>
                            </div>
                        </div>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button
                            onClick={addNewModalClose}
                            className="btn btn-secondary mr-2"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>

                        <button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Index;
