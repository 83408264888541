import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { bookingActions } from "../../../../actions";
import dateFormat from "dateformat";
import { bookingService } from "../../../../services";
import RepsUpdateModal from "./RepsUpdateModal";

class Close extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            //bookingList: false
            search: "",
            activePage: 1,
            limit: 50,
            totalItemsCount: 1,
            searchOperationDate: "",
            setList: [],
            itemUsedModal: false,
            repsUpdateModal: false,
            bookingRepsUpdateId: 0,
            searchReps:""
        };

        this.getList = this.getList.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.repsUpdateModalOpen = this.repsUpdateModalOpen.bind(this);
        this.repsUpdateModalClose = this.repsUpdateModalClose.bind(this);
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getList();
            },
        );
    }

    handlePageStates(activePage, totalItemsCount, limit) {
        this.setState({
            activePage: activePage,
            totalItemsCount: totalItemsCount,
            limit: limit,
        });
    }

    getList() {
        this.props.dispatch(
            bookingActions.getList({
                working_delivery_from_date: "",
                working_delivery_to_date: "",
                operation_from_date: "",
                type: ["loan", "consignment"],
                status: ["close"],
                search: this.state.search,
                operation_date: this.state.searchOperationDate,
                page: this.state.activePage,
                limit: this.state.limit,
            }),
        );
    }

    handleSearch(val) {
        this.setState(
            {
                search: val,
                activePage: 1,
            },
            () => {
                this.getList();
            },
        );
    }

    itemUsedModalOpen(id) {
        bookingService.getSetList(id).then(res => {
            this.setState({
                setList: res.data[0].bookingKitSetId,
                itemUsedModal: true,
            });
        });
    }

    itemUsedModalClose() {
        this.setState({
            itemUsedModal: false,
        });
    }

    repsUpdateModalOpen(item) {
        //this.getList();
        if(item?.bookings?._id){
            this.props.dispatch(bookingActions.getRepsList({ search: this.state.searchReps }));
            setTimeout(() => {
                this.getList();
                this.props.dispatch(bookingActions.getById(item.bookings._id));
            }, 500);
        }
        this.setState({
            repsUpdateModal: true,
            bookingRepsUpdateId: item.bookings._id
        });
    }

    repsUpdateModalClose(flag) {
        if(flag){
            this.getList();
            this.setState({
                repsUpdateModal: false,
            });
        }else{
            //this.getList();
            this.setState({
                repsUpdateModal: false,
            });
        }
    }

    componentDidMount() {
        this.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.refreshList == true || nextProps.redirect == true) {
            this.setState(
                {
                    activePage: 1,
                },
                () => {
                    this.getList();
                },
            );
        }

        if (
            nextProps.activePage != "" &&
            nextProps.totalItemsCount != "" &&
            nextProps.limit != ""
        ) {
            this.handlePageStates(
                nextProps.activePage,
                nextProps.totalItemsCount,
                this.state.limit,
            );
        }
    }

    render() {
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "admin") {
            return <Redirect to="/" />;
        }
        const { bookingList, loading } = this.props;
        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container-fluid">
                    <div className="row pt-4 align-items-center">
                        <div className="col-md-5">
                            <ul className="sub-menu">
                                <li>
                                    <Link to="/admin/bookings">Open</Link>
                                </li>
                                <li>
                                    <Link to="/admin/bookings/consignment">
                                        Consignment
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/admin/bookings/close"
                                        className="active">
                                        Closed
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/bookings/cancel">
                                        Cancelled
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <form className="form-search">
                                <Input
                                    className="form-control mr-sm-2"
                                    type="text"
                                    placeholder="Search.."
                                    value={this.state.search}
                                    onChange={e =>
                                        this.handleSearch(e.target.value)
                                    }
                                />
                            </form>
                        </div>
                        <div className="col-sm-2 text-right">
                            <Link
                                className="btn btn-primary add_option"
                                to="/admin/booking/add">
                                <i className="fa fa-plus"></i>{" "}
                                <span>Add New</span>
                            </Link>
                        </div>
                    </div>

                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col" width="200">
                                        Hospital Name
                                    </th>
                                    <th scope="col">Contact Person</th>
                                    <th
                                        scope="col"
                                        style={{ minWidth: "100px" }}>
                                        Sets
                                    </th>
                                    <th scope="col" width="200">
                                        Important Dates
                                    </th>
                                    <th
                                        scope="col"
                                        className="text-right"
                                        width="175">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bookingList &&
                                    bookingList.map(item => (
                                        <tr key={item._id}>
                                            <td
                                                width="200"
                                                data-th="Hospital Name">
                                                <div className="">
                                                    <Link
                                                        to={
                                                            "/admin/booking/details/" +
                                                            item.bookings._id
                                                        }>
                                                        <span>
                                                            <strong>
                                                                <span
                                                                    style={{
                                                                        display:
                                                                            "block",
                                                                    }}
                                                                    className="theme-text">
                                                                    {
                                                                        item
                                                                            .bookings
                                                                            .booking_id
                                                                    }
                                                                </span>
                                                                {
                                                                    item
                                                                        .hospitalId
                                                                        .name
                                                                }
                                                            </strong>
                                                        </span>
                                                        <br />
                                                        <small>
                                                            Booked on{" "}
                                                            {dateFormat(
                                                                item.bookings
                                                                    .createdAt,
                                                                "ddd,mmm dS",
                                                            )}
                                                        </small>
                                                        {item.bookings
                                                            .po_number !==
                                                            "" && (
                                                            <React.Fragment>
                                                                <br />
                                                                <small className="mobilehide">
                                                                    PO Number:{" "}
                                                                    {
                                                                        item
                                                                            .bookings
                                                                            .po_number
                                                                    }
                                                                </small>
                                                            </React.Fragment>
                                                        )}
                                                    </Link>
                                                </div>
                                            </td>
                                            <td data-th="Contact Person">
                                                <div className="info-left">
                                                    <strong>
                                                        {
                                                            item.hospitalId
                                                                .contact_person_name
                                                        }{" "}
                                                    </strong>
                                                    <br />
                                                    <small>
                                                        Location:{" "}
                                                        {item.bookings.hospital_address}{" "}
                                                    </small>
                                                    <br />
                                                    <small>
                                                        Email:{" "}
                                                        {item.hospitalId.email}
                                                    </small>
                                                    <br />
                                                    <small>
                                                        Phone:{" "}
                                                        {
                                                            item.hospitalId
                                                                .contact_person_phone_number
                                                        }
                                                    </small><br />
                                                    <small>Department:{item.bookings.hospital_department}</small>
                                                </div>
                                            </td>
                                            <td data-th="Sets">
                                                <small>
                                                    {item.bookingKitSetId &&
                                                        item.bookingKitSetId.map(
                                                            (kset, ind) => (
                                                                <React.Fragment
                                                                    key={ind}>
                                                                    {/* <div><a href="javascript:;" onClick={()=>this.handleKitDetails(kset.setId[0]._id)} >{kset.setId[0].set_name} </a></div> */}
                                                                    <div>
                                                                        <span>
                                                                            {
                                                                                kset
                                                                                    .setId[0]
                                                                                    .set_name
                                                                            }{" "}
                                                                        </span>
                                                                    </div>
                                                                </React.Fragment>
                                                            ),
                                                        )}
                                                </small>
                                            </td>
                                            <td data-th="Important Dates">
                                                <small>
                                                    Delivery Date:{" "}
                                                    <strong>
                                                        {dateFormat(
                                                            item.bookings
                                                                .delivery_date,
                                                            "ddd,mmm dS",
                                                        )}{" "}
                                                        {
                                                            item.bookings
                                                                .delivery_date_slot
                                                        }
                                                    </strong>
                                                    <br />
                                                    Operation Date:{" "}
                                                    <strong>
                                                        {dateFormat(
                                                            item.bookings
                                                                .operation_date,
                                                            "ddd,mmm dS",
                                                        )}{" "}
                                                        {
                                                            item.bookings
                                                                .operation_date_slot
                                                        }
                                                    </strong>
                                                    <br />
                                                    Collection Date:{" "}
                                                    <strong>
                                                        {dateFormat(
                                                            item.bookings
                                                                .collection_date,
                                                            "ddd,mmm dS",
                                                        )}
                                                    </strong>
                                                </small>
                                            </td>
                                            <td className="text-right">
                                                <div className="action-area dropdown">
                                                    <span className="capsule green-dim">
                                                        Closed
                                                    </span>
                                                    <a
                                                        className="dropdown-toggle"
                                                        href="#"
                                                        id="navbarDropdown"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <span className="dots">
                                                            ...
                                                        </span>
                                                    </a>
                                                    <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="navbarDropdown">
                                                        <Link
                                                            className="dropdown-item"
                                                            to={
                                                                "/admin/booking/item-used/c/" +
                                                                item.bookings
                                                                    ._id
                                                            }>
                                                            Item Used
                                                        </Link>

                                                        <Link
                                                            className="dropdown-item"
                                                            to={
                                                                "/admin/booking/details/" +
                                                                item.bookings
                                                                    ._id
                                                            }>
                                                            More Details
                                                        </Link>
                                                           
                                                        <a
                                                            href="javascript:;"
                                                            onClick={() =>
                                                                this.repsUpdateModalOpen(
                                                                    item
                                                                )
                                                            }
                                                            className="dropdown-item">
                                                            Assign Reps
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                {bookingList &&
                                    bookingList.length == 0 &&
                                    loading == false && (
                                        <tr className="text-center">
                                            <td colSpan="5">No Record Found</td>
                                        </tr>
                                    )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {bookingList && bookingList.length > 0 && (
                    <div>
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.limit}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination justify-content-center mb-5"
                        activeLinkClass="active"
                        nextPageText="Next"
                        prevPageText="Prev"
                    />
                    <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                    </div>
                )}

                <RepsUpdateModal bookingId = {this.state.bookingRepsUpdateId} isRepsUpdateModal = {this.state.repsUpdateModal} repsUpdateModalClose = {(flag)=>this.repsUpdateModalClose(flag)}/>

                <Modal
                    size="md"
                    backdrop="static"
                    isOpen={this.state.kitSetModal}
                    toggle={() => this.kitSetModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetModalClose()}>
                        Delete Hospital
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">
                            Please confirm if you want to delete this hospital?
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className=""></div>
                    </ModalFooter>
                </Modal>

                {/* Delete Modal */}

                <Modal
                    size="ml"
                    backdrop="static"
                    isOpen={this.state.itemUsedModal}
                    toggle={() => this.itemUsedModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.itemUsedModalClose()}>
                        Item Used
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Status</th>
                                    <th>Set Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.setList &&
                                    this.state.setList.map((item, index) => (
                                        <tr key={index}>
                                            <td className="align-top">
                                                <Input
                                                    type="checkbox"
                                                    className="ml-0"
                                                    readOnly
                                                    checked={
                                                        item.item_in_used ==
                                                        true
                                                            ? true
                                                            : false
                                                    }
                                                />
                                            </td>
                                            <td>{item.setData[0].set_name}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary ml-2"
                                onClick={() => this.itemUsedModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                {/* item used Modal */}
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { bookingList, activePage, totalItemsCount, limit,redirect, loading } =
        state.rootReducer.bookings;
    const { error, refreshList } = state.rootReducer.hospitals;
    return {
        bookingList,
        error,
        refreshList,
        loading,
        activePage,
        totalItemsCount,
        limit,
        redirect
    };
}

export default connect(mapStateToProps)(Close);
