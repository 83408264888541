import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table} from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import dateFormat from "dateformat";
import moment from "moment";
import * as excelJs from "exceljs";
import { collectionBookingService } from "../../../../services/collectionBooking.services";
import Collection from "../Booking/Collection";
import CollectionDetails from "./Model/CollectionDetails";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { debounce } from "lodash";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;


const NewAdminBookingReturn = () => {
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [search, setSearch] = useState("");
    const [filterDeliveryToDate, setfilterDeliveryToDate] = useState(todayDate);
    const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState(todayDate);
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const [dayActive, setDateActive] = useState("today");
    const [collectionList, setCollectionList] = useState([]);
    const [collectionModal, setCollectionModal] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);
    const [detailsModalResult, setDetailsModalResult] = useState([]);
    const [bookingId, setBookingId] = useState(0);

    const [searchDeliveryToDate, setSearchDeliveryToDate] = useState(newDate);
    const [searchDeliveryFromDate, setSearchDeliveryFromDate] = useState(newDate);
    const [listRefresh,setListRefresh] = useState(false);

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
        debouncedSearch(val);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        // getList();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const toDay = () => {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        setfilterDeliveryToDate(todayDate);
        setfilterDeliveryFromDate(todayDate);
        setSearchDeliveryToDate(newDate);
        setSearchDeliveryFromDate(newDate);
        setActivePage(1);
        setDateActive("today");
        //getList(todayDate, todayDate);
    };

    const nextDays = () => {
        let newDate = new Date();
        let date = newDate.getDate() + 1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const tomorrowDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        const searchTomorrowDate = new Date(tomorrowDate);
        setfilterDeliveryToDate(tomorrowDate);
        setfilterDeliveryFromDate(tomorrowDate);
        setSearchDeliveryToDate(searchTomorrowDate);
        setSearchDeliveryFromDate(searchTomorrowDate);
        setActivePage(1);
        setDateActive("tomorrow");
        //getList(tomorrowDate, tomorrowDate);
    };

    const pastBookings = () => {
        var pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
        var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        
        const searchFromDate = new Date(pastYearDate);
        const searchToDate = new Date(yesterdayDate);
        setfilterDeliveryToDate(yesterdayDate);
        setfilterDeliveryFromDate(pastYearDate);
        setSearchDeliveryToDate(searchToDate);
        setSearchDeliveryFromDate(searchFromDate);
        setActivePage(1);
        setDateActive("past");
        //getList(pastYearDate, yesterdayDate);
    };


    const getList = async (deliveryDate, toDeliveryDate,searchTerm,currentPage) => {
        setCollectionList([]);
        setLoading(true);
        try {
            const res = await collectionBookingService.getList({
                bookedByFilter: "",
                hospitalFilter: "",
                type: ["loan"],
                status: ["dispatch", "close"],
                collectionDateFilter: deliveryDate,
                toCollectionDateFilter: toDeliveryDate,
                search: searchTerm != undefined ? searchTerm : search,
                page: currentPage != undefined ? currentPage : activePage,
                limit: limit,
            });
            setCollectionList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
            setListRefresh(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getList(filterDeliveryFromDate, filterDeliveryToDate);
    }, [activePage,dayActive]);

    useEffect(() => {
        if(listRefresh){
            getList(filterDeliveryFromDate,filterDeliveryToDate);
        }
    }, [listRefresh]);

    const detailsModalOpen = (item) => {
        setDetailsModal(true);
        setDetailsModalResult(item);
    };

    const detailsModalClose = () => {
        setDetailsModal(false);
    };

    const collectionModalOpen = (id) => {
        setCollectionModal(true);
        setBookingId(id);
    };

    const collectionModalClose = () => {
        setCollectionModal(false);
        setBookingId(0);
    };

    const collectionSuccess = () => {
        setCollectionModal(false);
        setBookingId(0);
        setActivePage(1);
        getList(filterDeliveryFromDate, filterDeliveryToDate);
    };

    const handleSearchDeliveryFromDate = (val) => {
        setActivePage(1);
        setSearchDeliveryFromDate(val != null ? new Date(val) : "");
        setfilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    const handleSearchDeliveryToDate = (val) => {
        setActivePage(1);
        setSearchDeliveryToDate(val != null ? new Date(val) : "");
        setfilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    // const handleclearToDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryToDate("");
    //         setfilterDeliveryToDate("");
    //         setListRefresh(true);
    //     }
    // };

    // const handleclearFromDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryFromDate("");
    //         setfilterDeliveryFromDate("");
    //         setListRefresh(true);
    //     }
    // };

    const generateTemplate = async (data) => {
        const workbook = new excelJs.Workbook();
        const ws = workbook.addWorksheet("Test Worksheet");
        const options = ["Normal", "High"];
        const headers = [
            "Collection Number",
            "Hospital Name",
            "Address",
            "Contact Name",
            "Contact Number",
            "Department",
            "No. of Boxes",
            "Sets Name & No.",
            "Priority Level",
            "Comments",
        ];

        let dateInfo = "";
        let dayActiveString = dayActive === "past" ? "past booking " : dayActive + " ";

        if(dayActive === "past"){
            let dateTo = "";
            let dateFrom = "";

            if(filterDeliveryFromDate != ""){
                dateFrom = "from "+ moment(filterDeliveryFromDate).format("DD/MM/YYYY")+" ";
            }else{
                dateFrom = "";
            }
            
            if(filterDeliveryToDate != ""){
                dateTo = "to "+ moment(filterDeliveryToDate).format("DD/MM/YYYY")+" ";
            }else{
                dateTo = "";
            }

            dateInfo = dateFrom + dateTo;

            //dateInfo =  "from "+ moment(filterDeliveryFromDate).format("DD/MM/YYYY") + " to "+ moment(filterDeliveryToDate).format("DD/MM/YYYY");
        }else{
            dateInfo = moment(filterDeliveryFromDate).format("DD/MM/YYYY");
        }

        let headingContent =  "Collection "+ dayActiveString + dateInfo;
        
        // Add a header row with a merged cell for the heading
        const headingRow = ws.addRow([headingContent]);
        headingRow.getCell(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
        };
    
        headingRow.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 13,
            };
        });
    
        ws.mergeCells(dayActive === "past" ? "A1:D1" : "A1:B1"); // Merge cells for the heading
    
        const header1Row = ws.addRow(headers);

        header1Row.eachCell((cell) => {
            cell.font = {
                bold: true,
                size: 11,
            };
            cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "CCC0DA" } // Background color (e.g., yellow)
            };
            cell.border = {
                top: {style:"thin"},
                left: {style:"thin"},
                bottom: {style:"thin"},
                right: {style:"thin"}
            };
        });
    
        data.forEach((item, index) => {
            const kitset = item.bookingKitSetId.map(kit => kit.setId[0].set_name).join("\n");
            const boxesSet = item.bookingdispatches[0].bookingdispatchboxes.map(box => {
                return `${box.box_type} - ${box.number_of_box} ${box.number_of_box > 1 ? "Boxes" : "Box"}`;
            }).join("\n");
    
            const rowData = [
                index + 1,
                item.hospitalId.name,
                item.bookings.hospital_address,
                item.hospitalId.contact_person_name,
                item.hospitalId.contact_person_phone_number,
                item.bookings.hospital_department,
                boxesSet,
                kitset,
                item.bookingcollections[0] ? item.bookingcollections[0].priority_level : "",
                item.bookingcollections[0] ? item.bookingcollections[0].comment : "",
            ];

            let setLength = item?.bookingKitSetId?.length ? item.bookingKitSetId.length : 1;
            let boxLength = item?.bookingdispatches && item?.bookingdispatches[0]?.bookingdispatchboxes.length ? item?.bookingdispatches[0]?.bookingdispatchboxes.length : 1;
    
            let row = ws.addRow(rowData);

            row.height = setLength >= boxLength ? setLength*12 : boxLength*12;
            ws.getCell("G"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            ws.getCell("H"+row?._number).alignment = { vertical: "bottom", horizontal: "left" };
            // ws.properties.outlineLevelRow = 1;
        });
    
        // ws.columns.forEach((col) => (col.width = 18));

        // Iterate through each column to calculate and set width
        ws.columns.forEach((column, columnIndex) => {
            let maxLength = 0;
            // Start from the third row (index 2) to skip the header row
            for (let rowIndex = 3; rowIndex <= ws.rowCount; rowIndex++) {
                const cellValue = ws.getCell(rowIndex, columnIndex + 1).value;
                if(columnIndex+1 === 8 || columnIndex+1 === 7){
                    const array = cellValue.split("\n");
                    if(array?.length){
                        array.forEach(str => {
                            if (str.length > maxLength) {
                                maxLength = str.length;
                            }
                        });
                    }else{
                        if (cellValue && cellValue.toString().length > maxLength) {
                            maxLength = cellValue.toString().length;
                        }
                    }
                }else{
                    if (cellValue && cellValue.toString().length > maxLength) {
                        maxLength = cellValue.toString().length;
                    }
                }
            }
            // Set the width of the column based on the maximum length of its content
            column.width = maxLength < 20 ? 20 : maxLength + 2; // Add padding
        });
    
        ws.dataValidations.add("I3:I99999", {
            type: "list",
            allowBlank: false,
            formulae: [`"${options.join(",")}"`],
            showDropDown: false,
            showDropDownInCell: true,
        });
    
        ws.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
                row.eachCell((cell) => {
                    cell.border = {
                        top: { style: "thin", color: { argb: "000000" } },
                        left: { style: "thin", color: { argb: "000000" } },
                        bottom: { style: "thin", color: { argb: "000000" } },
                        right: { style: "thin", color: { argb: "000000" } },
                    };
                });
            }
            if (rowNumber === 1) {
                row.eachCell((cell) => {
                    cell.font = {
                        bold: true,
                        size: 13,
                    };
                });
            }
        });
    
        ws.getColumn("I").eachCell({ includeEmpty: true }, function(cell) {
            if (cell.value === "High") {
                cell.font = {
                    color: { argb: "FFDC143C"  } 
                };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFC0CB" }, 
                };
            } else if (cell.value === "Normal") {
                cell.font = {
                    color: { argb: "000000" }, 
                };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFFFFF" }, 
                };
            }
        });
        for (let rowNumber = 2; rowNumber <= ws.rowCount; rowNumber++) {
            const priorityCell = ws.getCell(`I${rowNumber}`);
            if (priorityCell.value === "High") {
                priorityCell.font = {
                    color: { argb: "FFDC143C" },
                };
                priorityCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFC0CB" },
                };
            } else if (priorityCell.value === "Normal") {
                priorityCell.font = {
                    color: { argb: "000000" },
                };
                priorityCell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFFFFFFF" },
                };
            }
        }
    
    
        const excelBlob = await workbook.xlsx.writeBuffer();    
        const excelUrl = URL.createObjectURL(
            new Blob([excelBlob], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
        );
    
        const link = document.createElement("a");
        link.href = excelUrl;
        link.download = "booking-collection";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(excelUrl);
        document.body.removeChild(link);
    };

    const debouncedSearch = debounce((searchTerm) => {
        // Perform the search or API call with searchTerm
        getList(filterDeliveryFromDate,filterDeliveryToDate,searchTerm,1);
    }, 800);

    return (
        <div>
            <div>
            </div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                            <DashboardSidebar
                                activePage="collection"
                                collectionCount={totalItemsCount}
                            />
                        </div>

                        <div className="col-lg-10">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12">
                                        <div className="sub-menu mb-lg-0 mb-sm-3">
                                            <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example">
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "today"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    //disabled={loading == true ? true : false}
                                                    onClick={() =>
                                                        !loading ? toDay() : ""
                                                    }>
                                                    {"Today"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary mx-1 " +
                                                        (dayActive ==
                                                            "tomorrow"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    //disabled={loading == true ? true : false}
                                                    onClick={() =>
                                                        !loading ? nextDays() : ""
                                                    }>                                                   
                                                    {"Tomorrow"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "past"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    //disabled={loading == true ? true : false}
                                                    onClick={() =>
                                                        !loading ? pastBookings() : ""
                                                    }>
                                                    {"Past Bookings"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <form className="form-search my-3 my-sm-0">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </form>
                                    </div>
                                    <div className={dayActive === "past" ? "col-lg-4 col-sm-6" : "col-lg-4 col-sm-6 text-right"}>
                                    <div className={dayActive === "past" ? "label_adjust lable-field-position" : ""}>
                                        {dayActive === "past" ?
                                            <label className="small mb-0">
                                                Collection Date{" "}
                                            </label> : ""
                                        }
                                        {/* <button type="button" onClick={() => generateTemplate(collectionList)} className="btn btn-default add_option"> Print </button> */}
                                        {dayActive === "past" ? 
                                        <div className="row no-gutters mb-2">
                                            <div className="col-lg-4 col-md-4 col-6">
                                                <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    showClearButton={false}
                                                    placeholderText="From"
                                                    selected={searchDeliveryFromDate}
                                                    onChange={(e) =>handleSearchDeliveryFromDate(e)}
                                                    //onKeyDown={handleclearFromDate}
                                                    className="form-control"
                                                    disabled={!loading ? false : true}
                                                    customInput={
                                                        <MaskedInput
                                                            pipe={autoCorrectedDatePipe}
                                                            mask={[
                                                                /\d/,
                                                                /\d/,
                                                                "/",
                                                                /\d/,
                                                                /\d/,
                                                                "/",
                                                                /\d/,
                                                                /\d/,
                                                                /\d/,
                                                                /\d/,
                                                            ]}
                                                            keepCharPositions={true}
                                                            guide={true}
                                                            showMask={false}
                                                        />
                                                    }
                                                />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-6">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="To"
                                                        selected={searchDeliveryToDate}
                                                        onChange={(e) => handleSearchDeliveryToDate(e)}
                                                        //onKeyDown={handleclearToDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-6">
                                            <button type="button" onClick={() => generateTemplate(collectionList)} className="btn btn-default add_option mx-2"> Print </button>
                                                
                                                </div>
                                            </div> : 
                                            <button type="button" onClick={() => generateTemplate(collectionList)} className="btn btn-default add_option"> Print </button>
                                               
                                        }
                                    </div>
                                    </div>
                                    </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">Set</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">
                                                    Dispatched Boxes
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-right"
                                                    style={{
                                                        maxWidth: "210px",
                                                    }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {collectionList &&
                                                collectionList.map(item => (
                                                    <tr key={item._id}>
                                                        <td
                                                            width="200"
                                                            data-th="Booking #"
                                                            className="align-top">
                                                            <Link
                                                                to={
                                                                    "/admin/booking/details/" +
                                                                    item
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item
                                                                                .bookings
                                                                                .booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td
                                                            data-th="Hospital Name"
                                                            className="align-top">
                                                            <div className="">
                                                                {
                                                                    item
                                                                        .hospitalId
                                                                        .name
                                                                }
                                                            </div>
                                                        </td>
                                                        <td
                                                            data-th="Set"
                                                            className="align-top">
                                                            <ul className="list">
                                                                {item.bookingKitSetId &&
                                                                    item.bookingKitSetId.map(
                                                                        (
                                                                            kset,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <li>
                                                                                    <span
                                                                                        className={
                                                                                            "status circle mr-1 " +
                                                                                            (kset.status ==
                                                                                                "close"
                                                                                                ? "green "
                                                                                                : "red ")
                                                                                        }
                                                                                        data-toggle="tooltip"
                                                                                        data-placement="top"
                                                                                        title=""
                                                                                        data-original-title="Retuned"></span>
                                                                                    <span>
                                                                                        {
                                                                                            kset
                                                                                                .setId[0]
                                                                                                .set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </li>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </ul>
                                                        </td>
                                                        <td className="align-top">
                                                            {dateFormat(
                                                                item
                                                                    .bookings
                                                                    .collection_date,
                                                                "ddd,mmm dS",
                                                            )}
                                                        </td>
                                                        <td
                                                            data-th="Dispatched Boxes"
                                                            className="align-top">
                                                            {item.bookingdispatches &&
                                                                item.bookingdispatches[0].bookingdispatchboxes.map(
                                                                    (
                                                                        box,
                                                                        ind,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                ind
                                                                            }>
                                                                            <div>
                                                                                {
                                                                                    box.box_type
                                                                                }{" "}
                                                                                -{" "}
                                                                                {
                                                                                    box.number_of_box
                                                                                }{" "}
                                                                                {box.number_of_box >
                                                                                    1
                                                                                    ? "Boxes"
                                                                                    : "Box"}
                                                                            </div>
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                        </td>

                                                        <td className="align-top">
                                                            {item.bookings
                                                                .status ==
                                                                "dispatch" &&
                                                                (dayActive ==
                                                                    "today" ||
                                                                    dayActive ==
                                                                    "past") &&
                                                                item
                                                                    .bookingcollections
                                                                    .length ==
                                                                0 && (
                                                                    <React.Fragment>
                                                                        <div style={{display:"flex"}}>
                                                                            <a
                                                                                href="javascript:;"
                                                                                onClick={() =>
                                                                                    collectionModalOpen(
                                                                                        item
                                                                                            .bookings
                                                                                            ._id,
                                                                                    )
                                                                                }
                                                                                className="btn btn-primary ml-2">
                                                                                Collect
                                                                            </a>

                                                                            <a className="btn text-right" style={{visibility : "hidden"}}>Details</a>
                                                                        </div>
                                                                    </React.Fragment>
                                                                   
                                                                )}
                                                            {item.bookings
                                                                .status ==
                                                                "dispatch" &&
                                                                (dayActive ==
                                                                    "today" ||
                                                                   dayActive ==
                                                                    "past") &&
                                                                item
                                                                    .bookingcollections
                                                                    .length >
                                                                0 && (
                                                                    <React.Fragment>
                                                                        <span className="capsule green-dim">
                                                                            Collected
                                                                        </span>
                                                                        <a
                                                                            href="javascript:;"
                                                                            onClick={() =>
                                                                                detailsModalOpen(
                                                                                    item.bookingcollections,
                                                                                )
                                                                            }
                                                                            className="btn ml-2">
                                                                            Details
                                                                        </a>
                                                                    </React.Fragment>
                                                                )}
                                                        </td>
                                                    </tr>
                                                ))}

                                            {collectionList && collectionList.length == 0 && loading == true &&                                                    
                                                <div className="loading-state">
                                                    <div className="loading"></div>
                                                </div>                                               
                                            }

                                            {collectionList &&
                                                collectionList.length ==
                                                0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="5">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {collectionList &&
                                collectionList.length > 0 && (
                                    <div>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={limit}
                                            totalItemsCount={
                                                totalItemsCount
                                            }
                                            pageRangeDisplayed={5}
                                            onChange={handlePageChange.bind(
                                                this,
                                            )}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            innerClass="pagination justify-content-center mb-5"
                                            activeLinkClass="active"
                                            nextPageText="Next"
                                            prevPageText="Prev"
                                        />
                                        <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                    </div>
                                )}

                            <CollectionDetails
                                bookingId={bookingId}
                                detailsModal={detailsModal}
                                detailsModalResult={detailsModalResult}
                                detailsModalClose={()=>
                                    detailsModalClose()
                                }
                            />
                            {/* Dispatch Details Modal */}

                            {/* Set Details Modal */}

                            <Collection
                                bookingId={bookingId}
                                collectionModal={collectionModal}
                                collectionModalOpen={
                                    collectionModalOpen
                                }
                                collectionModalClose={
                                    collectionModalClose
                                }
                                collectionSuccess={collectionSuccess}
                            />
                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <Footer />
        </div>
    );
};
export default NewAdminBookingReturn;

