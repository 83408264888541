import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input} from "reactstrap";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { userActions } from "../../../../actions";

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            changePassword: {
                id: "",
                password: "",
                confirm_password: ""
            },
            error: false,
            message:false
        };

        this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
        // this.handleOnKitSelect = this.handleOnKitSelect.bind(this);
        // this.handleOnProductSearch = this.handleOnProductSearch.bind(this);
        // this.handleOnProductSelect = this.handleOnProductSelect.bind(this);
        // this.handleKitList = this.handleKitList.bind(this);
        // this.handleProductList = this.handleProductList.bind(this);
    }

    handlePassword = (name, value) => {
        const { changePassword } = this.state;
        changePassword[name] = value;
        this.setState({
            changePassword
        });
    };

    changePasswordSubmit(id) {
        this.setState({
            changePassword: {
                id: id,
                password: this.state.changePassword.password,
                confirm_password: this.state.changePassword.confirm_password
            }
        }, () => {
            this.props.dispatch(userActions.resetPassword(this.state.changePassword));
        });
    }

    // handleOnKitSearch(searchString, result) {
    //     console.log(result);
    //     this.props.dispatch(setActions.getKitsList({search: searchString}));
    // }

    // handleOnKitSelect(item) {
    //     this.setState({ 
    //         selectedKit:  item
    //     });
    // }

    // handleOnProductSearch(searchString, result) {
    //     console.log(result);
    //     this.props.dispatch(setActions.getProductsList({search: searchString}));
    // }

    // handleOnProductSelect(item) {
    //     console.log(item);
    //     this.setState({ 
    //         selectedProduct:  item
    //     });

    //     this.setState(prevState => ({
    //         selectedProductList: [ item, ...prevState.selectedProductList]
    //     }));
    // }

    // handleKitList(kitList) {
    //     this.setState({ 
    //         kitList:  kitList
    //     });
    // }

    // handleProductList(productList) {
    //     this.setState({ 
    //         productList:  productList
    //     });
    // }

    // deleteProduct(e, id) {
    //     e.preventDefault();
    //     console.log("before", this.state.selectedProductList);
    //     const newProductList = this.state.selectedProductList.filter(product => product._id !== id);
    //     this.setState({ selectedProductList: newProductList },
    //         ()=>{
    //             console.log("after", this.state.selectedProductList);
    //         });
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        // if(nextProps.kitsList) {
        //     this.handleKitList(nextProps.kitsList);
        // }
        // if(nextProps.productsList) {
        //     this.handleProductList(nextProps.productsList);
        // }

        // if(nextProps.editModal == false) {
        //     this.editModalClose();
        // }
        // if(nextProps.editModal == true) {
        //     this.setEditUser(nextProps.user.data);
        // }

        // if(nextProps.resetModal == false) {
        //     this.resetPasswordModalClose();
        // }
        // if(nextProps.resetModal == true) {
        //     this.resetPasswordModalOpen();
        // }

        // if(nextProps.deleteModal == false) {
        //     this.deleteModalClose();
        // }
        // if(nextProps.deleteModal == true) {
        //     this.deleteModalOpen();
        // }

        // // if(nextProps.editModalClose) {
        // //     this.editModalClose();
        // // }

        if(nextProps.resetModal == false) {
            this.setState({ 
                message: true,
                changePassword: {
                    id: "",
                    password: "",
                    confirm_password: ""
                }
            });
        
            setTimeout(() => {
                this.setState({ 
                    message: false 
                });
            }, 5000);
        }

        if(nextProps.error !== "")
        {
            this.setState({ 
                error: true 
            });

           
        }
    }

    render() {
        //const { kitsList, productsList } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        const { error } = this.props;
        
        return (
            <div>
                <Navbar />
                <section className="b-burger">
                    <div className="container"> 
                        {/* <h1 className="date-scroll pt-4"> <Link to="/reps/open-booking"><i className="fa fa-long-arrow-left smbtn"></i> <strong>Back</strong></Link></h1> */}
                       
                        <div className="row pt-4">
                            <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2">
                                <div className="d-card">
                                    <h4>Change Password</h4>
                                    <div className="form-group">
                                        <label>Password <strong className="text-danger">*</strong></label>
                                        <Input 
                                            type="password" 
                                            className="form-control"
                                            value={this.state.changePassword.password} 
                                            onChange = {(e) => this.handlePassword("password", e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.password ? error.password : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Confirm Password <strong className="text-danger">*</strong></label>
                                        <Input 
                                            type="password" 
                                            className="form-control"
                                            value={this.state.changePassword.confirm_password} 
                                            onChange = {(e) => this.handlePassword("confirm_password", e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.confirm_password ? error.confirm_password : ""}</span> : ""}
                                        { this.state.message==true && <span className="text-success">Password updated successfully</span> }
                                    </div>
                                </div>
                                <div className="mt-5 text-right">
                                    <Link to="/reps/open-booking" className="btn btn-secondary mb-3 mr-2">Cancel</Link>
                                    <button className="btn btn-primary mb-3" onClick={() => this.changePasswordSubmit(this.state.user.data._id)}>Save Changes</button>
                                </div>   
                            </div>
                            <div className="col-lg-4 col-md-8"></div>
                        </div>
                       
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { error, resetModal } = state.rootReducer.users;
    
    return {
        error,
        resetModal
      
    };
}

export default connect(mapStateToProps)(ChangePassword);