import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from "react-select";
import { hospitalService } from "../../../../../services";

  const Merge = ({ isOpen, toggle, item }) => {
  const [selectedHospitals, setSelectedHospitals] = useState([]);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null); 
  const [hospitalList, setHospitalList]= useState([]);

const getHospitalList=()=>{
  hospitalService.hospitalListForMerge({
              search: "",
          }).then((response)=>{
            setHospitalList(response.data);
          });
};

  const getData=()=>{
    if (hospitalList && item) {
      const hospital_options = hospitalList
        .filter((hospital) => hospital._id !== item._id)
        .map((hospital) => ({
          value: hospital._id,
          label: hospital.name,
        }));
      setOptions(hospital_options);
    }
  };
  
useEffect(()=>{
  getHospitalList();
},[]);

  useEffect(() => {
  getData();
  }, [hospitalList, item]);

  const handleMerge = () => {
      const params = {
        hospitalId: item._id,
        mergeHospitalIds: selectedHospitals.map((hospital) => hospital.value),
      };
      hospitalService
        .mergeHospitalList(params)
        .then(() => {
          window.location.reload();
          toggle();
        })
        .catch((error) => {
          setError(error);
        });
    }; 


  const handleModalClose = () => {
    setSelectedHospitals([]);
    setError(null); 
    toggle();
  };

  return (
    <div>
      <Modal size="lg" backdrop="static" isOpen={isOpen} toggle={handleModalClose}>
        <ModalHeader>Merge Hospital</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label>Select a Hospital</label>
            <Select
              value={selectedHospitals}
              onChange={(selectedOptions) => setSelectedHospitals(selectedOptions)}
              options={options}
              isMulti={true} 
              placeholder="Select Hospitals"
            />
            {error && <div className="text-danger">{error.mergeHospitalIds}</div>} {/* Render the error message */}

          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-primary" onClick={handleMerge}>
            Merge
          </button>
          <button className="btn btn-secondary" onClick={handleModalClose}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Merge;




 

  