import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Input,
    Button,

} from "reactstrap";
import Select from "react-select";
import dateFormat from "dateformat";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import {
    repsBookingActions,
    kitActions,
    setActions,
    surgeonActions,
    operationActions,
} from "../../../../actions";
import AddSurgeon from "../../Admin/Surgeon/Add";
import AddHospital from "../../Admin/Hospitals/Add/Index";
import DatePicker from "react-datepicker";
import { bookingService } from "../../../../services/booking.services";
import UpdateHospital from "../Hospitals/Update/Index";

// const operations =  [{ value: "Appendectomy", label: "Appendectomy" },
//     { value: "C-Section", label: "C-Section" },
//     { value: "Heart Bypass", label: "Heart Bypass" },
// ];

const deliverySlots = [
    { value: "Pre 9AM", label: "Pre 9AM" },
    { value: "Pre 10AM", label: "Pre 10AM" },
    { value: "Pre 12", label: "Pre 12" },
    { value: "Same Day", label: "Same Day" },
];

const operationSlots = [
    { value: "AM", label: "AM" },
    { value: "PM", label: "PM" },
    { value: "TBD", label: "TBD" },
];

const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class Add extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            kitSetErrorFlag: 0,
            bookingInfo: {
                booking_type: "loan",
                hospitalId: "",
                hospital_address: "",
                hospital_department: "",
                po_number: "",
                operation_name: "",
                shipping_charge: 100,
                additional_notes: "",
                assigned_rep: "",
                additional_reps: [],
                delivery_date: "",
                delivery_date_slot: "Pre 12",
                operation_date: "",
                operation_date_slot: "AM",
                collection_date: "",
                return_date: "",
                kitData: [],
                kitSetData: [],
                log_status: "pending",
                log_by_id: "",
                surgeonData: [],
                other_opration: "",
                created_by: "",
                po_check: false,
                pickup_location_checkbox: false,
                pickup_location_text: "",
            },
            selectedOperation: [],
            selectedHospital: [],
            selectedDeliverySlot: [{ value: "Pre 12", label: "Pre 12" }],
            selectedOperationSlots: [{ value: "AM", label: "AM" }],
            selectedRep: [],
            selectedAdditionalRep: [],
            reps: [],
            additionalReps: [],
            hospitals: [],
            hospitalDetails: [],
            repsDetails: [],
            hospitalAddress: [],
            hospitalContactPerson: "",
            hospitalContactPersonEmail: "",
            hospitalContactPersonPhone: "",
            searchHospital: "",
            searchReps: "",
            searchOps: "",
            kits: [],
            kitListFlag: 0,
            selectedKit: [],
            kitWithKitSet: [],
            kitSetDetailModal: false,
            kitDetails: [],
            kitDetailsClickFlag: 0,
            bookingType: [
                { value: "loan", label: "Loan" },
                { value: "consignment", label: "Consignment" },
            ],
            selectedBookingType: [{ value: "loan", label: "Loan" }],
            selectedSurgeon: [],
            surgeonList: [],
            operationsList: [],
            addSurgeonModal: false,
            addNewHospitalModalProp: false,
            quantityError: false,
            suggestedKits: [],
            editHospitalModal: false,
            editHospitalId: 0,
            hospitalDepartment: [],
            surgeonNoteDetails:""
        };

        this.getList = this.getList.bind(this);
        this.showHospitalsList = this.showHospitalsList.bind(this);
        this.showOperationsList = this.showOperationsList.bind(this);
        this.handleBookingInfo = this.handleBookingInfo.bind(this);
        this.handleBookingAddress = this.handleBookingAddress.bind(this);
        this.handleBookingDepartment = this.handleBookingDepartment.bind(this);
        this.setSelectedOptionOperation =
            this.setSelectedOptionOperation.bind(this);
        this.setSelectedOptionHospital =
            this.setSelectedOptionHospital.bind(this);
        this.setSelectedOptionDeliverySlot =
            this.setSelectedOptionDeliverySlot.bind(this);
        this.setSelectedOptionOperationSlots =
            this.setSelectedOptionOperationSlots.bind(this);
        this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
        this.setSelectedOptionAdditionalRep =
            this.setSelectedOptionAdditionalRep.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
        this.handleAddKit = this.handleAddKit.bind(this);
        this.handelAddRemovSet = this.handelAddRemovSet.bind(this);
        this.handleHireCharge = this.handleHireCharge.bind(this);
        this.handleKitDetails = this.handleKitDetails.bind(this);
        this.showKitDetails = this.showKitDetails.bind(this);
        this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
        this.setSelectedOptionBookingType =
            this.setSelectedOptionBookingType.bind(this);
        this.checkNextAvalability = this.checkNextAvalability.bind(this);
        this.setSelectedOptionSurgeon =
            this.setSelectedOptionSurgeon.bind(this);
        this.addSurgeonModal = this.addSurgeonModal.bind(this);
        this.addNewSurgeonModalClose = this.addNewSurgeonModalClose.bind(this);
        this.setSugestedSelectedOptionKit =
            this.setSugestedSelectedOptionKit.bind(this);

        this.editHospitalModalClose = this.editHospitalModalClose.bind(this);
        this.refreshHospitalList = this.refreshHospitalList.bind(this);
    }

    getList() {
        this.showHospitalsList();

        this.props.dispatch(
            repsBookingActions.getRepsList({ search: this.state.searchReps }),
        );
        this.props.dispatch(surgeonActions.getSurgeonsList({ search: "" }));
        this.props.dispatch(
            operationActions.getOperationsList({
                search: this.state.searchOps,
            }),
        );
        this.props.dispatch(kitActions.kitListDropdownForReps({ search: "" }));
    }

    showHospitalsList() {
        bookingService
            .getHospitalsList({ search: this.state.searchHospital })
            .then(response => {
                var hospitalsList = response.data;
                var tempHospitals = [];
                hospitalsList.map(item => {
                    var option = { value: item._id, label: item.name };
                    tempHospitals.push(option);
                });
                this.setState({
                    hospitalDetails: hospitalsList,
                    hospitals: tempHospitals,
                });
            });
    }

    setSelectedOptionSurgeon(e) {
        var setSelectedOptionSurgeon = [{ value: e.value, label: e.label }];
        this.setState({
            selectedSurgeon: setSelectedOptionSurgeon,
            bookingInfo: {
                ...this.state.bookingInfo,
                surgeonData: [e.value],
            },
            surgeonNoteDetails: e?.notes
        });
    }

    setSurgeonList(data) {
        var surgeonList = [];
        data.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
                notes:item?.notes ? item?.notes : ""
            };
            surgeonList.push(option);
        });

        this.setState({
            surgeonList: surgeonList,
        });
    }

    showOperationsList(operationsList) {
        var tempOperations = [];
        operationsList.map(item => {
            var option = { value: item.name, label: item.name };
            tempOperations.push(option);
        });
        //tempOperations.push( {value: "Other", label: "Other"});

        this.setState({
            operationsList: tempOperations,
        });
    }

    showAddressList(address) {
        var hospitalAddress = [];
        var hospitalDepartment = [];
        address.addresses.map((item, ind) => {
            if (ind == 0) {
                hospitalAddress.push({
                    _id: item.id,
                    name: item.name,
                    checked: true,
                });
            } else {
                hospitalAddress.push({
                    _id: item.id,
                    name: item.name,
                    checked: false,
                });
            }
        });

        address.departments.map((item, ind) => {
            if (ind == 0) {
                hospitalDepartment.push({
                    _id: item.id,
                    name: item.name,
                    checked: true,
                });
            } else {
                hospitalDepartment.push({
                    _id: item.id,
                    name: item.name,
                    checked: false,
                });
            }
        });

        this.setState({
            hospitalAddress: hospitalAddress,
            hospitalDepartment: hospitalDepartment,
            hospitalContactPerson: address.contact_person_name,
            hospitalContactPersonEmail: address.email,
            hospitalContactPersonPhone: address.contact_person_phone_number,
        });
    }

    showRepsList(repsList) {
        var tempRepsList = [];
        repsList.map(item => {
            var option = {
                value: item._id,
                label: item.first_name + " " + item.last_name,
            };
            tempRepsList.push(option);
        });
        this.setState({
            repsDetails: repsList,
            reps: tempRepsList,
            additionalReps: tempRepsList,
        });
    }

    setSelectedOptionOperation(e) {
        var selectedOperation = [{ value: e.value, label: e.label }];
        if (e.label != "Other") {
            this.setState({
                selectedOperation: selectedOperation,
                bookingInfo: {
                    ...this.state.bookingInfo,
                    operation_name: e.label,
                    other_opration: "",
                },
            });
        }
        if (e.label == "Other") {
            this.setState({
                bookingInfo: {
                    ...this.state.bookingInfo,
                    operation_name: e.label,
                },
            });
        }
    }

    setSelectedOptionBookingType(e) {
        var selectedBookingType = [{ value: e.value, label: e.label }];
        console.log(selectedBookingType);
        this.setState({
            selectedBookingType: selectedBookingType,
            bookingInfo: {
                ...this.state.bookingInfo,
                booking_type: e.value,
            },
        });
    }

    setSelectedOptionHospital(e) {
        var selectedHospital = [{ value: e.value, label: e.label }];
        this.setState(
            {
                selectedHospital: selectedHospital,
                bookingInfo: {
                    ...this.state.bookingInfo,
                    hospitalId: e.value,
                },
            },
            () => {
                this.getHospitalDetailsById();
            },
        );
    }

    getHospitalDetailsById() {
        bookingService
            .getHospitalDetailsById(this.state.selectedHospital[0].value)
            .then(response => {
                this.showAddressList(response.data);
            });
    }

    setSelectedOptionRep(e) {
        var selectedRep = [{ value: e.value, label: e.label }];
        this.setState({
            selectedRep: selectedRep,
            bookingInfo: {
                ...this.state.bookingInfo,
                assigned_rep: e.value,
            },
        });
    }

    setSelectedOptionAdditionalRep = selectedAdditionalRep => {
        var tempAdditionalReps = [];
        selectedAdditionalRep.map(item => {
            tempAdditionalReps.push({ id: item.value });
        });
        this.setState(
            {
                selectedAdditionalRep: selectedAdditionalRep,
                bookingInfo: {
                    ...this.state.bookingInfo,
                    additional_reps: tempAdditionalReps,
                },
            },
            () => {
                console.log(this.state.bookingInfo.additional_reps);
            },
        );
    };

    setSelectedOptionKit(e) {
        var selectedKit = [{ value: e.value, label: e.label }];
        this.setState({
            selectedKit: selectedKit,
        });
    }

    setSugestedSelectedOptionKit(e) {
        var selectedKit = [{ value: e.value, label: e.label }];
        this.setState(
            {
                selectedKit: selectedKit,
            },
            () => {
                this.handleAddKit();
            },
        );
    }

    showKitsList(kitList) {
        var tempKitList = [];
        kitList.map(item => {
            var option = { value: item._id, label: item.name };
            tempKitList.push(option);
        });
        this.setState({
            kits: tempKitList,
            kitListFlag: 1,
        });
    }

    handleAddKit() {
        if (this.state.selectedKit.length > 0) {
            this.props.dispatch(
                repsBookingActions.getKitSetByKit({
                    kitId: this.state.selectedKit[0].value,
                }),
            );
        }
    }

    showKitsSetList(data) {
        const { kitWithKitSet } = this.state;
        let newKitWithKitSet = [];
        var subKitSet = [];
        data[0]["kitsets"].map(kset => {
            subKitSet.push({
                bookingKitSets: data[0]["bookingKitSets"],
                set_name: kset.set_name,
                _id: kset._id,
                hire_charge: 695,
                added: 0,
                availability: [],
            });
        });

        newKitWithKitSet.push({
            kitDocument: data[0].kitDocument,
            name: data[0].name,
            _id: data[0]._id,
            hire_charge: 695,
            quantity: "",
            sets: subKitSet,
        });

        this.setState({
            suggestedKits: data[0].suggestedKits,
        });

        var kits = this.state.kits;
        var slectedVal = data[0]._id;
        kits = kits.filter(rs => rs.value !== slectedVal);

        let newArr = [...newKitWithKitSet, ...kitWithKitSet];

        this.setState({
            kitWithKitSet: newArr,
            selectedKit: [],
            kits: kits,
        });
    }

    handelRemovKit(kiIndex) {
        const { kitWithKitSet, kits } = this.state;
        const kitValue = kitWithKitSet[kiIndex]._id;
        const kitLable = kitWithKitSet[kiIndex].name;
        kitWithKitSet.splice(kiIndex, 1);

        kits.push({ value: kitValue, label: kitLable });

        this.setState({
            kitWithKitSet,
            kits,
        });
    }

    handelAddRemovSet(kiIndex, setIndex, flag) {
        const { kitWithKitSet } = this.state;
        kitWithKitSet[kiIndex].sets[setIndex].added = flag;

        this.setState({
            kitWithKitSet,
        });
    }

    handleHireCharge(kiIndex, setIndex, val) {
        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }
        kitWithKitSet[kiIndex].sets[setIndex].hire_charge = val;

        this.setState({
            kitWithKitSet,
        });
    }

    handleKitHireCharge(index, val) {
        console.log(index, val);
        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }

        kitWithKitSet[index].hire_charge = val;

        this.setState({
            kitWithKitSet,
        });
    }

    handleKitQuantity(index, val) {
        console.log(index, val);

        const { kitWithKitSet } = this.state;
        if (val != "" && val < 0) {
            val = "";
        }

        kitWithKitSet[index].quantity = val;

        this.setState({
            kitWithKitSet,
            quantityError: false,
        });
    }

    handleBookingInfo(name, value) {
        const { bookingInfo } = this.state;
        if (
            name == "delivery_date" ||
            name == "collection_date" ||
            name == "operation_date"
        ) {
            let date = dateFormat(value, "yyyy-mm-dd");
            bookingService
                .checkHolidayDate({ date: date })
                .then(response => {
                    console.log("checkholiday", response);
                    if (response.data && response.data.length !== 0) {
                        if (name === "delivery_date") {
                            this.setState(prevState => ({
                                deliveryDateError:
                                    "Delivery Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                            }));
                        } else if (name === "collection_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    "Collection Date falls on a holiday.",
                                operationDateError:
                                    prevState.operationDateError,
                            }));
                        } else if (name === "operation_date") {
                            this.setState(prevState => ({
                                deliveryDateError: prevState.deliveryDateError,
                                collectionDateError:
                                    prevState.collectionDateError,
                                operationDateError:
                                    "Operation Date falls on a holiday.",
                            }));
                        }
                    } else {
                        this.setState(prevState => ({
                            deliveryDateError:
                                name === "delivery_date"
                                    ? ""
                                    : prevState.deliveryDateError,
                            operationDateError:
                                name === "operation_date"
                                    ? ""
                                    : prevState.operationDateError,
                            collectionDateError:
                                name === "collection_date"
                                    ? ""
                                    : prevState.collectionDateError,
                        }));
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        }

        if (name == "shipping_charge" && value != "" && value < 0) {
            value = "";
        }
        bookingInfo[name] = value;

        if (name == "po_check" && value == true) {
            bookingInfo["po_number"] = "";
        }
        if (name == "po_number" && value == "") {
            bookingInfo["po_check"] = true;
        }
        else if (name == "po_number" && value != "") {
            bookingInfo["po_check"] = false;
        }


        this.setState(
            {
                bookingInfo,
            },
            () => {
                console.log(this.state.bookingInfo);
            },
        );
    }

    handleBookingAddress(value, checked, ind) {
        const { hospitalAddress } = this.state;
        hospitalAddress.map(item => {
            item.checked = false;
        });
        hospitalAddress[ind].checked = true;
        this.setState({
            hospitalAddress,
        });
    }

    handleBookingDepartment(value, checked, ind) {
        const { hospitalDepartment } = this.state;
        hospitalDepartment.map(item => {
            item.checked = false;
        });
        hospitalDepartment[ind].checked = true;
        this.setState({
            hospitalDepartment,
        });
    }

    setSelectedOptionDeliverySlot(e) {
        var selectedDeliverySlot = [{ value: e.value, label: e.label }];
        this.setState({
            selectedDeliverySlot: selectedDeliverySlot,
            bookingInfo: {
                ...this.state.bookingInfo,
                delivery_date_slot: e.value,
            },
        });
    }

    setSelectedOptionOperationSlots(e) {
        var selectedOperationSlots = [{ value: e.value, label: e.label }];
        this.setState({
            selectedOperationSlots: selectedOperationSlots,
            bookingInfo: {
                ...this.state.bookingInfo,
                operation_date_slot: e.value,
            },
        });
    }

    handleSubmit() {
        const {
            bookingInfo,
            hospitalAddress,
            hospitalDepartment,
            kitWithKitSet,
        } = this.state;
        var kitError = 0;
        var kitData = [];
        var kitSetData = [];
        let kitSetFlag = 0;
        var kitSetErrorFlag = 0;
        this.setState({ quantityError: true });

        hospitalAddress
            .filter(rs => rs.checked === true)
            .map(item => {
                bookingInfo["hospital_address"] = item.name;
            });

        hospitalDepartment
            .filter(rs => rs.checked === true)
            .map(item => {
                bookingInfo["hospital_department"] = item.name;
            });

        kitWithKitSet.map(item => {
            kitData.push({
                id: item._id,
                hire_charge: item.hire_charge,
                quantity: item.quantity,
            });
            kitSetFlag = 1;
            kitError = 1;
            item.sets
                .filter(rs => rs.added === 1)
                .map(sub => {
                    kitSetData.push({
                        id: sub._id,
                        hire_charge: sub.hire_charge,
                        kitId: item._id,
                    });
                });
        });

        bookingInfo["assigned_rep"] = this.state.user.data._id;

        bookingInfo["return_date"] = "";

        if (bookingInfo["collection_date"] != "") {
            var return_date = new Date(bookingInfo["collection_date"]);
            return_date = return_date.setDate(return_date.getDate() + 1);
            bookingInfo["return_date"] = dateFormat(return_date, "yyyy-mm-dd");
        }
        if (bookingInfo["booking_type"] == "consignment") {
            bookingInfo["operation_date"] = bookingInfo["collection_date"];
        }

        bookingInfo["kitData"] = kitData;
        bookingInfo["kitSetData"] = kitSetData;

        console.log(kitSetFlag);

        if (
            bookingInfo["hospitalId"] != "" &&
            bookingInfo["po_number"] != "" &&
            bookingInfo["delivery_date"] != "" &&
            bookingInfo["operation_date"] != "" &&
            bookingInfo["collection_date"] != "" &&
            bookingInfo["kitData"].length != 0 &&
            bookingInfo["kitSetData"].length != 0
        ) {
            kitSetErrorFlag = 1;
            this.setState({
                kitSetErrorFlag: 0,
            });
        } else {
            kitSetErrorFlag = 0;
            if (kitError == 1) {
                kitSetErrorFlag = 1;
                this.setState({
                    kitSetErrorFlag: 0,
                });
            } else {
                this.setState({
                    kitSetErrorFlag: 1,
                });
            }
        }

        bookingInfo["log_by_id"] = this.state.user.data._id;

        console.log("bookingInfo", bookingInfo);
        if (kitSetErrorFlag == 1) {
            var datass = {
                additional_notes: bookingInfo["additional_notes"],
                additional_reps: bookingInfo["additional_reps"],
                assigned_rep: bookingInfo["assigned_rep"],
                booking_type: bookingInfo["booking_type"],
                collection_date: "",
                delivery_date: "",
                delivery_date_slot: bookingInfo["delivery_date_slot"],
                hospitalId: bookingInfo["hospitalId"],
                hospital_address: bookingInfo["hospital_address"],
                hospital_department: bookingInfo["hospital_department"],
                kitData: bookingInfo["kitData"],
                kitSetData: bookingInfo["kitSetData"],
                log_by_id: bookingInfo["log_by_id"],
                log_status: bookingInfo["log_status"],
                operation_date: "",
                operation_date_slot: bookingInfo["operation_date_slot"],
                operation_name: bookingInfo["operation_name"],
                other_opration: bookingInfo["other_opration"],
                po_number: bookingInfo["po_number"],
                return_date: bookingInfo["return_date"],
                shipping_charge: bookingInfo["shipping_charge"],
                surgeonData: bookingInfo["surgeonData"],
                created_by: this.state.user.data._id,
                po_check: bookingInfo["po_check"],
                pickup_location_checkbox: bookingInfo["pickup_location_checkbox"],
                pickup_location_text: bookingInfo["pickup_location_text"],
            };

            if (bookingInfo["collection_date"] != "") {
                datass["collection_date"] = dateFormat(
                    bookingInfo["collection_date"],
                    "yyyy-mm-dd",
                );
            }
            if (bookingInfo["delivery_date"] != "") {
                datass["delivery_date"] = dateFormat(
                    bookingInfo["delivery_date"],
                    "yyyy-mm-dd",
                );
            }
            if (bookingInfo["operation_date"] != "") {
                datass["operation_date"] = dateFormat(
                    bookingInfo["operation_date"],
                    "yyyy-mm-dd",
                );
            }
            this.props.dispatch(repsBookingActions.add(datass));
        }
    }

    kitSetDetailModalClose() {
        this.setState({
            kitSetDetailModal: false,
            kitSetData: [],
        });
    }

    editHospitalModalOpen(id) {
        this.setState({
            editHospitalId: id,
            editHospitalModal: !this.state.editModal,
        });
    }

    editHospitalModalClose() {
        this.setState({
            editHospitalModal: false,
            editHospitalId: 0,
        });
    }

    refreshHospitalList() {
        if (this.state.selectedHospital.length > 0) {
            this.showHospitalsList();
            this.getHospitalDetailsById();
        }
    }

    showKitDetails(kitDetails) {
        if (this.state.kitDetailsClickFlag == 1) {
            this.setState({
                kitDetails: kitDetails,
                kitSetDetailModal: true,
                kitDetailsClickFlag: 0,
            });
        }
    }

    handleKitDetails(id) {
        this.setState({
            kitDetailsClickFlag: 1,
        });

        this.props.dispatch(setActions.getById(id));
    }

    checkAvalability(bookingKitSets, setId, index, ind) {
        const { bookingInfo } = this.state;
        console.log(setId, index, ind);
        var htm = [];

        var currentDate = bookingInfo.delivery_date;
        var endDate = bookingInfo.collection_date;
        var falgForHtm = 0;
        var date = "";
        var separator = "-";
        var fulldate = "";
        var nextDate = [];
        var holiday = "No";
        if (currentDate != "" && endDate != "") {
            currentDate = new Date(currentDate);
            endDate = new Date(endDate);
            var dates = [];
            while (currentDate <= endDate) {
                date = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                        ? `0${currentDate.getMonth() + 1}`
                        : `${currentDate.getMonth() + 1}`
                    }${separator}${currentDate.getDate() < 10
                        ? `0${currentDate.getDate()}`
                        : `${currentDate.getDate()}`
                    }`;

                dates.push({ fulldate: date });
                currentDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate() + 1, // Will increase month if over range
                );
            }

            if (bookingKitSets.length == 0) {
                htm = <span className="capsule green-dim">Available</span>;
                endDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate() + 7,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    nextDate.push({
                        date:
                            currentDate.getDate() +
                            " " +
                            dayName[currentDate.getDay()],
                        holiday: holiday,
                        status: "",
                    });

                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            } else {
                dates &&
                    dates.map(diffdate => {
                        fulldate = diffdate.fulldate;
                        bookingKitSets
                            .filter(rs => rs.setId == setId)
                            .map(bookingSt => {
                                if (
                                    bookingSt.bookings &&
                                    bookingSt.bookings.filter(
                                        rs =>
                                            rs.delivery_date <= fulldate &&
                                            rs.collection_date >= fulldate,
                                    ).length
                                ) {
                                    falgForHtm += 1;
                                }
                            });
                    });

                if (falgForHtm > 0) {
                    htm = (
                        <span className="capsule red-dim">Not Available</span>
                    );
                }

                if (falgForHtm == 0) {
                    htm = <span className="capsule green-dim">Available</span>;
                }

                endDate = new Date(
                    endDate.getFullYear(),
                    endDate.getMonth(),
                    endDate.getDate() + 7,
                );

                while (currentDate <= endDate) {
                    holiday = "No";
                    falgForHtm = 0;
                    if (
                        currentDate.getDay() == 6 ||
                        currentDate.getDay() == 0
                    ) {
                        holiday = "holiday";
                    }

                    fulldate = `${currentDate.getFullYear()}${separator}${currentDate.getMonth() + 1 < 10
                            ? `0${currentDate.getMonth() + 1}`
                            : `${currentDate.getMonth() + 1}`
                        }${separator}${currentDate.getDate() < 10
                            ? `0${currentDate.getDate()}`
                            : `${currentDate.getDate()}`
                        }`;

                    bookingKitSets
                        .filter(rs => rs.setId == setId)
                        .map(bookingSt => {
                            if (
                                bookingSt.bookings &&
                                bookingSt.bookings.filter(
                                    rs =>
                                        rs.delivery_date <= fulldate &&
                                        rs.collection_date >= fulldate,
                                ).length
                            ) {
                                falgForHtm += 1;
                            }
                        });

                    if (falgForHtm > 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: "na",
                            status: " Not Available",
                        });
                    }

                    if (falgForHtm == 0) {
                        nextDate.push({
                            date:
                                currentDate.getDate() +
                                " " +
                                dayName[currentDate.getDay()],
                            holiday: holiday,
                            status: "Available",
                        });
                    }
                    currentDate = new Date(
                        currentDate.getFullYear(),
                        currentDate.getMonth(),
                        currentDate.getDate() + 1, // Will increase month if over range
                    );
                }
            }
        } else {
            htm = <span className="capsule green-dim">Available</span>;
        }

        return (
            <div>
                {htm}
                <div key="2">
                    <div
                        className="table-chart"
                        style={{ width: "auto", display: "inline-block" }}>
                        <table className="mt-2 main-table">
                            <thead>
                                <tr style={{ borderTop: "1px solid #DDD" }}>
                                    {nextDate &&
                                        nextDate.map((next, indxx) => (
                                            <th
                                                key={indxx}
                                                in={indxx}
                                                className={
                                                    (indxx == 0
                                                        ? " first_col "
                                                        : " ") + next.holiday
                                                }
                                                style={{ width: "30px" }}>
                                                {next.date}
                                            </th>
                                        ))}
                                    {/*<th className="first_col" style={{width:"30px"}}>24 Tue</th>
                                    <th>25 Tue</th>
                                    <th className="holiday">26 Tue</th>
                                    <th className="tdy">27 Tue</th>
                                    <th>28 Tue</th>
                                    <th>29 Tue</th>
                                    <th>30 Tue</th>*/}
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    checkNextAvalability() {
        var test = [];
        test.push({ date: 5 });

        return test;
    }

    addSurgeonModal() {
        this.setState({
            addSurgeonModal: true,
        });
    }

    addNewSurgeonModalClose() {
        this.setState({ addSurgeonModal: false });
        this.props.dispatch(surgeonActions.getSurgeonsList({ search: "" }));
    }

    addHospitalModal() {
        this.setState({
            addNewHospitalModalProp: true,
        });
    }

    addNewHospitalModalClose() {
        this.setState({ addNewHospitalModalProp: false });
        this.showHospitalsList();
    }

    componentDidMount() {
        this.getList();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("boss", nextProps);

        if (nextProps.repsList !== undefined) {
            this.showRepsList(nextProps.repsList);
        }

        if (
            nextProps.kitListDropdown !== undefined &&
            this.state.kitListFlag == 0
        ) {
            this.showKitsList(nextProps.kitListDropdown);
        }

        if (nextProps.kitSetList !== undefined) {
            this.showKitsSetList(nextProps.kitSetList);
        }

        if (nextProps.editSet == true) {
            this.showKitDetails(nextProps.set);
        }

        if (nextProps.surgeonList != undefined) {
            this.setSurgeonList(nextProps.surgeonList);
        }

        if (nextProps.opLists !== undefined) {
            this.showOperationsList(nextProps.opLists);
        }
    }

    render() {
        const { redirect } = this.props;

        if (redirect && this.state.bookingInfo.booking_type != "consignment") {
            return <Redirect to="/reps/open-booking" />;
        }
        if (redirect && this.state.bookingInfo.booking_type == "consignment") {
            return <Redirect to="/reps/consignment-booking" />;
        }
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if (this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        const { error } = this.props;
        const { surgeonNoteDetails } = this.state;
        return (
            <div>
                <Navbar activePage="bookings" />
                <div className="container">
                    <h1 className="date-scroll pt-4">
                        <Link to="/reps/open-booking">
                            <i className="fa fa-long-arrow-left smbtn"></i>{" "}
                            <strong>Back</strong>
                        </Link>
                    </h1>
                    <div className="row pt-4 mobile_nocard">
                        <div className="col-md-8">
                            <div className="d-card">
                                <h4>Basic Details</h4>
                                <div className="form-group">
                                    <label>
                                        Hospital Name{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <span className="pull-right">
                                        <a
                                            href="javascript:;"
                                            className="btn-link"
                                            onClick={() =>
                                                this.addHospitalModal()
                                            }>
                                            + Add New Hospital
                                        </a>
                                    </span>

                                    <div
                                        className=" info position_up"
                                        style={{ zIndex: 8 }}>
                                        <Select
                                            defaultValue={
                                                this.state.selectedHospital
                                            }
                                            onChange={
                                                this.setSelectedOptionHospital
                                            }
                                            options={this.state.hospitals}
                                            maxMenuHeight={190}
                                        />
                                        {error ? (
                                            <span className="text-danger">
                                                {error.hospitalId
                                                    ? error.hospitalId
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                {this.state.selectedHospital.length > 0 ? (
                                    <div className="row position-relative">
                                        <div className="edit_fld">
                                            <div className="">
                                                {" "}
                                                <i
                                                    onClick={() =>
                                                        this.editHospitalModalOpen(
                                                            this.state
                                                                .selectedHospital[0]
                                                                .value,
                                                        )
                                                    }
                                                    className="fa fa-pencil"></i>
                                            </div>
                                        </div>
                                        <div className="info col-md-6">
                                            <h6>Address</h6>
                                            <hr />
                                            {this.state.hospitalAddress.map(
                                                (item, ind) => (
                                                    <div
                                                        className="info-left"
                                                        style={{
                                                            marginLeft:
                                                                "1.25rem",
                                                        }}
                                                        key={item._id}>
                                                        <Input
                                                            type="radio"
                                                            id={item._id}
                                                            name="hospitalAddress"
                                                            value={item.name}
                                                            checked={
                                                                item.checked ==
                                                                true
                                                            }
                                                            onChange={e =>
                                                                this.handleBookingAddress(
                                                                    e.target
                                                                        .value,
                                                                    e.target
                                                                        .checked,
                                                                    ind,
                                                                    item,
                                                                )
                                                            }
                                                        />
                                                        <strong>
                                                            {" "}
                                                            {
                                                                this.state
                                                                    .hospitalContactPerson
                                                            }
                                                        </strong>
                                                        <div>
                                                            <small>
                                                                Location:{" "}
                                                                {item.name}
                                                            </small>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                Email:{" "}
                                                                {
                                                                    this.state
                                                                        .hospitalContactPersonEmail
                                                                }
                                                            </small>
                                                        </div>
                                                        <div>
                                                            <small>
                                                                Phone:{" "}
                                                                {
                                                                    this.state
                                                                        .hospitalContactPersonPhone
                                                                }
                                                            </small>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                        <div className="info col-md-6">
                                            <h6>Department</h6>
                                            <hr />
                                            {this.state.hospitalDepartment.map(
                                                (item, ind) => (
                                                    <div
                                                        className="info-left"
                                                        style={{
                                                            marginLeft:
                                                                "1.25rem",
                                                        }}
                                                        key={item._id}>
                                                        <Input
                                                            type="radio"
                                                            id={item._id}
                                                            name="hospitalDepartment"
                                                            value={
                                                                item.name
                                                            }
                                                            checked={
                                                                item.checked ==
                                                                true
                                                            }
                                                            onChange={e =>
                                                                this.handleBookingDepartment(
                                                                    e.target
                                                                        .value,
                                                                    e.target
                                                                        .checked,
                                                                    ind,
                                                                    item,
                                                                )
                                                            }
                                                        />
                                                        <div>
                                                            <small>
                                                                {item.name}
                                                            </small>
                                                        </div>
                                                    </div>
                                                ),
                                            )}
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="form-group mt-3">
                                                <span className="">
                                                    <Input
                                                        type="checkbox"
                                                        className="ml-0 form-check-input"
                                                        value={
                                                            this.state.bookingInfo.pickup_location_checkbox
                                                        }
                                                        checked={
                                                            this.state.bookingInfo.pickup_location_checkbox
                                                        }
                                                        onChange={e =>
                                                            this.handleBookingInfo(
                                                                "pickup_location_checkbox",
                                                                e.target.checked,
                                                            )
                                                        }
                                                    />
                                                </span>
                                                <label className="ml-4 ">
                                                    Is Pickup Location And Contact Details Different
                                                </label>
                                                {this.state.bookingInfo.pickup_location_checkbox ?
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={e =>
                                                            this.handleBookingInfo(
                                                                "pickup_location_text",
                                                                e.target.value,
                                                            )
                                                        }
                                                    />
                                                    : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="form-group">
                                    <label>
                                        Surgeon Name{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <span className="pull-right">
                                        <a
                                            href="javascript:;"
                                            className="btn-link"
                                            onClick={() =>
                                                this.addSurgeonModal()
                                            }>
                                            + Add New Surgeon
                                        </a>
                                    </span>

                                    <div
                                        className="info position_up"
                                        style={{ zIndex: 7 }}>
                                        <Select
                                            defaultValue={
                                                this.state.selectedSurgeon
                                            }
                                            onChange={
                                                this.setSelectedOptionSurgeon
                                            }
                                            options={this.state.surgeonList}
                                            maxMenuHeight={190}
                                        />
                                        {error ? (
                                            <span className="text-danger">
                                                {error.surgeonData
                                                    ? error.surgeonData
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                        {surgeonNoteDetails != "" ? (
                                            <div className="info-left" style={{marginTop: "0.75rem"}}>
                                            <div><strong>Note : </strong> <small>{surgeonNoteDetails}</small></div>
                                        </div>
                                        ):(
                                            ""
                                        )} 
                                </div>

                                <div className="form-group">
                                    <label>
                                        Operation Name{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <div
                                        className=" info position_up"
                                        style={{ zIndex: 4 }}>
                                        <Select
                                            defaultValue={
                                                this.state.selectedOperation
                                            }
                                            onChange={
                                                this.setSelectedOptionOperation
                                            }
                                            options={this.state.operationsList}
                                            maxMenuHeight={190}
                                        />
                                        {error ? (
                                            <span className="text-danger">
                                                {error.operation_name
                                                    ? error.operation_name
                                                    : ""}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                {this.state.bookingInfo.operation_name ==
                                    "Other" && (
                                        <div className="form-group">
                                            <label>
                                                Other Operation{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                value={
                                                    this.state.bookingInfo
                                                        .other_opration
                                                }
                                                onChange={e =>
                                                    this.handleBookingInfo(
                                                        "other_opration",
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            {error ? (
                                                <span className="text-danger">
                                                    {error.other_opration
                                                        ? error.other_opration
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    )}

                                <div className="form-group">
                                    <label>PO Number </label>

                                    <Input
                                        type="text"
                                        className="form-control"

                                        value={this.state.bookingInfo.po_number}
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "po_number",
                                                e.target.value,
                                            )
                                        }
                                    />

                                    {error ? (
                                        <span className="text-danger">
                                            {error.po_number
                                                ? error.po_number
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="form-group">
                                    <span className="mr-1">
                                        <Input
                                            type="checkbox"
                                            className="ml-0"
                                            value={
                                                this.state.bookingInfo.po_check
                                            }
                                            checked={
                                                this.state.bookingInfo.po_check
                                            }
                                            onChange={e =>
                                                this.handleBookingInfo(
                                                    "po_check",
                                                    e.target.checked,
                                                )
                                            }
                                        />
                                    </span>
                                    <label className="ml-4">
                                        Awaiting PO
                                    </label>
                                </div>
                            </div>
                            <div className="d-card my-4">
                                <h4>Purchase/Hire Details</h4>
                                <div className="row purchase_date">
                                    <div
                                        className={
                                            this.state.bookingInfo
                                                .booking_type != "consignment"
                                                ? "col-md-6"
                                                : "col-md-12"
                                        }>
                                        <div className="form-group">
                                            <label>
                                                Delivery Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="inputdata">
                                                <DatePicker
                                                    selected={
                                                        this.state.bookingInfo
                                                            .delivery_date
                                                    }
                                                    onChange={date =>
                                                        this.handleBookingInfo(
                                                            "delivery_date",
                                                            date,
                                                        )
                                                    }
                                                    dateFormat="dd-MM-yyyy EEE"
                                                    className="small"
                                                    id="datepicker"
                                                    autoComplete="off"
                                                />
                                            </div>
                                            {this.state.deliveryDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .deliveryDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <span className="text-danger">
                                                    {error.delivery_date
                                                        ? error.delivery_date
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.bookingInfo
                                                .delivery_date != "" &&
                                                (new Date(
                                                    this.state.bookingInfo.delivery_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.bookingInfo.delivery_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}
                                            {/* {this.state.bookingInfo.delivery_date!="" &&  ((new Date(this.state.bookingInfo.delivery_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.delivery_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.delivery_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The dispatch date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label></label>
                                            <div className="mt-2 position_up">
                                                <Select
                                                    defaultValue={
                                                        this.state
                                                            .selectedDeliverySlot
                                                    }
                                                    onChange={
                                                        this
                                                            .setSelectedOptionDeliverySlot
                                                    }
                                                    options={deliverySlots}
                                                    className="small"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.bookingInfo.booking_type !=
                                        "consignment" && (
                                            <React.Fragment>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Operation Date{" "}
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="inputdata">
                                                            <DatePicker
                                                                selected={
                                                                    this.state
                                                                        .bookingInfo
                                                                        .operation_date
                                                                }
                                                                onChange={date =>
                                                                    this.handleBookingInfo(
                                                                        "operation_date",
                                                                        date,
                                                                    )
                                                                }
                                                                dateFormat="dd-MM-yyyy EEE"
                                                                id="datepicker2"
                                                                className="small"
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                        {this.state
                                                            .operationDateError && (
                                                                <div className="error-message">
                                                                    <span className="text-danger">
                                                                        {
                                                                            this.state
                                                                                .operationDateError
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                        {/* {this.state.bookingInfo.operation_date!="" &&  ((new Date(this.state.bookingInfo.operation_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.operation_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.operation_date).getFullYear() < new Date().getFullYear())) && 
                                                    <div><span className="text-danger">The operation date you booked is in the past</span></div>
                                                } */}
                                                        {/* {this.state.bookingInfo.operation_date != "" && (new Date(this.state.bookingInfo.operation_date).getDay() == 6 || new Date(this.state.bookingInfo.operation_date).getDay() == 0) &&
                                                        <div><span className="text-danger mt-2">Selected date is a weekend/holiday but you can still make a booking</span></div>
                                                    } */}
                                                        {error ? (
                                                            <span className="text-danger">
                                                                {error.operation_date
                                                                    ? error.operation_date
                                                                    : ""}
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label></label>
                                                        <div className="mt-2">
                                                            <Select
                                                                defaultValue={
                                                                    this.state
                                                                        .selectedOperationSlots
                                                                }
                                                                onChange={
                                                                    this
                                                                        .setSelectedOptionOperationSlots
                                                                }
                                                                options={
                                                                    operationSlots
                                                                }
                                                                className="small"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>
                                                Collection Date{" "}
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            {/* <Input 
                                                type="date" 
                                                value={this.state.bookingInfo.collection_date}
                                                onChange = {(e) => this.handleBookingInfo("collection_date", e.target.value)}
                                                className="small"
                                            /> */}
                                            <DatePicker
                                                selected={
                                                    this.state.bookingInfo
                                                        .collection_date
                                                }
                                                onChange={date =>
                                                    this.handleBookingInfo(
                                                        "collection_date",
                                                        date,
                                                    )
                                                }
                                                dateFormat="dd-MM-yyyy EEE"
                                                id="datepicker3"
                                                className="small"
                                                autoComplete="off"
                                            />
                                            {this.state.collectionDateError && (
                                                <div className="error-message">
                                                    <span className="text-danger">
                                                        {
                                                            this.state
                                                                .collectionDateError
                                                        }
                                                    </span>
                                                </div>
                                            )}
                                            {error ? (
                                                <span className="text-danger">
                                                    {error.collection_date
                                                        ? error.collection_date
                                                        : ""}
                                                </span>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.bookingInfo
                                                .collection_date != "" &&
                                                (new Date(
                                                    this.state.bookingInfo.collection_date,
                                                ).getDay() == 6 ||
                                                    new Date(
                                                        this.state.bookingInfo.collection_date,
                                                    ).getDay() == 0) && (
                                                    <div>
                                                        <span className="text-danger mt-2">
                                                            Selected date is a
                                                            weekend/holiday but
                                                            you can still make a
                                                            booking
                                                        </span>
                                                    </div>
                                                )}

                                            {/* {this.state.bookingInfo.collection_date!="" &&  (new Date(this.state.bookingInfo.collection_date).getDay() == 5 || new Date(this.state.bookingInfo.collection_date).getDay() == 6)&& 
                                                <div><span className="text-danger mt-2"> Return Date is a non-working day</span></div>
                                            } */}

                                            {/* {this.state.bookingInfo.collection_date!="" &&  ((new Date(this.state.bookingInfo.collection_date).getDate() < new Date().getDate()) || ((new Date(this.state.bookingInfo.collection_date).getMonth()+1) < (new Date().getMonth()+1)) || (new Date(this.state.bookingInfo.collection_date).getFullYear() < new Date().getFullYear())) && 
                                                <div><span className="text-danger">The collection date you booked is in the past</span></div>
                                            } */}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 col-12">
                                        <label>
                                            Select Kit{" "}
                                            <span className="text-danger">
                                                *
                                            </span>
                                        </label>
                                    </div>
                                    <div className="col-sm-9 col-8">
                                        <div className="form-group mb-0">
                                            <Select
                                                value={this.state.selectedKit}
                                                onChange={
                                                    this.setSelectedOptionKit
                                                }
                                                options={this.state.kits}
                                                className="small"
                                            />
                                        </div>
                                        {this.state.kitSetErrorFlag == 1 ? (
                                            <span className="text-danger">
                                                Please add at least one kit set
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-sm-3 col-4">
                                        <Button
                                            className="btn  btn-block addnow1"
                                            onClick={this.handleAddKit}>
                                            <i className="fa fa-plus"></i>{" "}
                                            <span className="d-none d-md-block">
                                                Add
                                            </span>
                                        </Button>
                                    </div>
                                </div>
                                <div className="mt-4">
                                    <table className="table mb-0 mobile_full1">
                                        <tbody>
                                            {this.state.suggestedKits.length >
                                                0 && (
                                                    <React.Fragment>
                                                        <tr>
                                                            <th
                                                                colSpan="3"
                                                                className="w-100 border-top-0">
                                                                <div className="mb-2">
                                                                    <strong>
                                                                        Suggested
                                                                        Kits
                                                                    </strong>
                                                                </div>
                                                                {this.state.suggestedKits.map(
                                                                    (
                                                                        sugKit,
                                                                        sugInd,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                sugInd
                                                                            }>
                                                                            {this.state.kitWithKitSet.filter(
                                                                                filterKit =>
                                                                                    filterKit._id ==
                                                                                    sugKit
                                                                                        .suggestedKitDetail[0]
                                                                                        ?._id,
                                                                            )
                                                                                .length ==
                                                                                0 ? (
                                                                                <a
                                                                                    className="mr-3 my-2 btn btn-small btn-default"
                                                                                    href="javascript:;"
                                                                                    onClick={() =>
                                                                                        this.setSugestedSelectedOptionKit(
                                                                                            {
                                                                                                value: sugKit
                                                                                                    .suggestedKitDetail[0]
                                                                                                    ?._id,
                                                                                                label: sugKit
                                                                                                    .suggestedKitDetail[0]
                                                                                                    ?.name,
                                                                                            },
                                                                                        )
                                                                                    }>
                                                                                    {
                                                                                        sugKit
                                                                                            .suggestedKitDetail[0]
                                                                                            ?.name
                                                                                    }{" "}
                                                                                    <i className="fa fa-plus ml-2"></i>{" "}
                                                                                </a>
                                                                            ) : (
                                                                                <a
                                                                                    disabled
                                                                                    className="mr-3 my-2 btn btn-small btn-default"
                                                                                    href="javascript:;">
                                                                                    {
                                                                                        sugKit
                                                                                            .suggestedKitDetail[0]
                                                                                            ?.name
                                                                                    }{" "}
                                                                                    <i className="fa fa-plus ml-2"></i>{" "}
                                                                                </a>
                                                                            )}
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                            </th>
                                                        </tr>
                                                    </React.Fragment>
                                                )}
                                            {this.state.kitWithKitSet &&
                                                this.state.kitWithKitSet.map(
                                                    (kit, index) => (
                                                        <React.Fragment
                                                            key={index}>
                                                            <tr>
                                                                <th>
                                                                    <span className="mt-4">
                                                                        {
                                                                            kit.name
                                                                        }
                                                                        {kit
                                                                            .kitDocument
                                                                            .length >
                                                                            0 && (
                                                                                <span className="ml-1">
                                                                                    <a
                                                                                        id={
                                                                                            "UncontrolledTooltipPdf1" +
                                                                                            index
                                                                                        }
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="download-info badge1 badge-info1"
                                                                                        href={
                                                                                            process
                                                                                                .env
                                                                                                .REACT_APP_APIURL +
                                                                                            "assets/uploads/kitDocuments/" +
                                                                                            kit
                                                                                                .kitDocument[0]
                                                                                                .name
                                                                                        }>
                                                                                        Download Tray List
                                                                                    </a>

                                                                                </span>
                                                                            )}
                                                                    </span>
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        "vertical-align":
                                                                            "bottom",
                                                                        "padding-bottom":
                                                                            "5%",
                                                                    }}>
                                                                    <label className="small">
                                                                        Quantity{" "}
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <Input
                                                                        type="number"
                                                                        min="0"
                                                                        className="form-control"
                                                                        value={
                                                                            kit.quantity
                                                                        }
                                                                        onChange={e =>
                                                                            this.handleKitQuantity(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                        name=""
                                                                        style={{
                                                                            width: "120px",
                                                                        }}
                                                                    />
                                                                    {kit.quantity ==
                                                                        "" &&
                                                                        error &&
                                                                        this.state
                                                                            .quantityError ? (
                                                                        <span
                                                                            className="text-danger"
                                                                            style={{
                                                                                position:
                                                                                    "absolute",
                                                                            }}>
                                                                            {error.kitData
                                                                                ? error.kitData
                                                                                : ""}
                                                                        </span>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        "vertical-align":
                                                                            "bottom",
                                                                        "padding-bottom":
                                                                            "5%",
                                                                    }}>
                                                                    <label className="small">
                                                                        Hire
                                                                        Charges
                                                                        (Per
                                                                        Set)
                                                                    </label>
                                                                    <Input
                                                                        type="number"
                                                                        min="0"
                                                                        className="form-control"
                                                                        value={
                                                                            kit.hire_charge
                                                                        }
                                                                        onChange={e =>
                                                                            this.handleKitHireCharge(
                                                                                index,
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            )
                                                                        }
                                                                        name=""
                                                                        placeholder="£"
                                                                        style={{
                                                                            width: "120px",
                                                                        }}
                                                                    />
                                                                </td>

                                                                <td className="text-right">
                                                                    <div className="trash">
                                                                        <i
                                                                            onClick={() =>
                                                                                this.handelRemovKit(
                                                                                    index,
                                                                                )
                                                                            }
                                                                            className="fa fa-trash mt-4"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ),
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-card">
                                <h4>Charges</h4>
                                <div className="form-group">
                                    <label>
                                        Shipping Charge{" "}
                                        <span className="text-danger">*</span>
                                    </label>
                                    <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        value={
                                            this.state.bookingInfo
                                                .shipping_charge
                                        }
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "shipping_charge",
                                                e.target.value,
                                            )
                                        }
                                    />
                                    {error ? (
                                        <span className="text-danger">
                                            {error.shipping_charge
                                                ? error.shipping_charge
                                                : ""}
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>

                            <div className="d-card mt-4">
                                <h4>Booked By</h4>
                                <div className="form-group">
                                    <label>Additional Reps</label>
                                    <Select
                                        defaultValue={
                                            this.state.selectedAdditionalRep
                                        }
                                        onChange={
                                            this.setSelectedOptionAdditionalRep
                                        }
                                        options={this.state.additionalReps}
                                        isMulti={true}
                                    />
                                </div>
                            </div>

                            <div className="d-card mt-4">
                                <h4>Additional Notes</h4>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={
                                            this.state.bookingInfo
                                                .additional_notes
                                        }
                                        onChange={e =>
                                            this.handleBookingInfo(
                                                "additional_notes",
                                                e.target.value,
                                            )
                                        }></textarea>
                                </div>
                            </div>

                            <div className="mt-5 text-right">
                                <Link
                                    to="/reps/open-booking"
                                    className="btn btn-secondary mb-3 mr-2">
                                    Cancel
                                </Link>
                                <a
                                    href="javascript:;"
                                    onClick={this.handleSubmit}
                                    className="btn mb-3 btn-primary">
                                    Save Changes
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* ADD FORM */}
                </div>

                <Modal
                    size="lg"
                    backdrop="static"
                    isOpen={this.state.kitSetDetailModal}
                    toggle={() => this.kitSetDetailModalClose()}>
                    <ModalHeader
                        className=""
                        toggle={() => this.kitSetDetailModalClose()}>
                        Kit Details
                    </ModalHeader>
                    <ModalBody>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.kitDetails.setProductModules &&
                                    this.state.kitDetails.setProductModules.map(
                                        (product, ind) => (
                                            <tr key={ind}>
                                                <td>
                                                    {product.productID.code}
                                                </td>
                                                <td>
                                                    {product.productID.name}
                                                </td>
                                                <td>{product.quantity}</td>
                                            </tr>
                                        ),
                                    )}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                className="btn btn-secondary"
                                onClick={() => this.kitSetDetailModalClose()}>
                                Close
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>

                {/* Set Details Modal */}

                <AddHospital
                    addNewModalClose={this.addNewHospitalModalClose.bind(this)}
                    addNewModal={this.state.addNewHospitalModalProp}
                />

                {/* Edit Modal */}
                <UpdateHospital
                    editModalClose={this.editHospitalModalClose.bind(this)}
                    editModal={this.state.editHospitalModal}
                    editId={this.state.editHospitalId}
                    refreshGetList={this.refreshHospitalList.bind(this)}
                />

                <AddSurgeon
                    addNewModalClose={this.addNewSurgeonModalClose.bind(this)}
                    addNewModalProp={this.state.addSurgeonModal}
                />

                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {
        hospitalsList,
        repsList,
        error,
        kitSetList,
        redirect,
        hospitalDetails,
    } = state.rootReducer.repsBookings;
    const { kitListDropdown } = state.rootReducer.kits;
    const { list } = state.rootReducer.hospitals;
    const { editSet, set } = state.rootReducer.sets;
    const surgeonList = state.rootReducer.surgeons.allLists;
    const { opLists } = state.rootReducer.operations;

    return {
        hospitalsList,
        repsList,
        error,
        list,
        kitListDropdown,
        kitSetList,
        redirect,
        editSet,
        set,
        hospitalDetails,
        surgeonList,
        opLists,
    };
}

export default connect(mapStateToProps)(Add);