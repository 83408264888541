import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter,Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import moment from "moment";
import { repsBookingService } from "../../../../services/repsBooking.services";

import DatePicker from "react-datepicker";
// import * as excelJs from "exceljs";
import dateFormat from "dateformat";
import Select from "react-select";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { debounce } from "lodash";
import { history } from "../../../../helpers";

const statusOptions = [
    { value: "", label: "All" },
    { value: "yes", label: "Filled" },
    { value: "no", label: "Empty" },
];

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;

const RepsItemUsedList = () => {
    // const [dataXlsx, setDataXlsx] = useState([]);
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [search, setSearch] = useState("");
    const [filterDeliveryToDate, setfilterDeliveryToDate] = useState(todayDate);
    const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState(todayDate);
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const [dayActive, setDateActive] = useState("today");
    const [bookingList, setBookingList] = useState([]);

    const [searchDeliveryToDate, setSearchDeliveryToDate] = useState(newDate);
    const [searchDeliveryFromDate, setSearchDeliveryFromDate] = useState(newDate);
    const [listRefresh, setListRefresh] = useState(false);
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [status, setStatus] = useState("");
    const [selectedStatus, setSelectedStatus] = useState([{ value: "", label: "All" }]);//{ value: "", label: "All" }
    const [cancelModal, setCancelModal] = useState(false);
    const [cancelId, setCancelId] = useState(0);
    const [deleteComment, setDeleteComment] = useState("");
    const [error, setError] = useState({});

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
        debouncedSearch(val);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        window.scrollTo({top: 0});
        // getList();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const toDay = () => {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;
        setfilterDeliveryToDate(todayDate);
        setfilterDeliveryFromDate(todayDate);

        setSearchDeliveryToDate(newDate);
        setSearchDeliveryFromDate(newDate);

        setActivePage(1);
        setDateActive("today");
        setSelectedStatus([{ value: "", label: "All" }]);
        setStatus("");
        //getList(todayDate,todayDate);
    };

    const nextDays = () => {
        let newDate = new Date();
        let date = newDate.getDate() + 1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const tomorrowDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        const searchTomorrowDate = new Date(tomorrowDate);

        setfilterDeliveryToDate(tomorrowDate);
        setfilterDeliveryFromDate(tomorrowDate);

        setSearchDeliveryToDate(searchTomorrowDate);
        setSearchDeliveryFromDate(searchTomorrowDate);

        //getList(tomorrowDate,tomorrowDate);
        setActivePage(1);
        setDateActive("tomorrow");
        setSelectedStatus([{ value: "", label: "All" }]);
        setStatus("");
    };

    const pastBookings = () => {
        var pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
        var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

        const searchFromDate = new Date(pastYearDate);
        const searchToDate = new Date(yesterdayDate);

        setfilterDeliveryToDate(yesterdayDate);
        setfilterDeliveryFromDate(pastYearDate);

        setSearchDeliveryToDate(searchToDate);
        setSearchDeliveryFromDate(searchFromDate);

        setActivePage(1);
        setDateActive("past");
        setSelectedStatus([{ value: "", label: "All" }]);
        setStatus("");
        //getList(pastYearDate,yesterdayDate);
    };


    const getList = async (deliveryDate, toDeliveryDate,searchTerm,currentPage) => {
        setBookingList([]);
        setLoading(true);
        try {
            const res = await repsBookingService.getList({
                operation_from_date: "",
                type: ["loan"],
                status: ["pending", "booked", "dispatch", "return", "close"],
                working_delivery_from_date: deliveryDate,
                working_delivery_to_date: toDeliveryDate,
                search: searchTerm != undefined ? searchTerm : search,
                page: currentPage != undefined ? currentPage : activePage,
                limit: limit,
                reps_id: user?.data?._id,
                itemUsedProduct_filter: status
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
            setListRefresh(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getList(filterDeliveryFromDate, filterDeliveryToDate);
    }, [activePage, dayActive, status]);

    useEffect(() => {
        if (listRefresh) {
            getList(filterDeliveryFromDate, filterDeliveryToDate);
        }
    }, [listRefresh]);

    useEffect(() => {
        if(user?.data?.user_role !== "reps") {
            history.push("/");
        }
    }, []);

    const handleSearchDeliveryFromDate = (val) => {
        setActivePage(1);
        setSearchDeliveryFromDate(val != null ? new Date(val) : "");
        setfilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    const handleSearchDeliveryToDate = (val) => {
        setActivePage(1);
        setSearchDeliveryToDate(val != null ? new Date(val) : "");
        setfilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    // const handleclearToDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryToDate("");
    //         setfilterDeliveryToDate("");
    //         setListRefresh(true);
    //     }
    // };

    // const handleclearFromDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryFromDate("");
    //         setfilterDeliveryFromDate("");
    //         setListRefresh(true);
    //     }
    // };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleStatus = (e) => {
        setStatus(e.value);
        setSelectedStatus(e);
    };

    const cancelModalOpen = (id) => {
        setCancelId(id);
        setCancelModal(true);
    };

    const cancelModalClose = () => {
        setCancelId(0);
        setCancelModal(false);
    };

    const deleteCommentHandler = (val) => {
        setDeleteComment(val);
    };

    const handleCancle = async () => {
        try{
            const res = repsBookingService.updateStatus({
                id: cancelId,
                delete_comment: deleteComment,
                status: "cancel",
                log_by_id: user.data._id,
            });
            console.log(res);
            setCancelId(0);
            setCancelModal(false);
        } catch (error) {
            console.error("Error: ", error);
            setError(error);
        }
    };


    const debouncedSearch = debounce((searchTerm) => {
        console.log("searchTerm",searchTerm);
        // Perform the search or API call with searchTerm
        getList(filterDeliveryFromDate,filterDeliveryToDate,searchTerm,1);
    }, 800);

    return (
        <div>
            <Navbar activePage="item-used" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        {/* <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                        </div> */}

                        <div className="col-lg-12">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12 mb-2">
                                        <div className="sub-menu mb-0 mb-sm-3">
                                            <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example">
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "today"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? toDay() : ""
                                                    }>
                                                    {"Today"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary mx-1 " +
                                                        (dayActive ==
                                                            "tomorrow"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? nextDays() : ""
                                                    }>
                                                    {"Tomorrow"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "past"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? pastBookings() : ""
                                                    }>
                                                    {"Past Bookings"}
                                                </button>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-lg-3 col-sm-6 mb-2">
                                        <form className="form-search">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </form>
                                    </div>

                                    <div className="col-lg-5 col-sm-6 mb-2">
                                        {/* <div className="d-flex justify-content-end" style={{gap:"10px"}}> */}
                                        {/* icon code required */}
                                        <div className={dayActive === "past" ? "label_adjust lable-field-position" : ""}>
                                        {dayActive === "past" ?
                                            <label className="small mb-0">
                                                Delivery Date{" "}
                                            </label> : ""
                                        }
                                        {dayActive === "past" ?
                                            <div className="row no-gutters mb-2">
                                                <div className="col-lg-4 col-sm-4 col-6 px-0">

                                                        <DatePicker
                                                            dateFormat="dd/MM/yyyy"
                                                            showClearButton={false}
                                                            placeholderText="From"
                                                            selected={searchDeliveryFromDate}
                                                            onChange={(e) => handleSearchDeliveryFromDate(e)}
                                                            //onKeyDown={handleclearFromDate}
                                                            className="form-control"
                                                            disabled={!loading ? false : true}
                                                            customInput={
                                                                <MaskedInput
                                                                    pipe={autoCorrectedDatePipe}
                                                                    mask={[
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        "/",
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                        /\d/,
                                                                    ]}
                                                                    keepCharPositions={true}
                                                                    guide={true}
                                                                    showMask={false}
                                                                />
                                                            }
                                                        />
                                                </div>

                                                <div className="col-lg-4 col-sm-4 col-6 mb-2 px-1">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="To"
                                                        selected={searchDeliveryToDate}
                                                        onChange={(e) => handleSearchDeliveryToDate(e)}
                                                        //onKeyDown={handleclearToDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>

                                                <div className="col-lg-3 col-sm-4 col-12 px-1">
                                                <div className="label_adjust">
                                                        <label className="small mb-0">
                                                        Item Used{" "}
                                                        </label>
                                                    <Select
                                                        placeholder=""
                                                        value={selectedStatus}
                                                        onChange={handleStatus}
                                                        options={statusOptions}
                                                        className="text-left"
                                                    />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-2">
                                                            <button type="button" className="btn btn-default add_option" onClick={() => handleExportClick()}> Print </button>
                                                        </div> */}

                                            </div> : <React.Fragment>

                                                <div className="row no-gutters mb-2">
                                                    <div className="col-lg-7 col-0"></div>
                                                    <div className="col-lg-4 col-12">
                                                    <div className="label_adjust">
                                                        <label className="small mb-0">
                                                        Item Used{" "}
                                                        </label>
                                                        <Select
                                                            placeholder="Item Used"
                                                            value={selectedStatus}
                                                            onChange={handleStatus}
                                                            options={statusOptions}
                                                            className="text-left"
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }


                                    </div>
                                    </div>
                                </div>
                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">
                                                    Operation Name{" "}
                                                </th>
                                                <th scope="col" className="mobilehide">
                                                    Kits Required{" "}
                                                </th>
                                                <th scope="col">
                                                    Sets{" "}
                                                </th>
                                                <th scope="col">
                                                    Item Used{" "}
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="text-manage">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList &&
                                                bookingList.map((item, index) => (
                                                    <tr key={item._id + "-" + index}>
                                                        <td
                                                            width="200"
                                                            data-th="Booking #"
                                                            className="">
                                                            <Link
                                                                to={
                                                                    "/reps/booking/details/" +
                                                                    item
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item
                                                                                .bookings
                                                                                .booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="" data-th="Hospital Name">
                                                            {
                                                                item
                                                                    .hospitalId
                                                                    .name
                                                            }
                                                        </td>
                                                        <td data-th="Operation Name" className="">
                                                            <div>
                                                                {item.bookings.operation_name}
                                                            </div>
                                                        </td>

                                                        <td
                                                            data-th="Kits Required"
                                                            className="mobilehide">
                                                            <small>
                                                                {item.bookingkits &&
                                                                    item.bookingkits.map(
                                                                        (
                                                                            kit,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <div>
                                                                                    <span>
                                                                                        {
                                                                                            kit
                                                                                                .kits[0]
                                                                                                .name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </small>
                                                        </td>
                                                        <td
                                                            data-th="Sets"
                                                            className="mobilehide">
                                                            <small>
                                                                {item.bookingKitSetId &&
                                                                    item.bookingKitSetId.map(
                                                                        (
                                                                            kset,
                                                                            ind,
                                                                        ) => (
                                                                            <React.Fragment
                                                                                key={
                                                                                    ind
                                                                                }>
                                                                                <div>
                                                                                    <span>
                                                                                        {
                                                                                            kset
                                                                                                .setId[0]
                                                                                                .set_name
                                                                                        }{" "}
                                                                                    </span>
                                                                                </div>
                                                                            </React.Fragment>
                                                                        ),
                                                                    )}
                                                            </small>
                                                        </td>

                                                        <td data-th="Filled Status"
                                                        >
                                                            <span className={
                                                                "capsule " +
                                                                (item.itemUsedProducts.length > 0
                                                                    ? "green-dim "
                                                                    : "red-dim ") +
                                                                " mr-2 mb-1"
                                                            }>
                                                                {item.itemUsedProducts.length > 0 ? "Filled" : "Empty"}
                                                            </span>
                                                        </td>

                                                        <td className="text-manage">
                                                            <div className="action-area dropdown">
                                                                <div className="mb-2">
                                                                    <span
                                                                        className={
                                                                            "capsule " +
                                                                            (item
                                                                                .bookings
                                                                                .status ==
                                                                            "booked" && dateFormat(item.bookings.working_delivery_date,"yyyy-mm-dd") != dateFormat(new Date(),"yyyy-mm-dd") 
                                                                            || item.bookings.status === "close" 
                                                                            || item.bookingcollections.length && item.bookingreturns.length === 0
                                                                            || item.bookings.status == "dispatch" && item.bookingcollections.length === 0 && item.bookingreturns.length === 0
                                                                                ? "green-dim "
                                                                                : "red-dim ") +
                                                                            " mr-2 mb-1"
                                                                        }>
                                                                        {item
                                                                            .bookings
                                                                            .status ==
                                                                            "dispatch" && (
                                                                            <div>
                                                                                {item.bookingcollections.length && item.bookingreturns.length === 0 ? "Collected" : item.bookingreturns.length > 0 ? "Attempting Return (Partial)" : "Dispatched"}

                                                                            </div>
                                                                        )}
                                                                        {item
                                                                            .bookings
                                                                            .status !=
                                                                            "dispatch" &&
                                                                            capitalize(
                                                                                item.bookings.status === "booked" && dateFormat(item.bookings.working_delivery_date,"yyyy-mm-dd") === dateFormat(new Date(),"yyyy-mm-dd") ? "Pending Dispatch" : item.bookings.status === "booked" ? 
                                                                                "Booked and Confirmed" : 
                                                                                item.bookings.status === "pending" ? "Booked and not confirmed" : 
                                                                                item.bookings.status === "close" ? "Fully Return" : item.bookings.status
                                                                            )}
                                                                    </span>
                                                                </div>

                                                                <div className="mb-2">
                                                                    <span>
                                                                        <Link
                                                                            className="btn btn-outline-primary btn-sm1"
                                                                            to={
                                                                                "/reps/booking/details/" +
                                                                                item
                                                                                    .bookings
                                                                                    ._id
                                                                            }>
                                                                            More Details
                                                                        </Link>
                                                                    </span>
                                                                </div>


                                                                {(item.bookings
                                                                    .status ===
                                                                    "pending" ||
                                                                    item
                                                                        .bookings
                                                                        .status ===
                                                                    "booked") && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <Link
                                                                                    className="btn btn-sm1 btn-outline-primary"
                                                                                    to={
                                                                                        "/reps/booking/edit/" +
                                                                                        item
                                                                                            .bookings
                                                                                            ._id
                                                                                    }>
                                                                                    Edit
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                {item.bookings
                                                                    .status ===
                                                                    "pending" && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() =>
                                                                                        cancelModalOpen(
                                                                                            item
                                                                                                .bookings
                                                                                                ._id,
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-outline-primary btn-sm1">
                                                                                    Cancel
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                {item.bookings
                                                                    .status ===
                                                                    "booked" &&
                                                                    dateFormat(
                                                                        item
                                                                            .bookings
                                                                            .delivery_date,
                                                                        "yyyy-mm-dd",
                                                                    ) >
                                                                    dateFormat(
                                                                        new Date(),
                                                                        "yyyy-mm-dd",
                                                                    ) && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() =>
                                                                                        cancelModalOpen(
                                                                                            item
                                                                                                .bookings
                                                                                                ._id,
                                                                                        )
                                                                                    }
                                                                                    className="btn btn-outline-primary btn-sm1">
                                                                                    Cancel
                                                                                </a>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                                {item.bookings
                                                                    .status ==
                                                                    "dispatch" && (
                                                                        <div className="mb-2">
                                                                            <span>
                                                                                <Link
                                                                                    className="btn btn-outline-primary btn-sm1"
                                                                                    to={
                                                                                        "/reps/booking/item-used/o/" +
                                                                                        item
                                                                                            .bookings
                                                                                            ._id
                                                                                    }>
                                                                                    Item
                                                                                    Used
                                                                                </Link>
                                                                            </span>
                                                                        </div>
                                                                    )}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            {bookingList && bookingList.length == 0 && loading == true &&  
                                                <tr className="text-center">
                                                    <td colSpan="7">
                                                        <div className="loading-state-new">
                                                            <div className="loading"></div>
                                                        </div>                                               
                                                    </td>
                                                </tr>                                                  
                                               
                                            }

                                            {/* {bookingList && bookingList.length == 0 && loading == true &&
                                                <div className="loading-state-new">
                                                    <div className="loading"></div>
                                                </div>
                                            } */}

                                            {bookingList &&
                                                bookingList.length == 0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="7">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {bookingList && bookingList.length > 0 && (
                                <div>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={
                                            totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center mb-5"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Prev"
                                    />
                                    <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                </div>
                            )}

                            {/* Dispatch Details Modal */}
                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <Modal
                size="md"
                backdrop="static"
                isOpen={cancelModal}
                toggle={() => cancelModalClose()}>
                <ModalHeader
                    className=""
                    toggle={() => cancelModalClose()}>
                    Cancel Booking
                </ModalHeader>
                <ModalBody>
                    <div className="col">
                        <label>
                            Reason Of Cancellation{" "}
                            <strong className="text-danger">*</strong>
                        </label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={deleteComment}
                            onChange={e =>
                                deleteCommentHandler(e.target.value)
                            }
                        />
                        {error ? (
                            <span className="text-danger">
                                {error?.delete_comment
                                    ? error?.delete_comment
                                    : ""}
                            </span>
                        ) : (
                            ""
                        )}
                        {error?.common ? (
                            <span className="text-danger">
                                {error?.common ? error?.common : ""}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button
                            onClick={() => cancelModalClose()}
                            className="btn btn-secondary mr-2"
                            data-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => handleCancle()}>
                            Ok
                        </button>
                    </div>
                </ModalFooter>
            </Modal>

            <Footer />
        </div>
    );
};
export default RepsItemUsedList;

