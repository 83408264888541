import React from "react";
import { Input,Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Select from "react-select";
import { ReactComponent as Spinner } from "../../../../../assets/images/Spinner-1s-200px.svg";

const AddDispatch = (props) => {
    return (
        <Modal
            size="md"
            backdrop="static"
            isOpen={props.dispatchModal}
            toggle={() => props.dispatchModalClose()}>
            <ModalHeader
                className=""
                toggle={() =>
                    props.dispatchModalClose()
                }>
                Add Dispatch
            </ModalHeader>
            <ModalBody>
                <div className="form-group position_up"
                    style={{ zIndex: props.boxes.length + 2 }}
                >
                    <label>Courier</label>
                    <Select
                        defaultValue={props.selectedOption}
                        onChange={(e) => props.handleCourier(e)}
                        options={props.options}
                    />
                    {props.error &&
                        props.submitFlag == 1 ? (
                        <span className="text-danger">
                            {props.error.courier
                                ? props.error.courier
                                : ""}
                        </span>
                    ) : (
                        ""
                    )}
                </div>

                <div className="form-group position_up"
                    style={{ zIndex: props.boxes.length + 1 }}
                >
                    <label>Dispatched By</label>
                    <Select
                        defaultValue={props.selectedDispatchedByOptions}
                        onChange={(e) => props.handleDispatchedBy(e)}
                        options={props.dispatchedByOptions}
                    />
                    {props.error &&
                        props.submitFlag == 1 ? (
                        <span className="text-danger">
                            {props.error.dispatched_by
                                ? props.error.dispatched_by
                                : ""}
                        </span>
                    ) : (
                        ""
                    )}
                </div>



                {props.selectedDispatchedByValue === "Other" ?
                    <div className="row">
                        <div className="col-sm-12 mb-3">
                            <Input
                                type="text"
                                value={
                                    props.dispatch?.other_dispatched_by
                                }
                                onChange={e =>
                                    props.handleOtherDispatchedName(
                                        e.target.value
                                    )
                                }
                                className="form-control"
                                name=""
                                placeholder="Other Dispatched Name"
                            />
                            {(props.dispatch?.other_dispatched_by)?.trim() ==
                                "" &&
                                props.otherDispatchedFlag ==
                                true ? (
                                <span className="text-danger">
                                    Please Enter Other Dispatched Name
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    </div> : ""}


                <div className="form-group">
                    <label>Number of Boxes</label>
                    {props.boxes &&
                        props.boxes.map(
                            (item, index) => (
                                <div
                                    className="form-group row position_up"
                                    style={{ zIndex: props.boxes.length - index }}
                                    key={index}>
                                    <div className="col-5 mb-3">
                                        <Select
                                            defaultValue={props.selectedNumOfBox}
                                            onChange={(e) => props.handleBoxType(e, index)}
                                            options={props.boxOptions}
                                            placeholder="Box Type"
                                        />
                                        {item.box_type ==
                                            "" &&
                                            props.submitFlag ==
                                            1 ? (
                                            <span className="text-danger">
                                                Please
                                                enter
                                                box type
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-5 col-sm-6 mb-3">
                                        <Input
                                            type="number"
                                            value={
                                                item.number_of_box
                                            }
                                            onChange={(e)=> props.handleBoxNumber(e.target.value,index)
                                            }
                                            className="form-control"
                                            name=""
                                            placeholder="Number of Boxes"
                                        />
                                        {item.number_of_box ==
                                            "" &&
                                            props.submitFlag ==
                                            1
                                            && item?.numberOfBoxFlag ? (
                                            <span className="text-danger">
                                                Please
                                                enter
                                                number
                                                of boxes
                                            </span>
                                        ) : (
                                            ""
                                        )}

                                    </div>
                                    <div className="col-1">
                                        {index != 0 && (
                                            <i
                                                className="fa fa-trash"
                                                onClick={() =>
                                                    props.handleBoxRemove(
                                                        index,
                                                    )
                                                }></i>
                                        )}
                                    </div>
                                </div>
                            ),
                        )}
                    <a
                        href="javascript:;"
                        className="sml-btn"
                        onClick={() =>
                            props.addMoreBox()
                        }>
                        <i className="fa fa-plus"></i>{" "}
                        Add More
                    </a>
                </div>
                <div className="form-group">
                    <label>GPS Tracker</label>
                    <Input
                        type="text"
                        className="form-control"
                        name=""
                        onChange={(e)=>props.handleTrackerChange(e)}
                    />
                    {props.error &&
                        props.submitFlag == 1 ? (
                        <span className="text-danger">
                            {props.error.tracker
                                ? props.error.tracker
                                : ""}
                        </span>
                    ) : (
                        ""
                    )}

                </div>
                <div className="form-group">
                    <label>Notes</label>
                    <Input
                        type="textarea"
                        className="form-control"
                        name=""
                        onChange={(e)=>props.handleNotesChange(e)}

                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        onClick={() =>
                            props.dispatchModalClose()
                        }
                        className="btn btn-secondary mr-2"
                        data-dismiss="modal">
                        Cancel
                    </button>
                    {props.loading == false && (
                        <button
                            className="btn btn-primary"
                            onClick={() =>
                                props.handleDispatch()
                            }>
                            Ok
                        </button>
                    )}
                    {props.loading == true && (
                        <button
                            className="btn btn-primary loader-icon"
                            disabled>
                            <Spinner className="img-svg"></Spinner>
                        </button>
                    )}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default AddDispatch;



