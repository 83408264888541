import React, { useEffect, useState } from "react";
import {
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from "reactstrap";
import { operationService } from "../../../../../services";

const Index = ({ id, name, handleUpdate, editmodal, setEditModal }) => {
    const [updatedName, setUpdatedName] = useState(name);
    const [editError, setEditError] = useState("");
    const [loading, setLoading] = useState(false);

    console.log("ids---", id, "names---", name);
    console.log("updatedName------", updatedName);

    const toggleEditModal = () => {
        setEditModal(!editmodal);
        setEditError("");
    };

    const handleNameChange = (e) => {
        setUpdatedName(e.target.value);
    };

    const handleUpdateSubmit = () => {
        setLoading(true);
        operationService
            .update({ id: id, name: updatedName })
            .then((res) => {
                console.log(res);
                console.log("Item updated successfully");
                toggleEditModal();
                handleUpdate(id, updatedName);
            })
            .catch((error) => {
                console.log("Error updating item:", error);
                setEditError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        setUpdatedName(name);
    }, [name]);

    return (
        <>
            <div>
                <Modal
                    size="md"
                    backdrop="static"
                    isOpen={editmodal}
                    toggle={toggleEditModal}
                >
                    <ModalHeader className="" toggle={toggleEditModal}>
                        Edit Operation
                    </ModalHeader>
                    <ModalBody>
                        <form className="">
                            <div className="form-group row">
                                <div className="col">
                                    <label>Operation Name</label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        value={updatedName}
                                        onChange={handleNameChange}
                                    />
                                    {editError && editError.name && (
                                        <span className="text-danger">
                                            {editError.name}
                                        </span>
                                    )}
                                    {editError && editError.common && (
                                        <span className="text-danger">
                                            {editError.common}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button
                                onClick={toggleEditModal}
                                className="btn btn-secondary mr-2"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                            {loading ? (
                                <button
                                    className="btn btn-primary loader-icon"
                                    disabled
                                >
                                    <Spinner className="img-svg"></Spinner>
                                </button>
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    onClick={handleUpdateSubmit}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    );
};

export default Index;
