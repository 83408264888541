import React, { useState, useEffect } from "react";
import { ReactComponent as FilterIcon } from "../../../../assets/images/filter.svg";
import { ReactComponent as Close } from "../../../../assets/images/close.svg";
import Select from "react-select";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import moment from "moment";

const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");

const Filter = ({handleFilterToggle,filterModal,filterCreateDate,filterPoChase,handleFilterRefreesh}) => {
    
    const [filterToggle, setFilterToggle] = useState([{label:"All",value:"all"}]);
	const [options, setOptions] = useState([{label:"All",value:"all"},{label:"Checked",value:"checked"},{label:"Unchecked",value:"unchecked"}]);
    const [createDate, setCreateDate] = useState("");


    const handleFilter = () => {

    	handleFilterRefreesh({filterPoChase:filterToggle[0].value,filterCreateDate: createDate != "" ? moment(createDate).format("YYYY-MM-DD") : ""});
    };

    const handlePO = (e) => {

    	setFilterToggle([e]);
    };

    const capitalizeFirstLetter = (string) => { 
	    return string.charAt(0).toUpperCase() + string.slice(1);
	};


    const handleCreateDate = (val) => {
        setCreateDate( val != null ? new Date(val) : "");
    };

    const handleFilterClear = () => {
        setCreateDate("");
        setFilterToggle([{label:"All",value:"all"}]);
    };

    

    

    useEffect(() => {
    	console.log(setOptions);
    	setFilterToggle([{label:capitalizeFirstLetter(filterPoChase),value:filterPoChase}]);
        setCreateDate( filterCreateDate != "" ? new Date(filterCreateDate) : "");
    }, [filterModal]);


    


    return (
        <>
            <div className={"filter_section" + (filterModal==false?" hide ":"")} >
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex justify-content-between align-items-center">
                            <div >
                                <h5 className="mb-0">
                                    <FilterIcon className="img-svg"></FilterIcon> 
                                    <span>Filters</span>
                                </h5>
                               
                            </div>
                            
                            <div className="d-flex align-items-center">
                                <a className="mr-3" onClick={()=>handleFilterClear()}  href="javascript:;"> 
                                    <span>Clear Filters</span> 
                                </a>
                                <Close onClick={()=>handleFilterToggle()} className="img-svg"></Close>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                    	<div className="row">
                    		<div className="col-6">
                    			<div className="mb-3 text-left">
                    				<label className="form-label">Chase PO Number</label>
                    				<Select
                                        value={filterToggle}
                                        onChange={(e)=>handlePO(e)} 
                                        options={options}
                                       
                                    />
                                    
                    				
                    			</div>
                    		</div>
                            <div className="col-6">
                                <div className="mb-3 text-left">
                                    <label className="form-label">Create Date</label>
                                     <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            showClearButton={false}
                                            placeholderText="Select Date"
                                            selected={createDate}
                                            onChange={e =>
                                                handleCreateDate(
                                                    e,
                                                )
                                            }
                                            className="form-control"
                                            customInput={
                                                <MaskedInput
                                                    pipe={autoCorrectedDatePipe}
                                                    mask={[
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        "/",
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ]}
                                                    keepCharPositions={true}
                                                    guide={true}
                                                    showMask={false}
                                                />
                                            }
                                        />
                                    
                                    
                                </div>
                            </div>
                    	</div>
                    </div>
                    <div className="card-footer text-right">
                        <a onClick={()=>handleFilter()} className="btn btn-sm btn-primary">Apply</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;
