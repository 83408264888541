import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const DispatchDetails = (props) => {
    return (
        <Modal
        size="md"
        backdrop="static"
        isOpen={props.detailsModal}
        toggle={() => props.detailsModalClose()}>
        <ModalHeader
            className=""
            toggle={() => props.detailsModalClose()}>
            Dispatched Details
        </ModalHeader>
        <ModalBody>
            {props.detailsModalResult.map(
                (details, index) => (
                    <React.Fragment key={index}>
                        <div className="form-group">
                            <strong>Courier</strong>
                            <div>
                                {details.courier}
                            </div>
                        </div>
                        <div className="form-group">
                            <strong>Dispatched By</strong>
                            {details.other_dispatched_by !== "" ?
                                <div>
                                    {details.other_dispatched_by}
                                </div>
                                :
                                <div>
                                    {details.dispatched_by}
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <strong>
                                Number of Boxes
                            </strong>
                            {details.bookingdispatchboxes &&
                                details.bookingdispatchboxes.map(
                                    (box, ind) => (
                                        <React.Fragment
                                            key={
                                                ind
                                            }>
                                            <div className="form-group row">
                                                <div className="col">
                                                    {
                                                        box.box_type
                                                    }{" "}
                                                    -{" "}
                                                    {
                                                        box.number_of_box
                                                    }{" "}
                                                    {box.number_of_box >
                                                        1
                                                        ? "Boxes"
                                                        : "Box"}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ),
                                )}
                        </div>
                        <div className="form-group">
                            <strong>GPS Tracker</strong>
                            <div>
                                {details.tracker}
                            </div>
                        </div>
                        <div className="form-group">
                            <strong>Notes</strong>
                            <div>
                                {details.notes}
                            </div>
                        </div>
                    </React.Fragment>
                ),
            )}
        </ModalBody>
        <ModalFooter>
            <div className="">
                <button
                    className="btn btn-secondary"
                    onClick={() =>
                        props.detailsModalClose()
                    }>
                    Close
                </button>
            </div>
        </ModalFooter>
    </Modal>
    );
};

export default DispatchDetails;