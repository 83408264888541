import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input} from "reactstrap";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import { userActions } from "../../../../actions";

class EditProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            editUserData: {
                id: "",
                user_role: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: ""
            },
            // changePassword: {
            //     id: "",
            //     password: "",
            //     confirm_password: ""
            // },
            error: false,
            message:false
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        //this.changePasswordSubmit = this.changePasswordSubmit.bind(this);
        // this.handleOnKitSelect = this.handleOnKitSelect.bind(this);
        // this.handleOnProductSearch = this.handleOnProductSearch.bind(this);
        // this.handleOnProductSelect = this.handleOnProductSelect.bind(this);
        // this.handleKitList = this.handleKitList.bind(this);
        // this.handleProductList = this.handleProductList.bind(this);
    }

    getUserData() {
        this.props.dispatch(userActions.getById(this.state.user.data._id));
    }

    editUserInfoHandler = (name, value) => {
        const { editUserData } = this.state;
        editUserData[name] = value;
        this.setState({
            editUserData
        });
    };

    handleUpdate() {   
        this.props.dispatch(userActions.updateUser(this.state.editUserData));
    }

    setEditUser(data) {
        console.log(data);
        this.setState({
            editUserData: {
                id:data._id,
                user_role: data.user_role,
                first_name: data.first_name,
                last_name:data.last_name,
                email: data.email,
                phone:data.phone,
                status: "Active"
            },
            message:false
        });
    }

    // handlePassword = (name, value) => {
    //     const { changePassword } = this.state;
    //     changePassword[name] = value;
    //     this.setState({
    //         changePassword
    //     });
    // };

    // changePasswordSubmit(id) {
    //     this.setState({
    //         changePassword: {
    //             id: id,
    //             password: this.state.changePassword.password,
    //             confirm_password: this.state.changePassword.confirm_password
    //         }
    //     }, () => {
    //         this.props.dispatch(userActions.resetPassword(this.state.changePassword));
    //     });
    // }

    // handleOnKitSearch(searchString, result) {
    //     console.log(result);
    //     this.props.dispatch(setActions.getKitsList({search: searchString}));
    // }

    // handleOnKitSelect(item) {
    //     this.setState({ 
    //         selectedKit:  item
    //     });
    // }

    // handleOnProductSearch(searchString, result) {
    //     console.log(result);
    //     this.props.dispatch(setActions.getProductsList({search: searchString}));
    // }

    // handleOnProductSelect(item) {
    //     console.log(item);
    //     this.setState({ 
    //         selectedProduct:  item
    //     });

    //     this.setState(prevState => ({
    //         selectedProductList: [ item, ...prevState.selectedProductList]
    //     }));
    // }

    // handleKitList(kitList) {
    //     this.setState({ 
    //         kitList:  kitList
    //     });
    // }

    // handleProductList(productList) {
    //     this.setState({ 
    //         productList:  productList
    //     });
    // }

    // deleteProduct(e, id) {
    //     e.preventDefault();
    //     console.log("before", this.state.selectedProductList);
    //     const newProductList = this.state.selectedProductList.filter(product => product._id !== id);
    //     this.setState({ selectedProductList: newProductList },
    //         ()=>{
    //             console.log("after", this.state.selectedProductList);
    //         });
    // }

   

    componentDidMount(){
        this.getUserData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);

        // if(nextProps.kitsList) {
        //     this.handleKitList(nextProps.kitsList);
        // }
        // if(nextProps.productsList) {
        //     this.handleProductList(nextProps.productsList);
        // }

        // if(nextProps.editModal == false) {
        //     this.editModalClose();
        // }
        // if(nextProps.editModal == true) {
        //     this.setEditUser(nextProps.user.data);
        // }

        // if(nextProps.resetModal == false) {
        //     this.resetPasswordModalClose();
        // }
        // if(nextProps.resetModal == true) {
        //     this.resetPasswordModalOpen();
        // }

        // if(nextProps.deleteModal == false) {
        //     this.deleteModalClose();
        // }
        // if(nextProps.deleteModal == true) {
        //     this.deleteModalOpen();
        // }

        // // if(nextProps.editModalClose) {
        // //     this.editModalClose();
        // // }

        if(nextProps.editModal == true) {
            this.setEditUser(nextProps.user.data);
        }
        
        if(nextProps.editModal == false) {
            this.setState({ 
                message: true 
            });
        
            setTimeout(() => {
                this.setState({ 
                    message: false 
                });
            }, 5000);
        }
        

        if(nextProps.error !== "")
        {
            this.setState({ 
                error: true 
            });

           
        }
    }

    render() {
        //const { kitsList, productsList } = this.props;
        if (!this.state.user) {
            return <Redirect to="/" />;
        }
        if(this.state.user.data.user_role !== "reps") {
            return <Redirect to="/" />;
        }
        const { error } = this.props;
        
        return (
            <div>
                <Navbar />
                <section className="b-burger">
                    <div className="container"> 
                        {/* <h1 className="date-scroll pt-4"> <Link to="/reps/open-booking"><i className="fa fa-long-arrow-left smbtn"></i> <strong>Back</strong></Link></h1> */}
                        
                        <div className="row pt-4">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="d-card">
                                    <h4>Edit Profile</h4>
                                    
                                    
                                    <div className="form-group">
                                        <label>First Name <strong className="text-danger">*</strong></label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.editUserData.first_name} 
                                            onChange = {(e) => this.editUserInfoHandler("first_name", e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.first_name ? error.first_name : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Last Name <strong className="text-danger">*</strong></label>
                                        <Input 
                                            type="text"
                                            className="form-control"
                                            value={this.state.editUserData.last_name} 
                                            onChange = {(e) => this.editUserInfoHandler("last_name", e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.last_name ? error.last_name : ""}</span> : ""}
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address (Username) <strong className="text-danger">*</strong></label>
                                        <Input 
                                            type="email"
                                            className="form-control"
                                            value={this.state.editUserData.email} 
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Phone Number </label>
                                        <Input 
                                            type="number"
                                            min="0"
                                            className="form-control"
                                            value={this.state.editUserData.phone} 
                                            onChange = {(e) => this.editUserInfoHandler("phone", e.target.value)}
                                        />
                                        {(this.state.error && error) ? <span className="text-danger">{error.phone ? error.phone : ""}</span> : ""}
                                        { this.state.message==true && <span className="text-success">Profile updated successfully</span> }

                                    </div>
                                </div>
                                <div className="mt-5 text-right">
                                    <Link to="/reps/open-booking" className="btn btn-secondary mb-3 mr-2">Cancel</Link>
                                    <button className="btn btn-primary mb-3" onClick={this.handleUpdate}>Save Changes</button>
                                </div>   
                            </div>
                            <div className="col-lg-4"></div>
                        </div>
                        
                    </div>
                </section>
                <Footer />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { user } = state.rootReducer.users;
    const { error } = state.rootReducer.users;
    const { editModal } = state.rootReducer.users;
    
    return {
        error,
        editModal,
        user
    };
}

export default connect(mapStateToProps)(EditProfile);