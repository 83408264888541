import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import moment from "moment";
import { repsBookingService } from "../../../../services/repsBooking.services";

import DatePicker from "react-datepicker";
// import * as excelJs from "exceljs";
import dateFormat from "dateformat";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import { debounce } from "lodash";
import { history } from "../../../../helpers";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth() + 1;
let year = newDate.getFullYear();
let separator = "-";
const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;

const RepsDashboard = () => {
    // const [dataXlsx, setDataXlsx] = useState([]);
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/MM/yyyy");
    const [search, setSearch] = useState("");
    const [filterDeliveryToDate, setfilterDeliveryToDate] = useState(todayDate);
    const [filterDeliveryFromDate, setfilterDeliveryFromDate] = useState(todayDate);
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const [dayActive, setDateActive] = useState("today");
    const [bookingList, setBookingList] = useState([]);

    const [searchDeliveryToDate, setSearchDeliveryToDate] = useState(newDate);
    const [searchDeliveryFromDate, setSearchDeliveryFromDate] = useState(newDate);
    const [listRefresh,setListRefresh] = useState(false);
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
        debouncedSearch(val);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        window.scrollTo({top: 0});
        // getList();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const toDay = () => {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const todayDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;
        setfilterDeliveryToDate(todayDate);
        setfilterDeliveryFromDate(todayDate);

        setSearchDeliveryToDate(newDate);
        setSearchDeliveryFromDate(newDate);

        setActivePage(1);
        setDateActive("today");
        //getList(todayDate,todayDate);
    };

    const nextDays = () => {
        let newDate = new Date();
        let date = newDate.getDate() + 1;
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator = "-";
        const tomorrowDate = `${year}${separator}${month < 10 ? `0${month}` : `${month}`
            }${separator}${date < 10 ? `0${date}` : `${date}`}`;

        const searchTomorrowDate = new Date(tomorrowDate);

        setfilterDeliveryToDate(tomorrowDate);
        setfilterDeliveryFromDate(tomorrowDate);

        setSearchDeliveryToDate(searchTomorrowDate);
        setSearchDeliveryFromDate(searchTomorrowDate);

        //getList(tomorrowDate,tomorrowDate);
        setActivePage(1);
        setDateActive("tomorrow");
    };

    const pastBookings = () => {
        var pastYearDate = moment().subtract(6, "months").format("YYYY-MM-DD");
        var yesterdayDate = moment().subtract(1, "days").format("YYYY-MM-DD");

        const searchFromDate = new Date(pastYearDate);
        const searchToDate = new Date(yesterdayDate);

        setfilterDeliveryToDate(yesterdayDate);
        setfilterDeliveryFromDate(pastYearDate);

        setSearchDeliveryToDate(searchToDate);
        setSearchDeliveryFromDate(searchFromDate);

        setActivePage(1);
        setDateActive("past");
        //getList(pastYearDate,yesterdayDate);
    };


    const getList = async (deliveryDate,toDeliveryDate,searchTerm,currentPage) => {
        setBookingList([]);
        setLoading(true);
        try {
            const res = await repsBookingService.getList({
                operation_from_date:"",
                type: ["loan"],
                status: ["pending", "booked", "dispatch", "return","close"],
                working_delivery_from_date: deliveryDate,
                working_delivery_to_date: toDeliveryDate,
                search: searchTerm != undefined ? searchTerm : search,
                page: currentPage != undefined ? currentPage : activePage,
                limit: limit,
                reps_id:user?.data?._id
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
            setListRefresh(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if(user?.data?.user_role !== "reps") {
            history.push("/");
        }
    }, []);

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")));
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getList(filterDeliveryFromDate,filterDeliveryToDate);
    }, [activePage,dayActive]);

    useEffect(() => {
        if(listRefresh){
            getList(filterDeliveryFromDate,filterDeliveryToDate);
        }
    }, [listRefresh]);

    const handleSearchDeliveryFromDate = (val) => {
        setActivePage(1);
        setSearchDeliveryFromDate(val != null ? new Date(val) : "");
        setfilterDeliveryFromDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    const handleSearchDeliveryToDate = (val) => {
        setActivePage(1);
        setSearchDeliveryToDate(val != null ? new Date(val) : "");
        setfilterDeliveryToDate(val != null ? moment(val).format("YYYY-MM-DD") : "");
        setListRefresh(true);
    };

    // const handleclearToDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryToDate("");
    //         setfilterDeliveryToDate("");
    //         setListRefresh(true);
    //     }
    // };

    // const handleclearFromDate = event => {
    //     if (event.key === "Backspace") {
    //         setSearchDeliveryFromDate("");
    //         setfilterDeliveryFromDate("");
    //         setListRefresh(true);
    //     }
    // };

    const updatePONumber = (ev,item,index) => {
        let newBookingList = [...bookingList];
        newBookingList[index].bookings.po_number=ev.target.value;
        newBookingList[index].bookings.po_check= ev.target.value==""?true:false;
        setBookingList(newBookingList);
        repsBookingService
            .updatePoNumber({
                id: item?.bookings._id,
                //po_check:  ev.target.value==""?true:false,
                po_number: ev.target.value,
            })
            .then(res => {
                console.log("res", res);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    const updateMouseLeave = (item,index) => {
        let newBookingList = [...bookingList];
        newBookingList[index].bookings.po_hover=undefined;
        setBookingList(newBookingList);
    };

    const updateMouseEnter = (item,index) => {
        let newBookingList = [...bookingList];
        newBookingList[index].bookings.po_hover=1;
        setBookingList(newBookingList);
    };

    const capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const debouncedSearch = debounce((searchTerm) => {
        console.log("searchTerm",searchTerm);
        // Perform the search or API call with searchTerm
        getList(filterDeliveryFromDate,filterDeliveryToDate,searchTerm,1);
    }, 800);

    return (
        <div>
            <Navbar activePage="dashboard" />
            <main className="offset">
                <div className="container-fluid">
                    <div className="row pt-4">
                        {/* <div className="col-lg-2 side-filters">
                            <span className="side-heading">Dashboard</span>
                        </div> */}

                        <div className="col-lg-12">
                            <div className="ground">
                                <div className="row">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="sub-menu mb-0 mb-sm-3">
                                            <div
                                                className="btn-group"
                                                role="group"
                                                aria-label="Basic example">
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "today"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? toDay() : ""
                                                    }>
                                                    {"Today"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary mx-1 " +
                                                        (dayActive ==
                                                            "tomorrow"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? nextDays() : ""
                                                    }>
                                                    {"Tomorrow"}
                                                </button>
                                                <button
                                                    type="button"
                                                    className={
                                                        "btn btn-secondary " +
                                                        (dayActive ==
                                                            "past"
                                                            ? "active "
                                                            : loading ? "btn-disabled" : "")
                                                    }
                                                    onClick={() =>
                                                        !loading ? pastBookings() : ""
                                                    }>
                                                    {"Past Bookings"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6">
                                        <form className="form-search my-3 my-sm-0">
                                            <Input
                                                className="form-control mr-sm-2"
                                                type="text"
                                                placeholder="Search.."
                                                value={search}
                                                onChange={e =>
                                                    handleSearch(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </form>
                                    </div>

                                    <div className="col-lg-4 col-sm-6">
                                        <div className={dayActive === "past" ? "label_adjust lable-field-position" : ""}>
                                        {dayActive === "past" ?
                                            <label className="small mb-0">
                                                Delivery Date{" "}
                                            </label> : ""
                                        }
                                        {/* <div className="d-flex justify-content-end" style={{gap:"10px"}}> */}  
                                        {/* icon code required */}
                                        {dayActive === "past" ? 
                                            <div className="row no-gutters mb-2">
                                                <div className="col-6 px-0">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="From"
                                                        selected={searchDeliveryFromDate}
                                                        onChange={(e) =>handleSearchDeliveryFromDate(e)}
                                                        //onKeyDown={handleclearFromDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>
                                               
                                                <div className="col-6 px-1">
                                                    <DatePicker
                                                        dateFormat="dd/MM/yyyy"
                                                        showClearButton={false}
                                                        placeholderText="To"
                                                        selected={searchDeliveryToDate}
                                                        onChange={(e) => handleSearchDeliveryToDate(e)}
                                                        //onKeyDown={handleclearToDate}
                                                        className="form-control"
                                                        disabled={!loading ? false : true}
                                                        customInput={
                                                            <MaskedInput
                                                                pipe={autoCorrectedDatePipe}
                                                                mask={[
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    "/",
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                    /\d/,
                                                                ]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                                showMask={false}
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div> : ""
                                        }

                                        
                                    </div>


                                    </div>
                                    </div>

                                <div className="table-scroll mt-4">
                                    <Table className="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    scope="col"
                                                    style={{
                                                        minWidth: "200px",
                                                    }}>
                                                    Booking #
                                                </th>
                                                <th scope="col">
                                                    Hospital Name{" "}
                                                </th>
                                                <th scope="col">
                                                    Sergeon{" "}
                                                </th>
                                                <th scope="col">
                                                    Operation{" "}
                                                </th>
                                                <th scope="col">PO Number</th>
                                                <th
                                                    scope="col"
                                                    className="text-manage">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bookingList &&
                                                bookingList.map((item,index) => (
                                                    <tr key={item._id}>
                                                        <td
                                                            width="200"
                                                            data-th="Booking #"
                                                            className="">
                                                            <Link
                                                                to={
                                                                    "/reps/booking/details/" +
                                                                    item
                                                                        .bookings
                                                                        ._id
                                                                }>
                                                                <span>
                                                                    <strong>
                                                                        {" "}
                                                                        {
                                                                            item
                                                                                .bookings
                                                                                .booking_id
                                                                        }{" "}
                                                                    </strong>
                                                                </span>
                                                            </Link>
                                                        </td>
                                                        <td className="" data-th="Hospital Name">
                                                                {
                                                                    item
                                                                        .hospitalId
                                                                        .name
                                                                }
                                                        </td>
                                                        <td data-th="Surgeon" className="">
                                                            {item.bookingSurgeonData && item.bookingSurgeonData.map((surgeon, ind) => (
                                                                <React.Fragment key={ind}>
                                                                    <div><span>{surgeon.SurgeonDetail[0].first_name + " " + surgeon.SurgeonDetail[0].last_name} </span></div>
                                                                </React.Fragment>
                                                            ))}
                                                    </td>
                                                    <td data-th="Operation" className="">
                                                        <div>
                                                            {item.bookings.operation_name}
                                                        </div>
                                                    </td>
                                                    
                                                    <td data-th="" className="">                                                       
                                                        <div className="d-flex po-mobileview"  onMouseEnter={() => updateMouseEnter(item,index)} onMouseLeave={() => updateMouseLeave(item,index)}>
                                                            {item.bookings.po_hover !=undefined ?
                                                                <span className="d-flex">
                                                                    <span style={{width:"100px"}}>PO Number:</span> <input type="text" style={{maxWidth:"110px",marginTop:"-4px"}}  className="text-center ml-2" onChange={e =>updatePONumber(e,item,index)} value={item.bookings.po_number} />
                                                                </span>
                                                                :
                                                                <span className="d-flex">
                                                                    <span style={{width:"100px"}}>PO Number:</span> <input type="text" style={{maxWidth:"110px",marginTop:"-4px"}} className="bg-hide text-center ml-2"  onChange={e =>updatePONumber(e,item,index)} value={item.bookings.po_number==""?"TBC":item.bookings.po_number} />
                                                                </span>
                                                            }
                                                        </div>
                                                    </td>

                                                    <td className="text-manage">
                                                        <div className="action-area dropdown">
                                                            <div className="mb-2">
                                                                <span
                                                                    className={
                                                                    "capsule " +
                                                                    (item.bookings.status == "booked" && dateFormat(item.bookings.working_delivery_date,"yyyy-mm-dd") != dateFormat(new Date(),"yyyy-mm-dd") 
                                                                    || item.bookings.status === "close" 
                                                                    || item.bookingcollections.length && item.bookingreturns.length === 0
                                                                    || item.bookings.status == "dispatch" && item.bookingcollections.length === 0 && item.bookingreturns.length === 0
                                                                        ? "green-dim "
                                                                        : "red-dim ") +
                                                                    " mr-2 mb-1"
                                                                }>
                                                                    {item
                                                                        .bookings
                                                                        .status ==
                                                                        "dispatch" && (
                                                                        <div>
                                                                            {item.bookingcollections.length && item.bookingreturns.length === 0 ? "Collected" : item.bookingreturns.length > 0 ? "Attempting Return (Partial)" : "Dispatched"}
                                                                        </div>
                                                                    )}
                                                                    {item
                                                                        .bookings
                                                                        .status !=
                                                                        "dispatch" &&
                                                                        capitalize(
                                                                            item.bookings.status === "booked" && dateFormat(item.bookings.working_delivery_date,"yyyy-mm-dd") === dateFormat(new Date(),"yyyy-mm-dd") ? "Pending Dispatch" : item.bookings.status === "booked" ? 
                                                                            "Booked and Confirmed" : 
                                                                            item.bookings.status === "pending" ? "Booked and not confirmed" : 
                                                                            item.bookings.status === "close" ? "Fully Return" : item.bookings.status
                                                                        )}
                                                                </span>
                                                            </div>
                                                            
                                                            <div className="mb-2">
                                                                <span>
                                                                    <Link
                                                                        className="btn btn-outline-primary btn-sm1"
                                                                        to={
                                                                            "/reps/booking/details/" +
                                                                            item
                                                                                .bookings
                                                                                ._id
                                                                        }>
                                                                        More Details
                                                                    </Link>
                                                                </span>
                                                            </div>

                                                        </div>
                                                    </td>
                                                    </tr>
                                                ))}

                                            {bookingList && bookingList.length == 0 && loading == true &&  
                                                <tr className="text-center">
                                                    <td colSpan="6">
                                                        <div className="loading-state-new">
                                                            <div className="loading"></div>
                                                        </div>                                               
                                                    </td>
                                                </tr>                                                  
                                               
                                            }

                                            {bookingList &&
                                                bookingList.length == 0 &&
                                                loading == false && (
                                                    <tr className="text-center">
                                                        <td colSpan="6">
                                                            No Record Found
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            {bookingList && bookingList.length > 0 && (
                                <div>
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={
                                            totalItemsCount
                                        }
                                        pageRangeDisplayed={5}
                                        onChange={handlePageChange.bind(
                                            this,
                                        )}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination justify-content-center mb-5"
                                        activeLinkClass="active"
                                        nextPageText="Next"
                                        prevPageText="Prev"
                                    />
                                    <br className="d-block d-sm-none" /><br className="d-block d-sm-none" />
                                </div>
                            )}

                            {/* Dispatch Details Modal */}
                        </div>
                    </div>
                    {/*<!--/row-->*/}
                </div>
            </main>

            <Footer />
        </div>
    );
};
export default RepsDashboard;

