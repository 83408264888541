import React from "react";
import Navbar from "../Navbar";
import dateFormat from "dateformat";
import Pagination from "react-js-pagination";
import { bookingService } from "../../../../services/booking.services";

class holidayList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayHolidayList: [],
            activePage: 1,
            limit: 50,
            totalItemsCount: 0,
        };

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    getHolidayList() {
        bookingService
            .getHolidayList({
                page: this.state.activePage,
                limit: this.state.limit,
            })
            .then(response => {
                console.log("holidayList", response);
                this.setState({
                    displayHolidayList: response.data,
                    activePage: response.page,
                    limit: response.page_count,
                    totalItemsCount: response.total_count,
                });
            })
            .catch(error => {
                // Handle any errors that occur during the API call
                console.error(error);
            });
    }

    handlePageChange(pageNumber) {
        this.setState(
            {
                activePage: pageNumber,
            },
            () => {
                this.getHolidayList();
            },
        );
    }

    componentDidMount() {
        this.getHolidayList();
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="container py-3">
                    <div id="table-scroll" className="table-scroll mt-4">
                        <table id="main-table" className="main-table">
                            <thead>
                                <tr>
                                    <th scope="col">Sr. No</th>
                                    <th scope="col">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.displayHolidayList.map(
                                    (data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {dateFormat(
                                                    data.date,
                                                    "ddd, mmm dS, yyyy",
                                                )}
                                            </td>
                                        </tr>
                                    ),
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {this.state.displayHolidayList &&
                    this.state.displayHolidayList.length > 0 && (
                        <div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.limit}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={this.handlePageChange.bind(this)}
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination justify-content-center mb-5"
                            activeLinkClass="active"
                            nextPageText="Next"
                            prevPageText="Prev"
                        />
                        <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                        </div>
                    )}
            </div>
        );
    }
}

export default holidayList;
