import { kitConstants } from "../constants";
import { kitService } from "../services";

export const kitActions = {
    getKitsList,
    kitListDropdown,
    kitListDropdownForReps,
    addKit,
    getById,
    updateKit,
    delete: _delete
};

function getKitsList(search) {
    return dispatch => {
        dispatch(request());

        kitService.getKitsList(search)
            .then(
                kitsList => dispatch(success(kitsList)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kitConstants.GETALLKITS_REQUEST }; }
    function success(kitsList) { return { type: kitConstants.GETALLKITS_SUCCESS, kitsList }; }
    function failure(error) { return { type: kitConstants.GETALLKITS_FAILURE, error }; }
}

function kitListDropdown(search) {
    return dispatch => {
        dispatch(request());

        kitService.kitListDropdown(search)
            .then(
                kitListDropdown => dispatch(success(kitListDropdown)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kitConstants.GETDROPDOWNKITSLIST_REQUEST }; }
    function success(kitListDropdown) { return { type: kitConstants.GETDROPDOWNKITSLIST_SUCCESS, kitListDropdown }; }
    function failure(error) { return { type: kitConstants.GETDROPDOWNKITSLIST_FAILURE, error }; }
}

function kitListDropdownForReps(search) {
    return dispatch => {
        dispatch(request());

        kitService.kitListDropdownForReps(search)
            .then(
                kitListDropdown => dispatch(success(kitListDropdown)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kitConstants.GETDROPDOWNKITSLIST_REQUEST }; }
    function success(kitListDropdown) { return { type: kitConstants.GETDROPDOWNKITSLIST_SUCCESS, kitListDropdown }; }
    function failure(error) { return { type: kitConstants.GETDROPDOWNKITSLIST_FAILURE, error }; }
}

function addKit(kitInfo) {
    
    return dispatch => {
        dispatch(request());

        kitService.addKit(kitInfo)
            .then(
                kitInfo => dispatch(success(kitInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return {  type: kitConstants.KITADD_REQUEST }; }
    function success(kitInfo) {  return { type: kitConstants.KITADD_SUCCESS, kitInfo }; }
    function failure(error) { let errors=error.response.data.data.errors; return { type: kitConstants.KITADD_FAILURE, errors}; }
}

function updateKit(kitInfo) {
    return dispatch => {
        dispatch(request());

        kitService.update(kitInfo)
            .then(
                kitInfo => dispatch(success(kitInfo)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kitConstants.KITUPDATE_REQUEST }; }
    function success(kitInfo) { return { type: kitConstants.KITUPDATE_SUCCESS, kitInfo }; }
    function failure(error) { let errors=error.response.data.data.errors; return { type: kitConstants.KITUPDATE_FAILURE, errors }; }
}

function getById(id) {
    return dispatch => {
        dispatch(request());

        kitService.getById(id)
            .then(
                kit => dispatch(success(kit)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: kitConstants.KITEDIT_REQUEST }; }
    function success(kit) { return { type: kitConstants.KITEDIT_SUCCESS, kit }; }
    function failure(error) { return { type: kitConstants.KITEDIT_FAILURE, error }; }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        kitService.delete(id)
            .then(
                kit => { 
                    console.log(kit);
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: kitConstants.DELETE_REQUEST, id }; }
    function success(id) { return { type: kitConstants.DELETE_SUCCESS, id }; }
    function failure(id, error) { return { type: kitConstants.DELETE_FAILURE, id, error }; }
}