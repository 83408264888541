import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { surgeonService } from "../../../../services";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import Select from "react-select";

class Delete extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
            error: "",
            surgeons: [],
            selectedSurgeon: "",
            updateError:""
        };

        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.doDelete = this.doDelete.bind(this);
        this.suggestSurgeon = this.suggestSurgeon.bind(this);
        this.handleSurgeonSelect = this.handleSurgeonSelect.bind(this);
    }

    deleteModalClose() {
        this.setState({ error: "", updateError: "", selectedSurgeon: null });
        this.props.deleteModalClose();
    }
    
    doDelete() {
        surgeonService.delete({ id: this.props.id }).then((res) => {
            console.log(res);
            this.deleteModalClose();
        }).catch((res) => {
            console.log("res",res);
            this.setState({ error: res });
            this.suggestSurgeon();
        });
    }

    suggestSurgeon() {
        surgeonService.getList({ limit: 50, page: 1, search: "" })
            .then((res) => {
                this.setState({ surgeons: res.data });
            }).catch((error) => {
                console.log("error", error);
                this.setState({ updateError: error });
            });
    }

    handleSurgeonSelect(selectedOption) {
        this.setState({ selectedSurgeon: selectedOption });
        console.log(selectedOption.label);
    }


    updateSurgeon() {
        const { selectedSurgeon } = this.state;
        console.log("selectedSurgeonselectedSurgeon", selectedSurgeon);
        surgeonService.updateSurgeonList({
            prevSurgeonId: this.props.id,
            newSurgeonId: selectedSurgeon.value? selectedSurgeon.value: ""
        }).then((res) => {
            console.log("updatedsurgeonList", res.data);
            this.deleteModalClose();
        }).catch((error) => {
            console.log("error", error.newSurgeonId);
            this.setState({ updateError: error });
        });
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log("selectedSurgeon", this.state.selectedSurgeon);
        console.log(nextProps);
        /* if(nextProps.deleteModal==false)
        {
            this.deleteModalClose();
        }*/

    }

    render() {
        const { error, surgeons,updateError } = this.state;
        const { loading } = this.props;
        const { selectedSurgeon } = this.state;

        const options = surgeons.filter(surgeon => surgeon.surgeons._id !== this.props.id)
            .map((surgeon) => ({
                value: surgeon.surgeons._id,
                label: surgeon.surgeons.fullName
            }));

        return (
            <div>
                <Modal size="md" backdrop="static" isOpen={this.props.deleteModalProps} toggle={() => this.deleteModalClose()}>
                    <ModalHeader className="" toggle={() => this.deleteModalClose()}>
                        Delete Surgeon
                    </ModalHeader>
                    <ModalBody>
                        <div className="my-2">Please confirm if you want to delete this surgeon?</div>
                        {error != "" ? <span className="text-danger">{error.common ? error.common : ""}</span> : ""}
                        {error && error.common && (
                            <div className="form-group">
                                <label>Select a Surgeon</label>
                                <Select
                                    value={selectedSurgeon}
                                    onChange={this.handleSurgeonSelect}
                                    options={options}
                                    placeholder="Select a Surgeon"
                                />
                                {updateError != "" ? <span className="text-danger">{updateError.newSurgeonId ? updateError.newSurgeonId : ""}</span> : ""}
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="">
                            <button onClick={() => this.deleteModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                            {loading == false && !error && !updateError && !updateError.newSurgeonId && !error.common ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => this.doDelete()}>
                                    Ok
                                </button>

                            )
                                :
                                <button className="btn btn-primary" onClick={() => this.updateSurgeon()}>Update</button>
                            }
                            {loading == true &&
                                <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>

                            }
                        </div>

                    </ModalFooter>
                </Modal>

                {/* Delete Modal */}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { refreshList, loading, deleteModal } = state.rootReducer.surgeons;

    return {
        refreshList,
        loading,
        deleteModal,
    };
}

export default connect(mapStateToProps)(Delete);













