import React, {  useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
//import Select from "react-select";


const ReadMore = (props) => {
    const [readMoreModel, setReadMoreModel] = useState(false);
    const [readMoreContent, setReadMoreContent] = useState([]);
   
    const ModalOpen = () => {
        setReadMoreModel(true);

    };

    const handleReadMoreClose = () => {
        setReadMoreModel(false);
        setReadMoreContent([]);
        props.handleReadMoreClose();
       
    };

    
    useEffect(() => {
        if(props.readMoreModel==true)
        {
            setReadMoreContent(props.readMoreContent);
            ModalOpen();
        }
    }, [props.readMoreModel]);



    return (
        <div>
            <Modal size="md" backdrop="static" isOpen={readMoreModel} toggle={() => handleReadMoreClose()}>
                <ModalHeader className="" toggle={() => handleReadMoreClose()}>
                    Suggested kits
                </ModalHeader>
                <ModalBody>
                    <div className="my-2">
                        { readMoreContent.length >0 && readMoreContent.map((sugKit,sugInd)=>
                            <React.Fragment key={sugInd}>
                                {sugInd>0 ?", ":""}{sugKit.suggestedKitDetail[0]?.name}
                            </React.Fragment>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="">
                        <button className="btn btn-secondary ml-2" onClick={() =>handleReadMoreClose()}>Close</button>
                    </div>
                </ModalFooter>
            </Modal>

        </div>
    );
};

export default ReadMore;
