import React, { useState, useEffect } from "react";
import { Modal, ModalBody, Table, ModalFooter, Input, ModalHeader, Button } from "reactstrap";
import { updateLoanChargeOnKitOfAllHospitals, getRecordByKitId, updateKitChargeOnKitHospitals } from "../../../../../services/loanCharge.service";
//updateLoanChargeOnKitOfAllHospitals
const KitModal = ({ isOpen, toggle, item }) => {
  const [hospitalData, setHospitalData] = useState(null);
  //const [kitCharges, setKitCharges] = useState([]);
  const [updateValue, setUpdateValue] = useState("");
  const [search, setSearch] = useState("");
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const getData = () => {
    if (isOpen && item && item._id) {
      getRecordByKitId(item._id,search)
        .then((data) => {
          let datas = data.hospitalList;

          datas.map((hospital) => {
            setIsFirstLoad(false);
            if (hospital.kitLoanCharges.length < 1) {

              hospital.kitLoanCharges = [{ kitId: item._id, hospitalId: hospital._id, loan_charge: 695, check_loan_charge: false }];
            }

          });

          setHospitalData(datas);

        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  const handleKitChargeChange = (vl, name, index) => {
    console.log("first",vl);
    const updatedHospitalData = [...hospitalData];
    if (name == "loan_charge") {
      updatedHospitalData[index].kitLoanCharges[0].loan_charge = vl;

    }
    else {
      updatedHospitalData[index].kitLoanCharges[0].check_loan_charge = vl;
    }

    let item_id = updatedHospitalData[index].kitLoanCharges[0].kitId;
    let hospital_id = updatedHospitalData[index].kitLoanCharges[0].hospitalId;
    let loanCharge = updatedHospitalData[index].kitLoanCharges[0].loan_charge;
    let check_loan_charge = updatedHospitalData[index].kitLoanCharges[0].check_loan_charge;

    setHospitalData(updatedHospitalData);

    updateKitChargeOnKitHospitals(item_id, hospital_id, loanCharge, check_loan_charge)
      .then((response) => {
        console.log("Kit charge updated successfully:", response);
      })
      .catch((error) => {
        console.error("Error updating kit charge On Kit Hospitals:", error);
      });

  };

  const handleUpdateAll = () => {
    if (updateValue !== "") {
      if (!isNaN(updateValue)) {

        let hospitalDataVar = hospitalData.map((hospital) => ({
          hospitalId: hospital._id,
          loan_charge: hospital.kitLoanCharges[0].check_loan_charge == true ? hospital.kitLoanCharges[0].loan_charge : updateValue,
          check_loan_charge: hospital.kitLoanCharges[0].check_loan_charge,
          kitId: item._id
        }));
        const updateData = {
          "kitData": hospitalDataVar
        };

        const updatedHospitalData = [...hospitalData];
        updatedHospitalData.map((hospital) => {
          hospital.kitLoanCharges[0].loan_charge = hospital.kitLoanCharges[0].check_loan_charge == true ? hospital.kitLoanCharges[0].loan_charge : updateValue;
        });

        console.log("newHospitalDataVar", updatedHospitalData);

        setHospitalData(updatedHospitalData);

        updateLoanChargeOnKitOfAllHospitals(updateData)
          .then((response) => {
            console.log("Kit charges updated successfully:", response);
          })
          .catch((error) => {
            console.error("Error updating kit charges:", error);
          });
      }
    }
  };

  const handleSearch = (vl) => {
    setSearch(vl);
  };


  useEffect(() => {
    getData();
  }, [search]);

  useEffect(() => {
    getData();
    setUpdateValue("");
    setSearch("");
    setIsFirstLoad(true);
  }, [isOpen, item]);

  return (
    <div>
      <Modal size="lg" backdrop="static" isOpen={isOpen} toggle={toggle} className="header_close-btn">
        <ModalBody>
          <div>
            {item ? <ModalHeader className="" toggle={toggle}>{item.name}</ModalHeader> : ""}
            <div className="row">
              <div className="col-md-4 col-sm-6 ">
                <div className="d-flex align-items-center justify-content-between py-3">
                  <Input
                    type="number"
                    placeholder="Enter to update"
                    value={updateValue}
                    onChange={(e) => {
                      const inputValue = e.target.value.trim();
                      if (inputValue === "" || (!isNaN(inputValue) && parseInt(inputValue) >= 0)) {
                        setUpdateValue(inputValue);
                      }
                    }}
                  />
                  <Button className="btn-sm ml-2" onClick={handleUpdateAll}>
                    Update
                  </Button>
                </div>
              </div>
              <div className="col-md-4 col-sm-6 "></div>
              <div className="col-md-4 col-sm-6 ">
                <div className="d-flex align-items-center justify-content-between py-3">
                  <Input
                    type="text"
                    placeholder="Seacrh"
                    onChange={(e) =>handleSearch(e.target.value) }
                  />
                </div>
              </div>
            </div>
            <div className="fixTableHead">
              <Table id="main-table" className="main-table">
                <thead >
                  <tr>
                    <th>Hospital Name</th>
                    <th>Sac Code</th>
                    <th>Kit Charge</th>
                    <th width="50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {hospitalData &&
                    hospitalData.map((hospital, index) => (
                      <tr key={hospital._id}>
                        <td>{hospital.name}</td>
                        <td>{hospital.sac_code}</td>
                        <td>
                          <Input
                            type="number"
                            value={
                              hospital.kitLoanCharges[0].loan_charge === ""
                                ? ""
                                : hospital.kitLoanCharges[0].loan_charge
                            }
                            step="1"
                            onChange={(e) => {
                              const inputValue = e.target.value.trim();
                              if (inputValue === "" || (!isNaN(inputValue) && parseInt(inputValue) >= 0)) {
                                handleKitChargeChange(inputValue, "loan_charge", index);
                              }
                            }}
                          />
                        </td>
                        <td className="text-center">
                          <Input
                            className="m-auto"
                            type="checkbox"
                            checked={hospital.kitLoanCharges[0].check_loan_charge || false}
                            onChange={(e) => handleKitChargeChange(e.target.checked, "check", index)}
                          />
                        </td>
                      </tr>
                    ))}
                    {isFirstLoad==false && hospitalData.length==0 &&
                      <tr className="text-center">
                        <td colSpan={4}>No Record Found</td>
                      </tr>
                    }
                </tbody>
              </Table>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" onClick={toggle}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default KitModal;






