import React, { Component } from "react";
import { connect } from "react-redux";

const month_names_short=["","Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
class Head extends Component {
    constructor(props) {
        super(props);
        // reset login status
        this.state = {
            user: JSON.parse(localStorage.getItem("user")),
           
            dates:[],
            months:[]
        };
    }

    componentDidMount()
    {
      
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps);
 
    }

    /*{ind==0 && 
        <i className="fa fa-chevron-left" onClick={()=>this.props.leftScroll()}></i>
    }*/
    
    /*{this.props.months.length-1 == ind &&
        <i className="fa fa-chevron-right" onClick={()=>this.props.rightScroll()}></i>
    }*/
    render() {
       
        return (
            // <div className="table_header"></div>
            <thead className="table_header">
                <tr className="headrow">
                    <td colSpan="">Kit Name</td>
                    
                    {   this.props.months && this.props.months.map((month,ind) => (
                        <td key={ind} className="text-center" colSpan={month.colSpan}>
                            <div className="mnth-srl d-block">                                    
                                {month.colSpan>1 &&
                                    <span>{month.year} {month_names_short[month.month]} </span>
                                }                                    
                            </div>
                        </td>
                    ))}                      
                </tr>
                <tr>
                    <th scope="col"></th>
                    {   this.props.dates && this.props.dates.map((date,ind) => (
                        <th key={ind} scope="col" className={date.classN}>{date.day} {weekday[date.weekday]}</th>
                    ))}
                </tr>
            </thead>
        );
    }
}

function mapStateToProps(state) {
    const { error } = state.rootReducer.hospitals;
    return {
        error
    };
}

export default connect(mapStateToProps)(Head);