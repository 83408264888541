import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const CollectionDetails = (props) => {
    return (
        <Modal
            size="md"
            backdrop="static"
            isOpen={props.detailsModal}
            toggle={() => props.detailsModalClose()}>
            <ModalHeader
                className=""
                toggle={() => props.detailsModalClose()}>
                Collected Details
            </ModalHeader>
            <ModalBody>
                {props.detailsModalResult && props.detailsModalResult.map(
                    (details, index) => (
                        <React.Fragment key={index}>
                            <div className="form-group">
                                <strong>Courier</strong>
                                <div>
                                    {details.courier}
                                </div>
                            </div>

                            <div className="form-group">
                                <strong>
                                    Number of Boxes
                                </strong>
                                {details.bookingcollectionboxes &&
                                    details.bookingcollectionboxes.map(
                                        (box, ind) => (
                                            <React.Fragment
                                                key={
                                                    ind
                                                }>
                                                <div className="form-group row">
                                                    <div className="col">
                                                        {
                                                            box.box_type
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            box.number_of_box
                                                        }{" "}
                                                        {box.number_of_box >
                                                            1
                                                            ? "Boxes"
                                                            : "Box"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ),
                                    )}
                            </div>
                            <div className="form-group">
                                <strong>Priority Level</strong>
                                <div>
                                    {details.priority_level}
                                </div>
                            </div>
                            <div className="form-group">
                                <strong>Comment</strong>
                                <div>
                                    {details.comment}
                                </div>
                            </div>
                        </React.Fragment>
                    ),
                )}
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        className="btn btn-secondary"
                        onClick={() =>
                            props.detailsModalClose()
                        }>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default CollectionDetails;

