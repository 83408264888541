import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input, Table, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import Pagination from "react-js-pagination";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import DashboardSidebar from "./NewDashboardSidebar";
import dateFormat from "dateformat";
import * as XLSX from "xlsx";
import { ExportSheet } from "react-xlsx-sheet";
import { bookingRequestService } from "../../../../services/bookingRequest.services";
import { bookingService } from "../../../../services/booking.services";
import Select from "react-select";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";

const head = [
    { title: "HOSPITAL NAME",dataIndex: "hospital_name"},
    { title: "KITS REQUIRED",dataIndex: "kits"},
    { title: "IMPORTANT DATES",dataIndex: "import_dates"},
    { title: "REP",dataIndex: "rep_name" },
    
];

const NewAdminBookingRequest = () => {
    const [dataXlsx, setDataXlsx] = useState([]);
    const [search, setSearch] = useState("");
    const [activePage, setActivePage] = useState(1);
    const [totalItemsCount, setTotalItemsCount] = useState("");
    const [limit, setLimit] = useState(50);
    const [loading, setLoading] = useState(false);

    const [bookingList, setBookingList] = useState([]);
    const [hospitalsList, setHospitalList] = useState([]);
    const [repsList, setRepsList] = useState([]);
    const [selectedUser, setSelectedUser] = useState([{label:"Booked By",value:""}]);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedHospital, setSelectedHospital] = useState([{label:"All Hospital",value:""}]);
    const [selectedHospitalId, setSelectedHospitalId] = useState("");
    const [rejectComment,setRejectComment] = useState("");
    const [cancelModal, setCancelModal] = useState(false);
    const [acceptModal, setAcceptModal] = useState(false);
    const [bookingId,setBookingId] = useState(0);
    const [user,setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [rejectStatus,setRejectStatus] = useState(false);

    const handleSearch = (val) => {
        setSearch(val);
        setActivePage(1);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setLoading(true);
        // getList();
    };

    const handlePageStates = (activePage, totalItemsCount, limit) => {
        if (totalItemsCount === undefined) {
            totalItemsCount = 0;
        }
        setActivePage(activePage);
        setTotalItemsCount(totalItemsCount);
        setLimit(limit);
    };

    const showXlsData = async () => {
        
        var dataXlsx=[];
        var kitset="";
        var imDate="";

        bookingList && bookingList.map((item) => {
            kitset="";
            imDate="";

            item.bookingkits && item.bookingkits.map((kit)=>{
                if(kitset!="")
                {
                    kitset+="\n"+kit.kits[0].name;
                }
                else
                {
                    kitset+=kit.kits[0].name; 
                }
                
            }); 
            imDate="Delivery Date: "+dateFormat(item.bookings.delivery_date, "ddd,mmm dS")+" "+item.bookings.delivery_date_slot;
            imDate+="\nOperation Date: "+dateFormat(item.bookings.operation_date, "ddd,mmm dS")+" "+item.bookings.operation_date_slot;
            imDate+="\nCollection Date: "+dateFormat(item.bookings.collection_date, "ddd,mmm dS");
                                                         
            dataXlsx.push({
                "hospital_name": item.hospitalId.name+"\n Requested on "+dateFormat(item.bookings.createdAt, "ddd,mmm dS"),
                "kits": kitset,
                "import_dates": imDate,
                "rep_name": item.assigned_rep.first_name+" "+item.assigned_rep.last_name
               
            });
        });

        setDataXlsx(dataXlsx);
    };

    const getList = async () => {
        setBookingList([]);
        setLoading(true);
        try {
            const res = await bookingRequestService.getList({
                bookedByFilter: selectedUserId,
                hospitalFilter: selectedHospitalId,
                type: ["loan"],
                status: ["pending"],
                deliveryDateFiter: "",
                toDeliveryDateFiter: "",
                search: search,
                page: activePage,
                limit: limit,
            });
            setBookingList(res.data);
            setTotalItemsCount(res.total_count);
            setLoading(false);
        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    const getHospitalList = async () => {
        setHospitalList([]);
        try {
            const res = await bookingService.getHospitalsList({
                search: ""
            });
            showHospitalsList(res.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const getRepsList = async () => {
        setRepsList([]);
        try {
            const res = await bookingService.getRepsList({
                search: ""
            });
            showRepsList(res.data);
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    useEffect(() => {
        getRepsList();
        getHospitalList();
    }, []);


    useEffect(() => {
        showXlsData();
        setUser(JSON.parse(localStorage.getItem("user")));
    }, [bookingList]);

    useEffect(() => {
        handlePageStates(activePage, totalItemsCount, limit);
    }, [activePage, totalItemsCount, limit]);

    useEffect(() => {
        getList();
    }, [search, activePage]);

    useEffect(() => {
        getList();
    }, [selectedHospitalId, selectedUserId]);

    const showHospitalsList = (hospitalsList) => {
        var tempHospitals = [{label:"All Hospital",value:""}];
        hospitalsList.map((item)=> {
            var option = {value: item._id, label: item.name};
            tempHospitals.push(option);
        });
        setHospitalList(tempHospitals);
    };

    const showRepsList = (repsList) => {
        var tempRepsList = [{label:"Booked By",value:""}];
        repsList.map((item)=> {
            var option = {value: item._id, label: item.first_name+" "+item.last_name};
            tempRepsList.push(option);
        });
        setRepsList(tempRepsList);
    };

    const setSelectedOptionUser = (e) => {
        setActivePage(1);
        var selectedUser=[{value:e.value, label:e.label}];
        setSelectedUser(selectedUser);
        setSelectedUserId(e.value);
    }; 

    const setSelectedOptionHospital = (e) => {
        setActivePage(1);
        var selectedHospital=[{value:e.value, label:e.label}];
        setSelectedHospital(selectedHospital);
        setSelectedHospitalId(e.value);
    };


    const cancelModalOpen = (id) => {
        setRejectComment("");
        setCancelModal(true);
        setRejectStatus(false);
        setBookingId(id);
    };

    const cancelModalClose = () => {
        setRejectComment("");
        setCancelModal(false);
        setRejectStatus(false);
    };

    const acceptModalClose = () => {
        setAcceptModal(false);
    };

    const handleRejectComment = (comment) => {
        setRejectComment(comment);
    };

    const handleCancle = async () => {
        try {
            const res = await bookingRequestService.updateRejectStatus({
                id: bookingId,
                log_by_id:user.data._id,
                log_status:"rejected", 
                comment: rejectComment, 
                status:"reject"
            });
            
            if(res.status === 200){
                setCancelModal(false);
                setRejectStatus(true);
                getList();
            }

        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        }
    };

    const handleAccept = async () => {
        try {
            const res = await bookingRequestService.updateAcceptStatus({
                id: bookingId,
                log_by_id:user.data._id,
                status:"booking"
            });
            
            if(res.status === 200){
                setCancelModal(false);
                getList();
            }

        } catch (error) {
            console.error("Error: ", error);
            setLoading(false);
        }

    };

    return (
        <div>
        <Navbar activePage="dashboard"/>
        <main className="offset">
            <div className="container-fluid">
                <div className="row pt-4">
                    <div className="col-lg-2 side-filters">     
                        <span className="side-heading">Dashboard</span>
                        <DashboardSidebar activePage="request" requestCount={totalItemsCount} rejectStatus={rejectStatus}/>
                    </div>
                   
                    <div className="col-lg-10">
                        <div className="ground">
                            <div className="row">
                                <div className="col-md-4 col-sm-8"> 
                                    {/*<div className="btn-group" role="group" aria-label="Basic example">          		  
                                        <button type="button" className="btn btn-secondary active">Today</button>
                                        <button type="button" className="btn btn-secondary">Tomorrow</button>	
                                    </div> */} 
                                    <div className="sub-menu">
                                        <div className="d-flex">
                                            <div className="col p-0">
                                                <div className="mr-2">
                                                    <Select 
                                                        value={selectedUser}
                                                        onChange={setSelectedOptionUser}
                                                        options={repsList}
                                                        className="small text-left"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col p-0">
                                                <div className="ml-2">
                                                    <Select 
                                                        value={selectedHospital}
                                                        onChange={setSelectedOptionHospital}
                                                        options={hospitalsList}
                                                        className="small text-left"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4">
                                    <form className="form-search">
                                        <Input 
                                            className="form-control mr-sm-2" 
                                            type="text"
                                            placeholder="Search.."
                                            value={search}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />    
                                    </form>
                                </div>
                                <div className="col-md-4 text-right">
                                    {/*<button type="button" className="btn btn-default"> Print </button>*/}
                                    <ExportSheet
                                        header={head}
                                        fileName={"booking-request"}
                                        dataSource={dataXlsx}
                                        xlsx={XLSX}
                                        className="btn btn-default add_option"
                                    >
                                        <button className="btn btn-default add_option">Print</button>
                                    </ExportSheet>
                                </div>
                            </div>
                            <div className="table-scroll mt-4">
                                <Table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{minWidth:"200px"}}>Hospital Name </th>
                                            <th scope="col" >Kits Required</th>
                                            <th scope="col">Important Dates </th>
                                            <th scope="col">Rep </th>
                                            <th scope="col" className="text-right"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       
                                        { bookingList && bookingList.map((item) => (
                                            <tr key={item._id}>
                                                <td width="200" data-th="Hospital Name">
                                                    <div className="">                   
                                                        <Link to={"/admin/booking/details/"+item.bookings._id}>
                                                            <span><strong><span style={{"display": "block"}} className="theme-text">{item.bookings.booking_id}</span>
                                                                {item.hospitalId.name}</strong></span><br />
                                                            <small>Requested on {dateFormat(item.bookings.createdAt, "ddd,mmm dS")}</small>                          
                                                        </Link>
                                                    </div>
                                                </td>                                                        
                                                <td data-th="Kits Required">
                                                    <small>
                                                        { item.bookingkits && item.bookingkits.map((kit,ind)=>(
                                                            <React.Fragment key={ind}>
                                                                <div><span>{kit.kits[0].name} </span></div>
                                                            </React.Fragment>
                                                        ))}
                                                    </small>                        
                                                </td>
                                                <td data-th="Important Dates">
                                                    <small>
                                                    Delivery Date: <strong>{dateFormat(item.bookings.delivery_date, "ddd,mmm dS")} {item.bookings.delivery_date_slot}</strong><br />
                                                    Operation Date: <strong>{dateFormat(item.bookings.operation_date, "ddd,mmm dS")} {item.bookings.operation_date_slot}</strong><br />
                                                    Collection Date: <strong>{dateFormat(item.bookings.collection_date, "ddd,mmm dS")}</strong>
                                                    </small>
                                                </td> 
                                                <td data-th="Rep">{item.assigned_rep.first_name} {item.assigned_rep.last_name}</td>
                                                
                                                <td className="text-right">                                 
                                                    <a href="javascript:;" onClick={()=>cancelModalOpen(item.bookings._id)} className="btn btn-secondary my-1">Reject</a>
                                                    <Link className="btn btn-primary ml-2 my-1" to={"/admin/booking/edit/"+item.bookings._id}>Accept</Link>
                                                </td> 
                                            </tr>
                                        ))}

                                        {bookingList && bookingList.length == 0 && loading == true &&                                                    
                                            <div className="loading-state">
                                                <div className="loading"></div>
                                            </div>                                               
                                        }

                                        { bookingList && bookingList.length==0 && loading==false && 
                                            <tr className="text-center">
                                                <td colSpan="5">No Record Found</td>
                                            </tr>
                                        } 
                                    </tbody>
                                </Table>
                            </div>
                        </div>


                        {bookingList && bookingList.length >0 &&
                        <div>
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={limit}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange.bind(this)}
                                itemClass="page-item"
                                linkClass="page-link"
                                innerClass="pagination justify-content-center mb-5"
                                activeLinkClass="active"
                                nextPageText="Next"
                                prevPageText="Prev"
                            />
                            <br className="d-block d-sm-none"/><br className="d-block d-sm-none"/>
                        </div>
                        }

                     

                        <Modal size="md" backdrop="static" isOpen={cancelModal} toggle={() => cancelModalClose()}>
                            <ModalHeader className="" toggle={() => cancelModalClose()}>
                                Reject Booking
                            </ModalHeader>
                            <ModalBody>
                                {/* <div className="my-2">Please confirm if you want to reject this booking?</div> */}
                                <textarea 
                                    className="form-control my-2" 
                                    placeholder="Comment"
                                    value={rejectComment}
                                    onChange={(e) => handleRejectComment(e.target.value)}
                                ></textarea>
                            </ModalBody>
                            <ModalFooter>
                                <div className="">
                                    <button onClick={() => cancelModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                    { loading==false && 
                                        <button className="btn btn-primary" onClick={() =>handleCancle()}>Ok</button>
                                    }
                                    {loading==true && 
                                        <button className="btn btn-primary loader-icon" disabled><Spinner className="img-svg" ></Spinner></button>
                                    }
                                </div>
                            </ModalFooter>
                        </Modal>

                        {/* Reject Modal */}

                        <Modal size="ml" backdrop="static" isOpen={acceptModal} toggle={() => acceptModalClose()}>
                            <ModalHeader className="" toggle={() => acceptModalClose()}>
                                Accept Booking
                            </ModalHeader>
                            <ModalBody>
                                <div className="my-2">Please confirm if you want to accept this booking?</div>
                            </ModalBody>
                            <ModalFooter>
                                <div className="">
                                    <button onClick={() => acceptModalClose()} className="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button>
                                    <button className="btn btn-primary" onClick={() =>handleAccept()}>Ok</button>
                                </div>
                            </ModalFooter>
                        </Modal>
                        {/* Accept Modal*/}

                        {/* Set Details Modal */}
                    </div>
                </div>{/*<!--/row-->*/}
            </div>
        </main>

  
        <Footer />
    </div>
    );
};
export default NewAdminBookingRequest;