import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import moment from "moment";
const ReturnDetails = (props) => {

    const [showMore,setShowMore] = useState(false);
    const [showMoreId,setShowMoreId] = useState("");

    const showMoreFunction = (set) => {
        setShowMoreId(set?._id);
        setShowMore(showMoreId !== set?._id ? true : !showMore);
    };

    return (
        <Modal
            size="lg"
            backdrop="static"
            isOpen={props.detailsModal}
            toggle={() => props.detailsModalClose()}>
            <ModalHeader
                className=""
                toggle={() => props.detailsModalClose()}>
                Returned Details
            </ModalHeader>
            <ModalBody>
                {props.detailsModalResult.map(
                    (details, index) => (
                        <React.Fragment key={index}>
                            <div className="form-group">
                                <div>
                                    {details.bookingreturnsets &&
                                        details.bookingreturnsets.map(

                                            (set, indSet) => (
                                                <React.Fragment
                                                    key={
                                                        indSet
                                                    }>

                                                    {set?.isSetReturn ?
                                                        <div className="form-group row">
                                                            <div className="col">
                                                                {
                                                                    indSet === 0 ? <strong>Set Name</strong> : null
                                                                }
                                                                {
                                                                    <div>{set.sets[0].set_name}</div>
                                                                }
                                                            </div>

                                                            <div className="col">
                                                                {
                                                                    indSet === 0 ? <strong>Return Date</strong> : null
                                                                }

                                                                {
                                                                    <div>{moment(set.set_return_date).format("DD-MM-YYYY")}</div>
                                                                }
                                                            </div>

                                                            <div className="col">
                                                                {
                                                                    indSet === 0 ? <strong>Comment</strong> : null
                                                                }
                                                                {
                                                                    <div>{set?.comment ? (showMore && showMoreId === set?._id) ? set?.comment : `${set.comment.substring(0, 100)}` : null} {set?.comment?.length > 100 ? <button className="return-read-more-btn" onClick={() => showMoreFunction(set)}>{(showMore && showMoreId === set?._id) ? "Show less" : "Show More"}</button> : null}</div>
                                                                }
                                                            </div>


                                                        </div>
                                                        : null}

                                                </React.Fragment>
                                            ),
                                        )
                                    }
                                </div>
                            </div>

                            <div className="form-group">
                                <strong>
                                    Number of Boxes
                                </strong>
                                {details.bookingreturnboxes &&
                                    details.bookingreturnboxes.map(
                                        (box, ind) => (
                                            <React.Fragment
                                                key={
                                                    ind
                                                }>
                                                <div className="form-group row">
                                                    <div className="col">
                                                        {
                                                            box.box_type
                                                        }{" "}
                                                        -{" "}
                                                        {
                                                            box.number_of_box
                                                        }{" "}
                                                        {box.number_of_box >
                                                            1
                                                            ? "Boxes"
                                                            : "Box"}
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ),
                                    )}
                            </div>
                            <hr />
                        </React.Fragment>
                    ),
                )}
            </ModalBody>
            <ModalFooter>
                <div className="">
                    <button
                        className="btn btn-secondary"
                        onClick={() =>
                            props.detailsModalClose()
                        }>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ReturnDetails;

