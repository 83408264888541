import { authHeader,check419 } from "../helpers";
export const bookingRequestService = {
    getList,
    updateAcceptStatus,
    updateRejectStatus
};

function getList(search) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(search)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/getBookingRequest", requestOptions).then(handleResponse)
        .then(bookingList => {
            return bookingList;
        });
}

function updateAcceptStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: { ...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/updateBookingStatus", requestOptions).then(handleResponse);
}

function updateRejectStatus(bookingInfo) {
    const requestOptions = {
        method: "POST",
        headers: {...authHeader(),"Content-Type": "application/json" },
        body: JSON.stringify(bookingInfo)
    };

    return fetch(process.env.REACT_APP_APIURL+"booking/updateBookingStatusAsReject", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //const data = text;
        if (!response.ok) {
            if (response.status === 400) {
                // auto logout if 401 response returned from api
                // logout();
                // location.reload(true);
                const error = (data && data.data.errors) || response.statusText;
                return Promise.reject(error);
            } else if(response.status === 419){
                check419();
            }

            // const error = (data && data.message) || response.statusText;
            // return Promise.reject(error);
        }

        return data;
    });
}